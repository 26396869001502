/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date | string; output: Date | string };
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: { input: string; output: string };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: Record<string, any>; output: Record<string, any> };
};

export type Access = {
  __typename?: "Access";
  canAccessAdmin: Scalars["Boolean"]["output"];
  footer: Maybe<FooterAccess>;
  medias: Maybe<MediasAccess>;
  navigation: Maybe<NavigationAccess>;
  pages: Maybe<PagesAccess>;
  payload_preferences: Maybe<Payload_PreferencesAccess>;
  users: Maybe<UsersAccess>;
};

export type AnchorBlock = {
  __typename?: "AnchorBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  name: Maybe<Scalars["String"]["output"]>;
};

export type AngledTextBoxBlock = {
  __typename?: "AngledTextBoxBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type AngledTextBoxBlockTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type BubbleTextBlock = {
  __typename?: "BubbleTextBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  modal: Maybe<Array<BubbleTextBlock_Modal>>;
  openInNewTab: Maybe<Scalars["Boolean"]["output"]>;
  page: Maybe<Page>;
  text: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type BubbleTextBlock_Modal = MailchimpSignupModal | NumberedItemsModal | TextGridModal;

export type CaseLeadBoxBlock = {
  __typename?: "CaseLeadBoxBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  headline: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  poster: Maybe<Media>;
  text: Maybe<Scalars["JSON"]["output"]>;
  video: Maybe<Scalars["String"]["output"]>;
};

export type CaseLeadBoxBlockPosterArgs = {
  where?: InputMaybe<CaseLeadBoxBlock_Poster_Where>;
};

export type CaseLeadBoxBlockTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Lg__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Lg__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Lg__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Lg__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Lg__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Lg__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Md__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Md__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Md__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Md__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Md__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Md__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_2k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_2k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_2k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_2k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_2k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_2k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_4k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_4k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_4k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_4k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_4k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_4k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Hd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Xs__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Xs__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Xs__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Xs__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Xs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Sizes__Xs__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CaseLeadBoxBlock_Poster_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CaseLeadBoxBlock_Poster_Where = {
  AND?: InputMaybe<Array<InputMaybe<CaseLeadBoxBlock_Poster_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CaseLeadBoxBlock_Poster_Where_Or>>>;
  createdAt?: InputMaybe<CaseLeadBoxBlock_Poster_CreatedAt_Operator>;
  filename?: InputMaybe<CaseLeadBoxBlock_Poster_Filename_Operator>;
  filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Filesize_Operator>;
  height?: InputMaybe<CaseLeadBoxBlock_Poster_Height_Operator>;
  id?: InputMaybe<CaseLeadBoxBlock_Poster_Id_Operator>;
  mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<CaseLeadBoxBlock_Poster_UpdatedAt_Operator>;
  url?: InputMaybe<CaseLeadBoxBlock_Poster_Url_Operator>;
  width?: InputMaybe<CaseLeadBoxBlock_Poster_Width_Operator>;
};

export type CaseLeadBoxBlock_Poster_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<CaseLeadBoxBlock_Poster_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CaseLeadBoxBlock_Poster_Where_Or>>>;
  createdAt?: InputMaybe<CaseLeadBoxBlock_Poster_CreatedAt_Operator>;
  filename?: InputMaybe<CaseLeadBoxBlock_Poster_Filename_Operator>;
  filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Filesize_Operator>;
  height?: InputMaybe<CaseLeadBoxBlock_Poster_Height_Operator>;
  id?: InputMaybe<CaseLeadBoxBlock_Poster_Id_Operator>;
  mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<CaseLeadBoxBlock_Poster_UpdatedAt_Operator>;
  url?: InputMaybe<CaseLeadBoxBlock_Poster_Url_Operator>;
  width?: InputMaybe<CaseLeadBoxBlock_Poster_Width_Operator>;
};

export type CaseLeadBoxBlock_Poster_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<CaseLeadBoxBlock_Poster_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CaseLeadBoxBlock_Poster_Where_Or>>>;
  createdAt?: InputMaybe<CaseLeadBoxBlock_Poster_CreatedAt_Operator>;
  filename?: InputMaybe<CaseLeadBoxBlock_Poster_Filename_Operator>;
  filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Filesize_Operator>;
  height?: InputMaybe<CaseLeadBoxBlock_Poster_Height_Operator>;
  id?: InputMaybe<CaseLeadBoxBlock_Poster_Id_Operator>;
  mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<CaseLeadBoxBlock_Poster_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<CaseLeadBoxBlock_Poster_UpdatedAt_Operator>;
  url?: InputMaybe<CaseLeadBoxBlock_Poster_Url_Operator>;
  width?: InputMaybe<CaseLeadBoxBlock_Poster_Width_Operator>;
};

export type CaseLeadBoxBlock_Poster_Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock = {
  __typename?: "CasesBoxBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  cases: Maybe<Array<CasesBoxBlock_Cases>>;
  id: Maybe<Scalars["String"]["output"]>;
};

export type CasesBoxBlock_Cases = {
  __typename?: "CasesBoxBlock_Cases";
  description: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  image: Maybe<Media>;
  page: Maybe<Page>;
  text: Maybe<Scalars["JSON"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type CasesBoxBlock_CasesImageArgs = {
  where?: InputMaybe<CasesBoxBlock_Cases_Image_Where>;
};

export type CasesBoxBlock_CasesTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Lg__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Lg__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Lg__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Lg__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Lg__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Lg__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Md__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Md__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Md__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Md__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Md__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Md__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_2k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_2k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_2k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_2k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_2k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_2k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_4k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_4k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_4k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_4k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_4k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_4k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Hd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Xs__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Xs__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Xs__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Xs__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Xs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Sizes__Xs__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CasesBoxBlock_Cases_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type CasesBoxBlock_Cases_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<CasesBoxBlock_Cases_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CasesBoxBlock_Cases_Image_Where_Or>>>;
  createdAt?: InputMaybe<CasesBoxBlock_Cases_Image_CreatedAt_Operator>;
  filename?: InputMaybe<CasesBoxBlock_Cases_Image_Filename_Operator>;
  filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Filesize_Operator>;
  height?: InputMaybe<CasesBoxBlock_Cases_Image_Height_Operator>;
  id?: InputMaybe<CasesBoxBlock_Cases_Image_Id_Operator>;
  mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<CasesBoxBlock_Cases_Image_UpdatedAt_Operator>;
  url?: InputMaybe<CasesBoxBlock_Cases_Image_Url_Operator>;
  width?: InputMaybe<CasesBoxBlock_Cases_Image_Width_Operator>;
};

export type CasesBoxBlock_Cases_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<CasesBoxBlock_Cases_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CasesBoxBlock_Cases_Image_Where_Or>>>;
  createdAt?: InputMaybe<CasesBoxBlock_Cases_Image_CreatedAt_Operator>;
  filename?: InputMaybe<CasesBoxBlock_Cases_Image_Filename_Operator>;
  filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Filesize_Operator>;
  height?: InputMaybe<CasesBoxBlock_Cases_Image_Height_Operator>;
  id?: InputMaybe<CasesBoxBlock_Cases_Image_Id_Operator>;
  mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<CasesBoxBlock_Cases_Image_UpdatedAt_Operator>;
  url?: InputMaybe<CasesBoxBlock_Cases_Image_Url_Operator>;
  width?: InputMaybe<CasesBoxBlock_Cases_Image_Width_Operator>;
};

export type CasesBoxBlock_Cases_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<CasesBoxBlock_Cases_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<CasesBoxBlock_Cases_Image_Where_Or>>>;
  createdAt?: InputMaybe<CasesBoxBlock_Cases_Image_CreatedAt_Operator>;
  filename?: InputMaybe<CasesBoxBlock_Cases_Image_Filename_Operator>;
  filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Filesize_Operator>;
  height?: InputMaybe<CasesBoxBlock_Cases_Image_Height_Operator>;
  id?: InputMaybe<CasesBoxBlock_Cases_Image_Id_Operator>;
  mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<CasesBoxBlock_Cases_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<CasesBoxBlock_Cases_Image_UpdatedAt_Operator>;
  url?: InputMaybe<CasesBoxBlock_Cases_Image_Url_Operator>;
  width?: InputMaybe<CasesBoxBlock_Cases_Image_Width_Operator>;
};

export type CasesBoxBlock_Cases_Image_Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DoubleSlotBoxBlock = {
  __typename?: "DoubleSlotBoxBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  contentLeft: Maybe<DoubleSlotBoxBlock_ContentLeft>;
  contentRight: Maybe<DoubleSlotBoxBlock_ContentRight>;
  id: Maybe<Scalars["String"]["output"]>;
};

export type DoubleSlotBoxBlock_ContentLeft = {
  __typename?: "DoubleSlotBoxBlock_ContentLeft";
  blocks: Maybe<Array<DoubleSlotBoxBlock_ContentLeft_Blocks>>;
};

export type DoubleSlotBoxBlock_ContentLeft_Blocks = BubbleTextBlock | VideoCarouselBlock;

export type DoubleSlotBoxBlock_ContentRight = {
  __typename?: "DoubleSlotBoxBlock_ContentRight";
  icon: Maybe<DoubleSlotBoxBlock_ContentRight_Icon | "%future added value">;
  text: Maybe<Scalars["JSON"]["output"]>;
};

export type DoubleSlotBoxBlock_ContentRightTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum DoubleSlotBoxBlock_ContentRight_Icon {
  Compass = "compass",
  Eye = "eye",
  Filter = "filter",
  Heart = "heart",
  Send = "send",
  Target = "target",
  Volume_1 = "volume_1",
  Volume_2 = "volume_2",
  X = "x",
}

export type Footer = {
  __typename?: "Footer";
  address: Scalars["JSON"]["output"];
  contact: Maybe<Footer_Contact>;
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  socials: Maybe<Footer_Socials>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type FooterAddressArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FooterDocAccessFields = {
  __typename?: "FooterDocAccessFields";
  address: Maybe<FooterDocAccessFields_Address>;
  contact: Maybe<FooterDocAccessFields_Contact>;
  createdAt: Maybe<FooterDocAccessFields_CreatedAt>;
  socials: Maybe<FooterDocAccessFields_Socials>;
  updatedAt: Maybe<FooterDocAccessFields_UpdatedAt>;
};

export type FooterDocAccessFields_Address = {
  __typename?: "FooterDocAccessFields_address";
  create: Maybe<FooterDocAccessFields_Address_Create>;
  delete: Maybe<FooterDocAccessFields_Address_Delete>;
  read: Maybe<FooterDocAccessFields_Address_Read>;
  update: Maybe<FooterDocAccessFields_Address_Update>;
};

export type FooterDocAccessFields_Address_Create = {
  __typename?: "FooterDocAccessFields_address_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Address_Delete = {
  __typename?: "FooterDocAccessFields_address_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Address_Read = {
  __typename?: "FooterDocAccessFields_address_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Address_Update = {
  __typename?: "FooterDocAccessFields_address_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact = {
  __typename?: "FooterDocAccessFields_contact";
  create: Maybe<FooterDocAccessFields_Contact_Create>;
  delete: Maybe<FooterDocAccessFields_Contact_Delete>;
  fields: Maybe<FooterDocAccessFields_Contact_Fields>;
  read: Maybe<FooterDocAccessFields_Contact_Read>;
  update: Maybe<FooterDocAccessFields_Contact_Update>;
};

export type FooterDocAccessFields_Contact_Create = {
  __typename?: "FooterDocAccessFields_contact_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Delete = {
  __typename?: "FooterDocAccessFields_contact_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Fields = {
  __typename?: "FooterDocAccessFields_contact_Fields";
  email: Maybe<FooterDocAccessFields_Contact_Email>;
  emailContent: Maybe<FooterDocAccessFields_Contact_EmailContent>;
  phoneNumber: Maybe<FooterDocAccessFields_Contact_PhoneNumber>;
  title: Maybe<FooterDocAccessFields_Contact_Title>;
};

export type FooterDocAccessFields_Contact_Read = {
  __typename?: "FooterDocAccessFields_contact_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Update = {
  __typename?: "FooterDocAccessFields_contact_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Email = {
  __typename?: "FooterDocAccessFields_contact_email";
  create: Maybe<FooterDocAccessFields_Contact_Email_Create>;
  delete: Maybe<FooterDocAccessFields_Contact_Email_Delete>;
  read: Maybe<FooterDocAccessFields_Contact_Email_Read>;
  update: Maybe<FooterDocAccessFields_Contact_Email_Update>;
};

export type FooterDocAccessFields_Contact_EmailContent = {
  __typename?: "FooterDocAccessFields_contact_emailContent";
  create: Maybe<FooterDocAccessFields_Contact_EmailContent_Create>;
  delete: Maybe<FooterDocAccessFields_Contact_EmailContent_Delete>;
  read: Maybe<FooterDocAccessFields_Contact_EmailContent_Read>;
  update: Maybe<FooterDocAccessFields_Contact_EmailContent_Update>;
};

export type FooterDocAccessFields_Contact_EmailContent_Create = {
  __typename?: "FooterDocAccessFields_contact_emailContent_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_EmailContent_Delete = {
  __typename?: "FooterDocAccessFields_contact_emailContent_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_EmailContent_Read = {
  __typename?: "FooterDocAccessFields_contact_emailContent_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_EmailContent_Update = {
  __typename?: "FooterDocAccessFields_contact_emailContent_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Email_Create = {
  __typename?: "FooterDocAccessFields_contact_email_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Email_Delete = {
  __typename?: "FooterDocAccessFields_contact_email_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Email_Read = {
  __typename?: "FooterDocAccessFields_contact_email_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Email_Update = {
  __typename?: "FooterDocAccessFields_contact_email_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_PhoneNumber = {
  __typename?: "FooterDocAccessFields_contact_phoneNumber";
  create: Maybe<FooterDocAccessFields_Contact_PhoneNumber_Create>;
  delete: Maybe<FooterDocAccessFields_Contact_PhoneNumber_Delete>;
  read: Maybe<FooterDocAccessFields_Contact_PhoneNumber_Read>;
  update: Maybe<FooterDocAccessFields_Contact_PhoneNumber_Update>;
};

export type FooterDocAccessFields_Contact_PhoneNumber_Create = {
  __typename?: "FooterDocAccessFields_contact_phoneNumber_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_PhoneNumber_Delete = {
  __typename?: "FooterDocAccessFields_contact_phoneNumber_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_PhoneNumber_Read = {
  __typename?: "FooterDocAccessFields_contact_phoneNumber_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_PhoneNumber_Update = {
  __typename?: "FooterDocAccessFields_contact_phoneNumber_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Title = {
  __typename?: "FooterDocAccessFields_contact_title";
  create: Maybe<FooterDocAccessFields_Contact_Title_Create>;
  delete: Maybe<FooterDocAccessFields_Contact_Title_Delete>;
  read: Maybe<FooterDocAccessFields_Contact_Title_Read>;
  update: Maybe<FooterDocAccessFields_Contact_Title_Update>;
};

export type FooterDocAccessFields_Contact_Title_Create = {
  __typename?: "FooterDocAccessFields_contact_title_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Title_Delete = {
  __typename?: "FooterDocAccessFields_contact_title_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Title_Read = {
  __typename?: "FooterDocAccessFields_contact_title_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Contact_Title_Update = {
  __typename?: "FooterDocAccessFields_contact_title_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_CreatedAt = {
  __typename?: "FooterDocAccessFields_createdAt";
  create: Maybe<FooterDocAccessFields_CreatedAt_Create>;
  delete: Maybe<FooterDocAccessFields_CreatedAt_Delete>;
  read: Maybe<FooterDocAccessFields_CreatedAt_Read>;
  update: Maybe<FooterDocAccessFields_CreatedAt_Update>;
};

export type FooterDocAccessFields_CreatedAt_Create = {
  __typename?: "FooterDocAccessFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_CreatedAt_Delete = {
  __typename?: "FooterDocAccessFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_CreatedAt_Read = {
  __typename?: "FooterDocAccessFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_CreatedAt_Update = {
  __typename?: "FooterDocAccessFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Socials = {
  __typename?: "FooterDocAccessFields_socials";
  create: Maybe<FooterDocAccessFields_Socials_Create>;
  delete: Maybe<FooterDocAccessFields_Socials_Delete>;
  fields: Maybe<FooterDocAccessFields_Socials_Fields>;
  read: Maybe<FooterDocAccessFields_Socials_Read>;
  update: Maybe<FooterDocAccessFields_Socials_Update>;
};

export type FooterDocAccessFields_Socials_Create = {
  __typename?: "FooterDocAccessFields_socials_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Socials_Delete = {
  __typename?: "FooterDocAccessFields_socials_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Socials_Fields = {
  __typename?: "FooterDocAccessFields_socials_Fields";
  linkedinUrl: Maybe<FooterDocAccessFields_Socials_LinkedinUrl>;
};

export type FooterDocAccessFields_Socials_Read = {
  __typename?: "FooterDocAccessFields_socials_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Socials_Update = {
  __typename?: "FooterDocAccessFields_socials_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Socials_LinkedinUrl = {
  __typename?: "FooterDocAccessFields_socials_linkedinUrl";
  create: Maybe<FooterDocAccessFields_Socials_LinkedinUrl_Create>;
  delete: Maybe<FooterDocAccessFields_Socials_LinkedinUrl_Delete>;
  read: Maybe<FooterDocAccessFields_Socials_LinkedinUrl_Read>;
  update: Maybe<FooterDocAccessFields_Socials_LinkedinUrl_Update>;
};

export type FooterDocAccessFields_Socials_LinkedinUrl_Create = {
  __typename?: "FooterDocAccessFields_socials_linkedinUrl_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Socials_LinkedinUrl_Delete = {
  __typename?: "FooterDocAccessFields_socials_linkedinUrl_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Socials_LinkedinUrl_Read = {
  __typename?: "FooterDocAccessFields_socials_linkedinUrl_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_Socials_LinkedinUrl_Update = {
  __typename?: "FooterDocAccessFields_socials_linkedinUrl_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_UpdatedAt = {
  __typename?: "FooterDocAccessFields_updatedAt";
  create: Maybe<FooterDocAccessFields_UpdatedAt_Create>;
  delete: Maybe<FooterDocAccessFields_UpdatedAt_Delete>;
  read: Maybe<FooterDocAccessFields_UpdatedAt_Read>;
  update: Maybe<FooterDocAccessFields_UpdatedAt_Update>;
};

export type FooterDocAccessFields_UpdatedAt_Create = {
  __typename?: "FooterDocAccessFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_UpdatedAt_Delete = {
  __typename?: "FooterDocAccessFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_UpdatedAt_Read = {
  __typename?: "FooterDocAccessFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterDocAccessFields_UpdatedAt_Update = {
  __typename?: "FooterDocAccessFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields = {
  __typename?: "FooterFields";
  address: Maybe<FooterFields_Address>;
  contact: Maybe<FooterFields_Contact>;
  createdAt: Maybe<FooterFields_CreatedAt>;
  socials: Maybe<FooterFields_Socials>;
  updatedAt: Maybe<FooterFields_UpdatedAt>;
};

export type FooterFields_Address = {
  __typename?: "FooterFields_address";
  create: Maybe<FooterFields_Address_Create>;
  delete: Maybe<FooterFields_Address_Delete>;
  read: Maybe<FooterFields_Address_Read>;
  update: Maybe<FooterFields_Address_Update>;
};

export type FooterFields_Address_Create = {
  __typename?: "FooterFields_address_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Address_Delete = {
  __typename?: "FooterFields_address_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Address_Read = {
  __typename?: "FooterFields_address_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Address_Update = {
  __typename?: "FooterFields_address_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact = {
  __typename?: "FooterFields_contact";
  create: Maybe<FooterFields_Contact_Create>;
  delete: Maybe<FooterFields_Contact_Delete>;
  fields: Maybe<FooterFields_Contact_Fields>;
  read: Maybe<FooterFields_Contact_Read>;
  update: Maybe<FooterFields_Contact_Update>;
};

export type FooterFields_Contact_Create = {
  __typename?: "FooterFields_contact_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Delete = {
  __typename?: "FooterFields_contact_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Fields = {
  __typename?: "FooterFields_contact_Fields";
  email: Maybe<FooterFields_Contact_Email>;
  emailContent: Maybe<FooterFields_Contact_EmailContent>;
  phoneNumber: Maybe<FooterFields_Contact_PhoneNumber>;
  title: Maybe<FooterFields_Contact_Title>;
};

export type FooterFields_Contact_Read = {
  __typename?: "FooterFields_contact_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Update = {
  __typename?: "FooterFields_contact_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Email = {
  __typename?: "FooterFields_contact_email";
  create: Maybe<FooterFields_Contact_Email_Create>;
  delete: Maybe<FooterFields_Contact_Email_Delete>;
  read: Maybe<FooterFields_Contact_Email_Read>;
  update: Maybe<FooterFields_Contact_Email_Update>;
};

export type FooterFields_Contact_EmailContent = {
  __typename?: "FooterFields_contact_emailContent";
  create: Maybe<FooterFields_Contact_EmailContent_Create>;
  delete: Maybe<FooterFields_Contact_EmailContent_Delete>;
  read: Maybe<FooterFields_Contact_EmailContent_Read>;
  update: Maybe<FooterFields_Contact_EmailContent_Update>;
};

export type FooterFields_Contact_EmailContent_Create = {
  __typename?: "FooterFields_contact_emailContent_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_EmailContent_Delete = {
  __typename?: "FooterFields_contact_emailContent_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_EmailContent_Read = {
  __typename?: "FooterFields_contact_emailContent_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_EmailContent_Update = {
  __typename?: "FooterFields_contact_emailContent_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Email_Create = {
  __typename?: "FooterFields_contact_email_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Email_Delete = {
  __typename?: "FooterFields_contact_email_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Email_Read = {
  __typename?: "FooterFields_contact_email_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Email_Update = {
  __typename?: "FooterFields_contact_email_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_PhoneNumber = {
  __typename?: "FooterFields_contact_phoneNumber";
  create: Maybe<FooterFields_Contact_PhoneNumber_Create>;
  delete: Maybe<FooterFields_Contact_PhoneNumber_Delete>;
  read: Maybe<FooterFields_Contact_PhoneNumber_Read>;
  update: Maybe<FooterFields_Contact_PhoneNumber_Update>;
};

export type FooterFields_Contact_PhoneNumber_Create = {
  __typename?: "FooterFields_contact_phoneNumber_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_PhoneNumber_Delete = {
  __typename?: "FooterFields_contact_phoneNumber_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_PhoneNumber_Read = {
  __typename?: "FooterFields_contact_phoneNumber_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_PhoneNumber_Update = {
  __typename?: "FooterFields_contact_phoneNumber_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Title = {
  __typename?: "FooterFields_contact_title";
  create: Maybe<FooterFields_Contact_Title_Create>;
  delete: Maybe<FooterFields_Contact_Title_Delete>;
  read: Maybe<FooterFields_Contact_Title_Read>;
  update: Maybe<FooterFields_Contact_Title_Update>;
};

export type FooterFields_Contact_Title_Create = {
  __typename?: "FooterFields_contact_title_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Title_Delete = {
  __typename?: "FooterFields_contact_title_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Title_Read = {
  __typename?: "FooterFields_contact_title_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Contact_Title_Update = {
  __typename?: "FooterFields_contact_title_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_CreatedAt = {
  __typename?: "FooterFields_createdAt";
  create: Maybe<FooterFields_CreatedAt_Create>;
  delete: Maybe<FooterFields_CreatedAt_Delete>;
  read: Maybe<FooterFields_CreatedAt_Read>;
  update: Maybe<FooterFields_CreatedAt_Update>;
};

export type FooterFields_CreatedAt_Create = {
  __typename?: "FooterFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_CreatedAt_Delete = {
  __typename?: "FooterFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_CreatedAt_Read = {
  __typename?: "FooterFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_CreatedAt_Update = {
  __typename?: "FooterFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Socials = {
  __typename?: "FooterFields_socials";
  create: Maybe<FooterFields_Socials_Create>;
  delete: Maybe<FooterFields_Socials_Delete>;
  fields: Maybe<FooterFields_Socials_Fields>;
  read: Maybe<FooterFields_Socials_Read>;
  update: Maybe<FooterFields_Socials_Update>;
};

export type FooterFields_Socials_Create = {
  __typename?: "FooterFields_socials_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Socials_Delete = {
  __typename?: "FooterFields_socials_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Socials_Fields = {
  __typename?: "FooterFields_socials_Fields";
  linkedinUrl: Maybe<FooterFields_Socials_LinkedinUrl>;
};

export type FooterFields_Socials_Read = {
  __typename?: "FooterFields_socials_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Socials_Update = {
  __typename?: "FooterFields_socials_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Socials_LinkedinUrl = {
  __typename?: "FooterFields_socials_linkedinUrl";
  create: Maybe<FooterFields_Socials_LinkedinUrl_Create>;
  delete: Maybe<FooterFields_Socials_LinkedinUrl_Delete>;
  read: Maybe<FooterFields_Socials_LinkedinUrl_Read>;
  update: Maybe<FooterFields_Socials_LinkedinUrl_Update>;
};

export type FooterFields_Socials_LinkedinUrl_Create = {
  __typename?: "FooterFields_socials_linkedinUrl_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Socials_LinkedinUrl_Delete = {
  __typename?: "FooterFields_socials_linkedinUrl_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Socials_LinkedinUrl_Read = {
  __typename?: "FooterFields_socials_linkedinUrl_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_Socials_LinkedinUrl_Update = {
  __typename?: "FooterFields_socials_linkedinUrl_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_UpdatedAt = {
  __typename?: "FooterFields_updatedAt";
  create: Maybe<FooterFields_UpdatedAt_Create>;
  delete: Maybe<FooterFields_UpdatedAt_Delete>;
  read: Maybe<FooterFields_UpdatedAt_Read>;
  update: Maybe<FooterFields_UpdatedAt_Update>;
};

export type FooterFields_UpdatedAt_Create = {
  __typename?: "FooterFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_UpdatedAt_Delete = {
  __typename?: "FooterFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_UpdatedAt_Read = {
  __typename?: "FooterFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type FooterFields_UpdatedAt_Update = {
  __typename?: "FooterFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type FooterReadAccess = {
  __typename?: "FooterReadAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type FooterReadDocAccess = {
  __typename?: "FooterReadDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type FooterUpdateAccess = {
  __typename?: "FooterUpdateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type FooterUpdateDocAccess = {
  __typename?: "FooterUpdateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type Footer_Contact = {
  __typename?: "Footer_Contact";
  email: Maybe<Scalars["EmailAddress"]["output"]>;
  emailContent: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type Footer_Socials = {
  __typename?: "Footer_Socials";
  linkedinUrl: Maybe<Scalars["String"]["output"]>;
};

export type IconCards = {
  __typename?: "IconCards";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  cards: Maybe<Array<IconCards_Cards>>;
  id: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type IconCards_Cards = {
  __typename?: "IconCards_Cards";
  additionalContent: Maybe<Array<IconCards_Cards_AdditionalContent>>;
  icon: Maybe<IconCards_Cards_Icon | "%future added value">;
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type IconCards_CardsTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type IconCards_Cards_AdditionalContent = BubbleTextBlock;

export enum IconCards_Cards_Icon {
  Compass = "compass",
  Eye = "eye",
  Filter = "filter",
  Heart = "heart",
  Send = "send",
  Target = "target",
  Volume_1 = "volume_1",
  Volume_2 = "volume_2",
  X = "x",
}

export type ImageHeaderBlock = {
  __typename?: "ImageHeaderBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  bubbleText: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  image: Maybe<Media>;
  text: Maybe<Scalars["String"]["output"]>;
  typewriterFx: Maybe<Scalars["Boolean"]["output"]>;
  video: Maybe<Scalars["String"]["output"]>;
};

export type ImageHeaderBlockImageArgs = {
  where?: InputMaybe<ImageHeaderBlock_Image_Where>;
};

export type ImageHeaderBlock_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ImageHeaderBlock_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Lg__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Lg__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Lg__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Lg__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Lg__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Lg__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Md__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Md__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Md__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Md__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Md__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Md__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_2k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_2k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_2k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_2k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_2k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_2k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_4k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_4k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_4k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_4k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_4k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_4k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Fullhd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Fullhd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Fullhd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Fullhd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Fullhd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Fullhd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Hd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Hd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Hd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Hd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Hd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Res_Hd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Xs__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Xs__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Xs__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_Sizes__Xs__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Xs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Sizes__Xs__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ImageHeaderBlock_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ImageHeaderBlock_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ImageHeaderBlock_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<ImageHeaderBlock_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageHeaderBlock_Image_Where_Or>>>;
  createdAt?: InputMaybe<ImageHeaderBlock_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ImageHeaderBlock_Image_Filename_Operator>;
  filesize?: InputMaybe<ImageHeaderBlock_Image_Filesize_Operator>;
  height?: InputMaybe<ImageHeaderBlock_Image_Height_Operator>;
  id?: InputMaybe<ImageHeaderBlock_Image_Id_Operator>;
  mimeType?: InputMaybe<ImageHeaderBlock_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<ImageHeaderBlock_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ImageHeaderBlock_Image_Url_Operator>;
  width?: InputMaybe<ImageHeaderBlock_Image_Width_Operator>;
};

export type ImageHeaderBlock_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<ImageHeaderBlock_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageHeaderBlock_Image_Where_Or>>>;
  createdAt?: InputMaybe<ImageHeaderBlock_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ImageHeaderBlock_Image_Filename_Operator>;
  filesize?: InputMaybe<ImageHeaderBlock_Image_Filesize_Operator>;
  height?: InputMaybe<ImageHeaderBlock_Image_Height_Operator>;
  id?: InputMaybe<ImageHeaderBlock_Image_Id_Operator>;
  mimeType?: InputMaybe<ImageHeaderBlock_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<ImageHeaderBlock_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ImageHeaderBlock_Image_Url_Operator>;
  width?: InputMaybe<ImageHeaderBlock_Image_Width_Operator>;
};

export type ImageHeaderBlock_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<ImageHeaderBlock_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ImageHeaderBlock_Image_Where_Or>>>;
  createdAt?: InputMaybe<ImageHeaderBlock_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ImageHeaderBlock_Image_Filename_Operator>;
  filesize?: InputMaybe<ImageHeaderBlock_Image_Filesize_Operator>;
  height?: InputMaybe<ImageHeaderBlock_Image_Height_Operator>;
  id?: InputMaybe<ImageHeaderBlock_Image_Id_Operator>;
  mimeType?: InputMaybe<ImageHeaderBlock_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<ImageHeaderBlock_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<ImageHeaderBlock_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ImageHeaderBlock_Image_Url_Operator>;
  width?: InputMaybe<ImageHeaderBlock_Image_Width_Operator>;
};

export type ImageHeaderBlock_Image_Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type LeadBoxBlock = {
  __typename?: "LeadBoxBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  links: Maybe<Array<LeadBoxBlock_Links>>;
  text: Maybe<Scalars["JSON"]["output"]>;
};

export type LeadBoxBlockTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LeadBoxBlock_Links = BubbleTextBlock;

export type LeifLinkBlock = {
  __typename?: "LeifLinkBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type LeifLinkBlockTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MailchimpSignupModal = {
  __typename?: "MailchimpSignupModal";
  Mailchimp: Maybe<MailchimpSignupModal_Mailchimp>;
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type MailchimpSignupModalTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MailchimpSignupModal_Mailchimp = {
  __typename?: "MailchimpSignupModal_Mailchimp";
  actionUrl: Maybe<Scalars["String"]["output"]>;
  tags: Maybe<Array<MailchimpSignupModal_Mailchimp_Tags>>;
};

export type MailchimpSignupModal_Mailchimp_Tags = {
  __typename?: "MailchimpSignupModal_Mailchimp_Tags";
  id: Maybe<Scalars["String"]["output"]>;
  tag: Maybe<Scalars["String"]["output"]>;
};

export type Media = {
  __typename?: "Media";
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  filename: Maybe<Scalars["String"]["output"]>;
  filesize: Maybe<Scalars["Float"]["output"]>;
  height: Maybe<Scalars["Float"]["output"]>;
  id: Maybe<Scalars["Int"]["output"]>;
  mimeType: Maybe<Scalars["String"]["output"]>;
  sizes: Maybe<Media_Sizes>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type MediaBlock = {
  __typename?: "MediaBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  media: Maybe<Media>;
};

export type MediaBlockMediaArgs = {
  where?: InputMaybe<MediaBlock_Media_Where>;
};

export type MediaBlock_Media_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MediaBlock_Media_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Lg__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Lg__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Lg__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Lg__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Lg__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Lg__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Md__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Md__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Md__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Md__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Md__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Md__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_2k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_2k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_2k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_2k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_2k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_2k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_4k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_4k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_4k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_4k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_4k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_4k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_Fullhd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_Fullhd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_Fullhd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_Fullhd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_Fullhd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_Fullhd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_Hd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_Hd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_Hd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Res_Hd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_Hd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Res_Hd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Xs__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Xs__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Xs__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_Sizes__Xs__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Xs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Sizes__Xs__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MediaBlock_Media_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MediaBlock_Media_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type MediaBlock_Media_Where = {
  AND?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_Or>>>;
  createdAt?: InputMaybe<MediaBlock_Media_CreatedAt_Operator>;
  filename?: InputMaybe<MediaBlock_Media_Filename_Operator>;
  filesize?: InputMaybe<MediaBlock_Media_Filesize_Operator>;
  height?: InputMaybe<MediaBlock_Media_Height_Operator>;
  id?: InputMaybe<MediaBlock_Media_Id_Operator>;
  mimeType?: InputMaybe<MediaBlock_Media_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<MediaBlock_Media_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<MediaBlock_Media_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<MediaBlock_Media_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<MediaBlock_Media_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<MediaBlock_Media_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<MediaBlock_Media_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<MediaBlock_Media_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<MediaBlock_Media_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<MediaBlock_Media_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<MediaBlock_Media_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<MediaBlock_Media_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<MediaBlock_Media_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<MediaBlock_Media_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<MediaBlock_Media_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<MediaBlock_Media_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<MediaBlock_Media_UpdatedAt_Operator>;
  url?: InputMaybe<MediaBlock_Media_Url_Operator>;
  width?: InputMaybe<MediaBlock_Media_Width_Operator>;
};

export type MediaBlock_Media_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_Or>>>;
  createdAt?: InputMaybe<MediaBlock_Media_CreatedAt_Operator>;
  filename?: InputMaybe<MediaBlock_Media_Filename_Operator>;
  filesize?: InputMaybe<MediaBlock_Media_Filesize_Operator>;
  height?: InputMaybe<MediaBlock_Media_Height_Operator>;
  id?: InputMaybe<MediaBlock_Media_Id_Operator>;
  mimeType?: InputMaybe<MediaBlock_Media_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<MediaBlock_Media_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<MediaBlock_Media_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<MediaBlock_Media_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<MediaBlock_Media_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<MediaBlock_Media_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<MediaBlock_Media_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<MediaBlock_Media_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<MediaBlock_Media_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<MediaBlock_Media_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<MediaBlock_Media_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<MediaBlock_Media_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<MediaBlock_Media_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<MediaBlock_Media_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<MediaBlock_Media_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<MediaBlock_Media_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<MediaBlock_Media_UpdatedAt_Operator>;
  url?: InputMaybe<MediaBlock_Media_Url_Operator>;
  width?: InputMaybe<MediaBlock_Media_Width_Operator>;
};

export type MediaBlock_Media_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<MediaBlock_Media_Where_Or>>>;
  createdAt?: InputMaybe<MediaBlock_Media_CreatedAt_Operator>;
  filename?: InputMaybe<MediaBlock_Media_Filename_Operator>;
  filesize?: InputMaybe<MediaBlock_Media_Filesize_Operator>;
  height?: InputMaybe<MediaBlock_Media_Height_Operator>;
  id?: InputMaybe<MediaBlock_Media_Id_Operator>;
  mimeType?: InputMaybe<MediaBlock_Media_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<MediaBlock_Media_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<MediaBlock_Media_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<MediaBlock_Media_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<MediaBlock_Media_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<MediaBlock_Media_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<MediaBlock_Media_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<MediaBlock_Media_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<MediaBlock_Media_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<MediaBlock_Media_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<MediaBlock_Media_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<MediaBlock_Media_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<MediaBlock_Media_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<MediaBlock_Media_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<MediaBlock_Media_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<MediaBlock_Media_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<MediaBlock_Media_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<MediaBlock_Media_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<MediaBlock_Media_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<MediaBlock_Media_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<MediaBlock_Media_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<MediaBlock_Media_UpdatedAt_Operator>;
  url?: InputMaybe<MediaBlock_Media_Url_Operator>;
  width?: InputMaybe<MediaBlock_Media_Width_Operator>;
};

export type MediaBlock_Media_Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes = {
  __typename?: "Media_Sizes";
  lg: Maybe<Media_Sizes_Lg>;
  md: Maybe<Media_Sizes_Md>;
  res_2k: Maybe<Media_Sizes_Res_2k>;
  res_4k: Maybe<Media_Sizes_Res_4k>;
  res_fullhd: Maybe<Media_Sizes_Res_Fullhd>;
  res_hd: Maybe<Media_Sizes_Res_Hd>;
  xs: Maybe<Media_Sizes_Xs>;
};

export type Media_Sizes_Lg = {
  __typename?: "Media_Sizes_Lg";
  filename: Maybe<Scalars["String"]["output"]>;
  filesize: Maybe<Scalars["Float"]["output"]>;
  height: Maybe<Scalars["Float"]["output"]>;
  mimeType: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type Media_Sizes_Md = {
  __typename?: "Media_Sizes_Md";
  filename: Maybe<Scalars["String"]["output"]>;
  filesize: Maybe<Scalars["Float"]["output"]>;
  height: Maybe<Scalars["Float"]["output"]>;
  mimeType: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type Media_Sizes_Res_2k = {
  __typename?: "Media_Sizes_Res_2k";
  filename: Maybe<Scalars["String"]["output"]>;
  filesize: Maybe<Scalars["Float"]["output"]>;
  height: Maybe<Scalars["Float"]["output"]>;
  mimeType: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type Media_Sizes_Res_4k = {
  __typename?: "Media_Sizes_Res_4k";
  filename: Maybe<Scalars["String"]["output"]>;
  filesize: Maybe<Scalars["Float"]["output"]>;
  height: Maybe<Scalars["Float"]["output"]>;
  mimeType: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type Media_Sizes_Res_Fullhd = {
  __typename?: "Media_Sizes_Res_fullhd";
  filename: Maybe<Scalars["String"]["output"]>;
  filesize: Maybe<Scalars["Float"]["output"]>;
  height: Maybe<Scalars["Float"]["output"]>;
  mimeType: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type Media_Sizes_Res_Hd = {
  __typename?: "Media_Sizes_Res_hd";
  filename: Maybe<Scalars["String"]["output"]>;
  filesize: Maybe<Scalars["Float"]["output"]>;
  height: Maybe<Scalars["Float"]["output"]>;
  mimeType: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type Media_Sizes_Xs = {
  __typename?: "Media_Sizes_Xs";
  filename: Maybe<Scalars["String"]["output"]>;
  filesize: Maybe<Scalars["Float"]["output"]>;
  height: Maybe<Scalars["Float"]["output"]>;
  mimeType: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
  width: Maybe<Scalars["Float"]["output"]>;
};

export type Media_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Media_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Id_Operator = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Int"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  less_than?: InputMaybe<Scalars["Int"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  not_equals?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Media_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Lg__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Lg__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Lg__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Lg__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Lg__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Lg__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Md__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Md__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Md__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Md__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Md__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Md__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_2k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_2k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_2k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_2k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_2k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_2k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_4k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_4k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_4k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_4k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_4k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_4k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_Fullhd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_Fullhd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_Fullhd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_Fullhd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_Fullhd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_Fullhd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_Hd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_Hd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_Hd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Res_Hd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_Hd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Res_Hd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Xs__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Xs__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Xs__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_Sizes__Xs__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Xs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Sizes__Xs__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Media_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Media_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Media_Where = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<Media_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<Media_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<Media_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<Media_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<Media_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<Media_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<Media_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<Media_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<Media_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<Media_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<Media_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<Media_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<Media_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<Media_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<Media_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<Media_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<Media_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<Media_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<Media_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<Media_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<Media_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<Media_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<Media_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<Media_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<Media_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<Media_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<Media_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<Media_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<Media_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<Media_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<Media_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<Media_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<Media_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<Media_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<Media_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<Media_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<Media_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<Media_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<Media_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<Media_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<Media_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<Media_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<Media_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<Media_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<Media_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<Media_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<Media_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<Media_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<Media_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<Media_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<Media_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<Media_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<Media_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<Media_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<Media_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<Media_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<Media_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<Media_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<Media_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<Media_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<Media_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<Media_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<Media_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<Media_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<Media_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<Media_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<Media_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<Media_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<Media_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<Media_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<Media_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<Media_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<Media_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<Media_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<Media_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<Media_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<Media_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<Media_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<Media_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<Media_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<Media_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<Media_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<Media_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<Media_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Media_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Media_Where_Or>>>;
  createdAt?: InputMaybe<Media_CreatedAt_Operator>;
  filename?: InputMaybe<Media_Filename_Operator>;
  filesize?: InputMaybe<Media_Filesize_Operator>;
  height?: InputMaybe<Media_Height_Operator>;
  id?: InputMaybe<Media_Id_Operator>;
  mimeType?: InputMaybe<Media_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<Media_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<Media_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<Media_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<Media_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<Media_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<Media_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<Media_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<Media_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<Media_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<Media_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<Media_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<Media_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<Media_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<Media_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<Media_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<Media_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<Media_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<Media_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<Media_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<Media_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<Media_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<Media_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<Media_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<Media_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<Media_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<Media_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<Media_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<Media_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<Media_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<Media_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<Media_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<Media_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<Media_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<Media_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<Media_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<Media_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<Media_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<Media_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<Media_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<Media_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<Media_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<Media_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<Media_UpdatedAt_Operator>;
  url?: InputMaybe<Media_Url_Operator>;
  width?: InputMaybe<Media_Width_Operator>;
};

export type Media_Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Medias = {
  __typename?: "Medias";
  docs: Maybe<Array<Maybe<Media>>>;
  hasNextPage: Maybe<Scalars["Boolean"]["output"]>;
  hasPrevPage: Maybe<Scalars["Boolean"]["output"]>;
  limit: Maybe<Scalars["Int"]["output"]>;
  nextPage: Maybe<Scalars["Int"]["output"]>;
  offset: Maybe<Scalars["Int"]["output"]>;
  page: Maybe<Scalars["Int"]["output"]>;
  pagingCounter: Maybe<Scalars["Int"]["output"]>;
  prevPage: Maybe<Scalars["Int"]["output"]>;
  totalDocs: Maybe<Scalars["Int"]["output"]>;
  totalPages: Maybe<Scalars["Int"]["output"]>;
};

export type MediasCreateAccess = {
  __typename?: "MediasCreateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type MediasCreateDocAccess = {
  __typename?: "MediasCreateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type MediasDeleteAccess = {
  __typename?: "MediasDeleteAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type MediasDeleteDocAccess = {
  __typename?: "MediasDeleteDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type MediasDocAccessFields = {
  __typename?: "MediasDocAccessFields";
  createdAt: Maybe<MediasDocAccessFields_CreatedAt>;
  filename: Maybe<MediasDocAccessFields_Filename>;
  filesize: Maybe<MediasDocAccessFields_Filesize>;
  height: Maybe<MediasDocAccessFields_Height>;
  mimeType: Maybe<MediasDocAccessFields_MimeType>;
  sizes: Maybe<MediasDocAccessFields_Sizes>;
  updatedAt: Maybe<MediasDocAccessFields_UpdatedAt>;
  url: Maybe<MediasDocAccessFields_Url>;
  width: Maybe<MediasDocAccessFields_Width>;
};

export type MediasDocAccessFields_CreatedAt = {
  __typename?: "MediasDocAccessFields_createdAt";
  create: Maybe<MediasDocAccessFields_CreatedAt_Create>;
  delete: Maybe<MediasDocAccessFields_CreatedAt_Delete>;
  read: Maybe<MediasDocAccessFields_CreatedAt_Read>;
  update: Maybe<MediasDocAccessFields_CreatedAt_Update>;
};

export type MediasDocAccessFields_CreatedAt_Create = {
  __typename?: "MediasDocAccessFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_CreatedAt_Delete = {
  __typename?: "MediasDocAccessFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_CreatedAt_Read = {
  __typename?: "MediasDocAccessFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_CreatedAt_Update = {
  __typename?: "MediasDocAccessFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Filename = {
  __typename?: "MediasDocAccessFields_filename";
  create: Maybe<MediasDocAccessFields_Filename_Create>;
  delete: Maybe<MediasDocAccessFields_Filename_Delete>;
  read: Maybe<MediasDocAccessFields_Filename_Read>;
  update: Maybe<MediasDocAccessFields_Filename_Update>;
};

export type MediasDocAccessFields_Filename_Create = {
  __typename?: "MediasDocAccessFields_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Filename_Delete = {
  __typename?: "MediasDocAccessFields_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Filename_Read = {
  __typename?: "MediasDocAccessFields_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Filename_Update = {
  __typename?: "MediasDocAccessFields_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Filesize = {
  __typename?: "MediasDocAccessFields_filesize";
  create: Maybe<MediasDocAccessFields_Filesize_Create>;
  delete: Maybe<MediasDocAccessFields_Filesize_Delete>;
  read: Maybe<MediasDocAccessFields_Filesize_Read>;
  update: Maybe<MediasDocAccessFields_Filesize_Update>;
};

export type MediasDocAccessFields_Filesize_Create = {
  __typename?: "MediasDocAccessFields_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Filesize_Delete = {
  __typename?: "MediasDocAccessFields_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Filesize_Read = {
  __typename?: "MediasDocAccessFields_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Filesize_Update = {
  __typename?: "MediasDocAccessFields_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Height = {
  __typename?: "MediasDocAccessFields_height";
  create: Maybe<MediasDocAccessFields_Height_Create>;
  delete: Maybe<MediasDocAccessFields_Height_Delete>;
  read: Maybe<MediasDocAccessFields_Height_Read>;
  update: Maybe<MediasDocAccessFields_Height_Update>;
};

export type MediasDocAccessFields_Height_Create = {
  __typename?: "MediasDocAccessFields_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Height_Delete = {
  __typename?: "MediasDocAccessFields_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Height_Read = {
  __typename?: "MediasDocAccessFields_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Height_Update = {
  __typename?: "MediasDocAccessFields_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_MimeType = {
  __typename?: "MediasDocAccessFields_mimeType";
  create: Maybe<MediasDocAccessFields_MimeType_Create>;
  delete: Maybe<MediasDocAccessFields_MimeType_Delete>;
  read: Maybe<MediasDocAccessFields_MimeType_Read>;
  update: Maybe<MediasDocAccessFields_MimeType_Update>;
};

export type MediasDocAccessFields_MimeType_Create = {
  __typename?: "MediasDocAccessFields_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_MimeType_Delete = {
  __typename?: "MediasDocAccessFields_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_MimeType_Read = {
  __typename?: "MediasDocAccessFields_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_MimeType_Update = {
  __typename?: "MediasDocAccessFields_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes = {
  __typename?: "MediasDocAccessFields_sizes";
  create: Maybe<MediasDocAccessFields_Sizes_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Delete>;
  fields: Maybe<MediasDocAccessFields_Sizes_Fields>;
  read: Maybe<MediasDocAccessFields_Sizes_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Update>;
};

export type MediasDocAccessFields_Sizes_Create = {
  __typename?: "MediasDocAccessFields_sizes_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Delete = {
  __typename?: "MediasDocAccessFields_sizes_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Fields = {
  __typename?: "MediasDocAccessFields_sizes_Fields";
  lg: Maybe<MediasDocAccessFields_Sizes_Lg>;
  md: Maybe<MediasDocAccessFields_Sizes_Md>;
  res_2k: Maybe<MediasDocAccessFields_Sizes_Res_2k>;
  res_4k: Maybe<MediasDocAccessFields_Sizes_Res_4k>;
  res_fullhd: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd>;
  res_hd: Maybe<MediasDocAccessFields_Sizes_Res_Hd>;
  xs: Maybe<MediasDocAccessFields_Sizes_Xs>;
};

export type MediasDocAccessFields_Sizes_Read = {
  __typename?: "MediasDocAccessFields_sizes_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Update = {
  __typename?: "MediasDocAccessFields_sizes_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg = {
  __typename?: "MediasDocAccessFields_sizes_lg";
  create: Maybe<MediasDocAccessFields_Sizes_Lg_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Lg_Delete>;
  fields: Maybe<MediasDocAccessFields_Sizes_Lg_Fields>;
  read: Maybe<MediasDocAccessFields_Sizes_Lg_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Lg_Update>;
};

export type MediasDocAccessFields_Sizes_Lg_Create = {
  __typename?: "MediasDocAccessFields_sizes_lg_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Delete = {
  __typename?: "MediasDocAccessFields_sizes_lg_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Fields = {
  __typename?: "MediasDocAccessFields_sizes_lg_Fields";
  filename: Maybe<MediasDocAccessFields_Sizes_Lg_Filename>;
  filesize: Maybe<MediasDocAccessFields_Sizes_Lg_Filesize>;
  height: Maybe<MediasDocAccessFields_Sizes_Lg_Height>;
  mimeType: Maybe<MediasDocAccessFields_Sizes_Lg_MimeType>;
  url: Maybe<MediasDocAccessFields_Sizes_Lg_Url>;
  width: Maybe<MediasDocAccessFields_Sizes_Lg_Width>;
};

export type MediasDocAccessFields_Sizes_Lg_Read = {
  __typename?: "MediasDocAccessFields_sizes_lg_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Update = {
  __typename?: "MediasDocAccessFields_sizes_lg_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Filename = {
  __typename?: "MediasDocAccessFields_sizes_lg_filename";
  create: Maybe<MediasDocAccessFields_Sizes_Lg_Filename_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Lg_Filename_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Lg_Filename_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Lg_Filename_Update>;
};

export type MediasDocAccessFields_Sizes_Lg_Filename_Create = {
  __typename?: "MediasDocAccessFields_sizes_lg_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Filename_Delete = {
  __typename?: "MediasDocAccessFields_sizes_lg_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Filename_Read = {
  __typename?: "MediasDocAccessFields_sizes_lg_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Filename_Update = {
  __typename?: "MediasDocAccessFields_sizes_lg_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Filesize = {
  __typename?: "MediasDocAccessFields_sizes_lg_filesize";
  create: Maybe<MediasDocAccessFields_Sizes_Lg_Filesize_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Lg_Filesize_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Lg_Filesize_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Lg_Filesize_Update>;
};

export type MediasDocAccessFields_Sizes_Lg_Filesize_Create = {
  __typename?: "MediasDocAccessFields_sizes_lg_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Filesize_Delete = {
  __typename?: "MediasDocAccessFields_sizes_lg_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Filesize_Read = {
  __typename?: "MediasDocAccessFields_sizes_lg_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Filesize_Update = {
  __typename?: "MediasDocAccessFields_sizes_lg_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Height = {
  __typename?: "MediasDocAccessFields_sizes_lg_height";
  create: Maybe<MediasDocAccessFields_Sizes_Lg_Height_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Lg_Height_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Lg_Height_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Lg_Height_Update>;
};

export type MediasDocAccessFields_Sizes_Lg_Height_Create = {
  __typename?: "MediasDocAccessFields_sizes_lg_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Height_Delete = {
  __typename?: "MediasDocAccessFields_sizes_lg_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Height_Read = {
  __typename?: "MediasDocAccessFields_sizes_lg_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Height_Update = {
  __typename?: "MediasDocAccessFields_sizes_lg_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_MimeType = {
  __typename?: "MediasDocAccessFields_sizes_lg_mimeType";
  create: Maybe<MediasDocAccessFields_Sizes_Lg_MimeType_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Lg_MimeType_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Lg_MimeType_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Lg_MimeType_Update>;
};

export type MediasDocAccessFields_Sizes_Lg_MimeType_Create = {
  __typename?: "MediasDocAccessFields_sizes_lg_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_MimeType_Delete = {
  __typename?: "MediasDocAccessFields_sizes_lg_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_MimeType_Read = {
  __typename?: "MediasDocAccessFields_sizes_lg_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_MimeType_Update = {
  __typename?: "MediasDocAccessFields_sizes_lg_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Url = {
  __typename?: "MediasDocAccessFields_sizes_lg_url";
  create: Maybe<MediasDocAccessFields_Sizes_Lg_Url_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Lg_Url_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Lg_Url_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Lg_Url_Update>;
};

export type MediasDocAccessFields_Sizes_Lg_Url_Create = {
  __typename?: "MediasDocAccessFields_sizes_lg_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Url_Delete = {
  __typename?: "MediasDocAccessFields_sizes_lg_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Url_Read = {
  __typename?: "MediasDocAccessFields_sizes_lg_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Url_Update = {
  __typename?: "MediasDocAccessFields_sizes_lg_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Width = {
  __typename?: "MediasDocAccessFields_sizes_lg_width";
  create: Maybe<MediasDocAccessFields_Sizes_Lg_Width_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Lg_Width_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Lg_Width_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Lg_Width_Update>;
};

export type MediasDocAccessFields_Sizes_Lg_Width_Create = {
  __typename?: "MediasDocAccessFields_sizes_lg_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Width_Delete = {
  __typename?: "MediasDocAccessFields_sizes_lg_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Width_Read = {
  __typename?: "MediasDocAccessFields_sizes_lg_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Lg_Width_Update = {
  __typename?: "MediasDocAccessFields_sizes_lg_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md = {
  __typename?: "MediasDocAccessFields_sizes_md";
  create: Maybe<MediasDocAccessFields_Sizes_Md_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Md_Delete>;
  fields: Maybe<MediasDocAccessFields_Sizes_Md_Fields>;
  read: Maybe<MediasDocAccessFields_Sizes_Md_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Md_Update>;
};

export type MediasDocAccessFields_Sizes_Md_Create = {
  __typename?: "MediasDocAccessFields_sizes_md_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Delete = {
  __typename?: "MediasDocAccessFields_sizes_md_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Fields = {
  __typename?: "MediasDocAccessFields_sizes_md_Fields";
  filename: Maybe<MediasDocAccessFields_Sizes_Md_Filename>;
  filesize: Maybe<MediasDocAccessFields_Sizes_Md_Filesize>;
  height: Maybe<MediasDocAccessFields_Sizes_Md_Height>;
  mimeType: Maybe<MediasDocAccessFields_Sizes_Md_MimeType>;
  url: Maybe<MediasDocAccessFields_Sizes_Md_Url>;
  width: Maybe<MediasDocAccessFields_Sizes_Md_Width>;
};

export type MediasDocAccessFields_Sizes_Md_Read = {
  __typename?: "MediasDocAccessFields_sizes_md_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Update = {
  __typename?: "MediasDocAccessFields_sizes_md_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Filename = {
  __typename?: "MediasDocAccessFields_sizes_md_filename";
  create: Maybe<MediasDocAccessFields_Sizes_Md_Filename_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Md_Filename_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Md_Filename_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Md_Filename_Update>;
};

export type MediasDocAccessFields_Sizes_Md_Filename_Create = {
  __typename?: "MediasDocAccessFields_sizes_md_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Filename_Delete = {
  __typename?: "MediasDocAccessFields_sizes_md_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Filename_Read = {
  __typename?: "MediasDocAccessFields_sizes_md_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Filename_Update = {
  __typename?: "MediasDocAccessFields_sizes_md_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Filesize = {
  __typename?: "MediasDocAccessFields_sizes_md_filesize";
  create: Maybe<MediasDocAccessFields_Sizes_Md_Filesize_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Md_Filesize_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Md_Filesize_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Md_Filesize_Update>;
};

export type MediasDocAccessFields_Sizes_Md_Filesize_Create = {
  __typename?: "MediasDocAccessFields_sizes_md_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Filesize_Delete = {
  __typename?: "MediasDocAccessFields_sizes_md_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Filesize_Read = {
  __typename?: "MediasDocAccessFields_sizes_md_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Filesize_Update = {
  __typename?: "MediasDocAccessFields_sizes_md_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Height = {
  __typename?: "MediasDocAccessFields_sizes_md_height";
  create: Maybe<MediasDocAccessFields_Sizes_Md_Height_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Md_Height_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Md_Height_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Md_Height_Update>;
};

export type MediasDocAccessFields_Sizes_Md_Height_Create = {
  __typename?: "MediasDocAccessFields_sizes_md_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Height_Delete = {
  __typename?: "MediasDocAccessFields_sizes_md_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Height_Read = {
  __typename?: "MediasDocAccessFields_sizes_md_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Height_Update = {
  __typename?: "MediasDocAccessFields_sizes_md_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_MimeType = {
  __typename?: "MediasDocAccessFields_sizes_md_mimeType";
  create: Maybe<MediasDocAccessFields_Sizes_Md_MimeType_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Md_MimeType_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Md_MimeType_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Md_MimeType_Update>;
};

export type MediasDocAccessFields_Sizes_Md_MimeType_Create = {
  __typename?: "MediasDocAccessFields_sizes_md_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_MimeType_Delete = {
  __typename?: "MediasDocAccessFields_sizes_md_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_MimeType_Read = {
  __typename?: "MediasDocAccessFields_sizes_md_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_MimeType_Update = {
  __typename?: "MediasDocAccessFields_sizes_md_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Url = {
  __typename?: "MediasDocAccessFields_sizes_md_url";
  create: Maybe<MediasDocAccessFields_Sizes_Md_Url_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Md_Url_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Md_Url_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Md_Url_Update>;
};

export type MediasDocAccessFields_Sizes_Md_Url_Create = {
  __typename?: "MediasDocAccessFields_sizes_md_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Url_Delete = {
  __typename?: "MediasDocAccessFields_sizes_md_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Url_Read = {
  __typename?: "MediasDocAccessFields_sizes_md_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Url_Update = {
  __typename?: "MediasDocAccessFields_sizes_md_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Width = {
  __typename?: "MediasDocAccessFields_sizes_md_width";
  create: Maybe<MediasDocAccessFields_Sizes_Md_Width_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Md_Width_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Md_Width_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Md_Width_Update>;
};

export type MediasDocAccessFields_Sizes_Md_Width_Create = {
  __typename?: "MediasDocAccessFields_sizes_md_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Width_Delete = {
  __typename?: "MediasDocAccessFields_sizes_md_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Width_Read = {
  __typename?: "MediasDocAccessFields_sizes_md_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Md_Width_Update = {
  __typename?: "MediasDocAccessFields_sizes_md_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k = {
  __typename?: "MediasDocAccessFields_sizes_res_2k";
  create: Maybe<MediasDocAccessFields_Sizes_Res_2k_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_2k_Delete>;
  fields: Maybe<MediasDocAccessFields_Sizes_Res_2k_Fields>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_2k_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_2k_Update>;
};

export type MediasDocAccessFields_Sizes_Res_2k_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Fields = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_Fields";
  filename: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filename>;
  filesize: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filesize>;
  height: Maybe<MediasDocAccessFields_Sizes_Res_2k_Height>;
  mimeType: Maybe<MediasDocAccessFields_Sizes_Res_2k_MimeType>;
  url: Maybe<MediasDocAccessFields_Sizes_Res_2k_Url>;
  width: Maybe<MediasDocAccessFields_Sizes_Res_2k_Width>;
};

export type MediasDocAccessFields_Sizes_Res_2k_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Filename = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filename";
  create: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filename_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filename_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filename_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filename_Update>;
};

export type MediasDocAccessFields_Sizes_Res_2k_Filename_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Filename_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Filename_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Filename_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Filesize = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filesize";
  create: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filesize_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filesize_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filesize_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_2k_Filesize_Update>;
};

export type MediasDocAccessFields_Sizes_Res_2k_Filesize_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Filesize_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Filesize_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Filesize_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Height = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_height";
  create: Maybe<MediasDocAccessFields_Sizes_Res_2k_Height_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_2k_Height_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_2k_Height_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_2k_Height_Update>;
};

export type MediasDocAccessFields_Sizes_Res_2k_Height_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Height_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Height_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Height_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_MimeType = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_mimeType";
  create: Maybe<MediasDocAccessFields_Sizes_Res_2k_MimeType_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_2k_MimeType_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_2k_MimeType_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_2k_MimeType_Update>;
};

export type MediasDocAccessFields_Sizes_Res_2k_MimeType_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_MimeType_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_MimeType_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_MimeType_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Url = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_url";
  create: Maybe<MediasDocAccessFields_Sizes_Res_2k_Url_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_2k_Url_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_2k_Url_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_2k_Url_Update>;
};

export type MediasDocAccessFields_Sizes_Res_2k_Url_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Url_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Url_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Url_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Width = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_width";
  create: Maybe<MediasDocAccessFields_Sizes_Res_2k_Width_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_2k_Width_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_2k_Width_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_2k_Width_Update>;
};

export type MediasDocAccessFields_Sizes_Res_2k_Width_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Width_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Width_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_2k_Width_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_2k_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k = {
  __typename?: "MediasDocAccessFields_sizes_res_4k";
  create: Maybe<MediasDocAccessFields_Sizes_Res_4k_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_4k_Delete>;
  fields: Maybe<MediasDocAccessFields_Sizes_Res_4k_Fields>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_4k_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_4k_Update>;
};

export type MediasDocAccessFields_Sizes_Res_4k_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Fields = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_Fields";
  filename: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filename>;
  filesize: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filesize>;
  height: Maybe<MediasDocAccessFields_Sizes_Res_4k_Height>;
  mimeType: Maybe<MediasDocAccessFields_Sizes_Res_4k_MimeType>;
  url: Maybe<MediasDocAccessFields_Sizes_Res_4k_Url>;
  width: Maybe<MediasDocAccessFields_Sizes_Res_4k_Width>;
};

export type MediasDocAccessFields_Sizes_Res_4k_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Filename = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filename";
  create: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filename_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filename_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filename_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filename_Update>;
};

export type MediasDocAccessFields_Sizes_Res_4k_Filename_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Filename_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Filename_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Filename_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Filesize = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filesize";
  create: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filesize_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filesize_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filesize_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_4k_Filesize_Update>;
};

export type MediasDocAccessFields_Sizes_Res_4k_Filesize_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Filesize_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Filesize_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Filesize_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Height = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_height";
  create: Maybe<MediasDocAccessFields_Sizes_Res_4k_Height_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_4k_Height_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_4k_Height_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_4k_Height_Update>;
};

export type MediasDocAccessFields_Sizes_Res_4k_Height_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Height_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Height_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Height_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_MimeType = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_mimeType";
  create: Maybe<MediasDocAccessFields_Sizes_Res_4k_MimeType_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_4k_MimeType_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_4k_MimeType_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_4k_MimeType_Update>;
};

export type MediasDocAccessFields_Sizes_Res_4k_MimeType_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_MimeType_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_MimeType_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_MimeType_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Url = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_url";
  create: Maybe<MediasDocAccessFields_Sizes_Res_4k_Url_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_4k_Url_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_4k_Url_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_4k_Url_Update>;
};

export type MediasDocAccessFields_Sizes_Res_4k_Url_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Url_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Url_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Url_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Width = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_width";
  create: Maybe<MediasDocAccessFields_Sizes_Res_4k_Width_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_4k_Width_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_4k_Width_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_4k_Width_Update>;
};

export type MediasDocAccessFields_Sizes_Res_4k_Width_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Width_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Width_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_4k_Width_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_4k_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Delete>;
  fields: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Fields>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Fields = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_Fields";
  filename: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filename>;
  filesize: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filesize>;
  height: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Height>;
  mimeType: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_MimeType>;
  url: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Url>;
  width: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Width>;
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filename = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filename";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filename_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filename_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filename_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filename_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filename_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filename_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filename_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filename_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filesize = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filesize";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filesize_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filesize_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filesize_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Filesize_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filesize_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filesize_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filesize_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Filesize_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Height = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_height";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Height_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Height_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Height_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Height_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Height_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Height_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Height_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Height_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_MimeType = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_mimeType";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_MimeType_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_MimeType_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_MimeType_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_MimeType_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_MimeType_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_MimeType_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_MimeType_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_MimeType_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Url = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_url";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Url_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Url_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Url_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Url_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Url_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Url_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Url_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Url_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Width = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_width";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Width_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Width_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Width_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Fullhd_Width_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Width_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Width_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Width_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Fullhd_Width_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_fullhd_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd = {
  __typename?: "MediasDocAccessFields_sizes_res_hd";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Delete>;
  fields: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Fields>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Hd_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Fields = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_Fields";
  filename: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filename>;
  filesize: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filesize>;
  height: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Height>;
  mimeType: Maybe<MediasDocAccessFields_Sizes_Res_Hd_MimeType>;
  url: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Url>;
  width: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Width>;
};

export type MediasDocAccessFields_Sizes_Res_Hd_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filename = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filename";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filename_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filename_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filename_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filename_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filename_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filename_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filename_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filename_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filesize = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filesize";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filesize_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filesize_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filesize_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Filesize_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filesize_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filesize_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filesize_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Filesize_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Height = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_height";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Height_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Height_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Height_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Height_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Hd_Height_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Height_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Height_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Height_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_MimeType = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_mimeType";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Hd_MimeType_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Hd_MimeType_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Hd_MimeType_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Hd_MimeType_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Hd_MimeType_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_MimeType_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_MimeType_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_MimeType_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Url = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_url";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Url_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Url_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Url_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Url_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Hd_Url_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Url_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Url_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Url_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Width = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_width";
  create: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Width_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Width_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Width_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Res_Hd_Width_Update>;
};

export type MediasDocAccessFields_Sizes_Res_Hd_Width_Create = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Width_Delete = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Width_Read = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Res_Hd_Width_Update = {
  __typename?: "MediasDocAccessFields_sizes_res_hd_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs = {
  __typename?: "MediasDocAccessFields_sizes_xs";
  create: Maybe<MediasDocAccessFields_Sizes_Xs_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Xs_Delete>;
  fields: Maybe<MediasDocAccessFields_Sizes_Xs_Fields>;
  read: Maybe<MediasDocAccessFields_Sizes_Xs_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Xs_Update>;
};

export type MediasDocAccessFields_Sizes_Xs_Create = {
  __typename?: "MediasDocAccessFields_sizes_xs_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Delete = {
  __typename?: "MediasDocAccessFields_sizes_xs_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Fields = {
  __typename?: "MediasDocAccessFields_sizes_xs_Fields";
  filename: Maybe<MediasDocAccessFields_Sizes_Xs_Filename>;
  filesize: Maybe<MediasDocAccessFields_Sizes_Xs_Filesize>;
  height: Maybe<MediasDocAccessFields_Sizes_Xs_Height>;
  mimeType: Maybe<MediasDocAccessFields_Sizes_Xs_MimeType>;
  url: Maybe<MediasDocAccessFields_Sizes_Xs_Url>;
  width: Maybe<MediasDocAccessFields_Sizes_Xs_Width>;
};

export type MediasDocAccessFields_Sizes_Xs_Read = {
  __typename?: "MediasDocAccessFields_sizes_xs_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Update = {
  __typename?: "MediasDocAccessFields_sizes_xs_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Filename = {
  __typename?: "MediasDocAccessFields_sizes_xs_filename";
  create: Maybe<MediasDocAccessFields_Sizes_Xs_Filename_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Xs_Filename_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Xs_Filename_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Xs_Filename_Update>;
};

export type MediasDocAccessFields_Sizes_Xs_Filename_Create = {
  __typename?: "MediasDocAccessFields_sizes_xs_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Filename_Delete = {
  __typename?: "MediasDocAccessFields_sizes_xs_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Filename_Read = {
  __typename?: "MediasDocAccessFields_sizes_xs_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Filename_Update = {
  __typename?: "MediasDocAccessFields_sizes_xs_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Filesize = {
  __typename?: "MediasDocAccessFields_sizes_xs_filesize";
  create: Maybe<MediasDocAccessFields_Sizes_Xs_Filesize_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Xs_Filesize_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Xs_Filesize_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Xs_Filesize_Update>;
};

export type MediasDocAccessFields_Sizes_Xs_Filesize_Create = {
  __typename?: "MediasDocAccessFields_sizes_xs_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Filesize_Delete = {
  __typename?: "MediasDocAccessFields_sizes_xs_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Filesize_Read = {
  __typename?: "MediasDocAccessFields_sizes_xs_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Filesize_Update = {
  __typename?: "MediasDocAccessFields_sizes_xs_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Height = {
  __typename?: "MediasDocAccessFields_sizes_xs_height";
  create: Maybe<MediasDocAccessFields_Sizes_Xs_Height_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Xs_Height_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Xs_Height_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Xs_Height_Update>;
};

export type MediasDocAccessFields_Sizes_Xs_Height_Create = {
  __typename?: "MediasDocAccessFields_sizes_xs_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Height_Delete = {
  __typename?: "MediasDocAccessFields_sizes_xs_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Height_Read = {
  __typename?: "MediasDocAccessFields_sizes_xs_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Height_Update = {
  __typename?: "MediasDocAccessFields_sizes_xs_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_MimeType = {
  __typename?: "MediasDocAccessFields_sizes_xs_mimeType";
  create: Maybe<MediasDocAccessFields_Sizes_Xs_MimeType_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Xs_MimeType_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Xs_MimeType_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Xs_MimeType_Update>;
};

export type MediasDocAccessFields_Sizes_Xs_MimeType_Create = {
  __typename?: "MediasDocAccessFields_sizes_xs_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_MimeType_Delete = {
  __typename?: "MediasDocAccessFields_sizes_xs_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_MimeType_Read = {
  __typename?: "MediasDocAccessFields_sizes_xs_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_MimeType_Update = {
  __typename?: "MediasDocAccessFields_sizes_xs_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Url = {
  __typename?: "MediasDocAccessFields_sizes_xs_url";
  create: Maybe<MediasDocAccessFields_Sizes_Xs_Url_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Xs_Url_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Xs_Url_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Xs_Url_Update>;
};

export type MediasDocAccessFields_Sizes_Xs_Url_Create = {
  __typename?: "MediasDocAccessFields_sizes_xs_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Url_Delete = {
  __typename?: "MediasDocAccessFields_sizes_xs_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Url_Read = {
  __typename?: "MediasDocAccessFields_sizes_xs_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Url_Update = {
  __typename?: "MediasDocAccessFields_sizes_xs_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Width = {
  __typename?: "MediasDocAccessFields_sizes_xs_width";
  create: Maybe<MediasDocAccessFields_Sizes_Xs_Width_Create>;
  delete: Maybe<MediasDocAccessFields_Sizes_Xs_Width_Delete>;
  read: Maybe<MediasDocAccessFields_Sizes_Xs_Width_Read>;
  update: Maybe<MediasDocAccessFields_Sizes_Xs_Width_Update>;
};

export type MediasDocAccessFields_Sizes_Xs_Width_Create = {
  __typename?: "MediasDocAccessFields_sizes_xs_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Width_Delete = {
  __typename?: "MediasDocAccessFields_sizes_xs_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Width_Read = {
  __typename?: "MediasDocAccessFields_sizes_xs_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Sizes_Xs_Width_Update = {
  __typename?: "MediasDocAccessFields_sizes_xs_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_UpdatedAt = {
  __typename?: "MediasDocAccessFields_updatedAt";
  create: Maybe<MediasDocAccessFields_UpdatedAt_Create>;
  delete: Maybe<MediasDocAccessFields_UpdatedAt_Delete>;
  read: Maybe<MediasDocAccessFields_UpdatedAt_Read>;
  update: Maybe<MediasDocAccessFields_UpdatedAt_Update>;
};

export type MediasDocAccessFields_UpdatedAt_Create = {
  __typename?: "MediasDocAccessFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_UpdatedAt_Delete = {
  __typename?: "MediasDocAccessFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_UpdatedAt_Read = {
  __typename?: "MediasDocAccessFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_UpdatedAt_Update = {
  __typename?: "MediasDocAccessFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Url = {
  __typename?: "MediasDocAccessFields_url";
  create: Maybe<MediasDocAccessFields_Url_Create>;
  delete: Maybe<MediasDocAccessFields_Url_Delete>;
  read: Maybe<MediasDocAccessFields_Url_Read>;
  update: Maybe<MediasDocAccessFields_Url_Update>;
};

export type MediasDocAccessFields_Url_Create = {
  __typename?: "MediasDocAccessFields_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Url_Delete = {
  __typename?: "MediasDocAccessFields_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Url_Read = {
  __typename?: "MediasDocAccessFields_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Url_Update = {
  __typename?: "MediasDocAccessFields_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Width = {
  __typename?: "MediasDocAccessFields_width";
  create: Maybe<MediasDocAccessFields_Width_Create>;
  delete: Maybe<MediasDocAccessFields_Width_Delete>;
  read: Maybe<MediasDocAccessFields_Width_Read>;
  update: Maybe<MediasDocAccessFields_Width_Update>;
};

export type MediasDocAccessFields_Width_Create = {
  __typename?: "MediasDocAccessFields_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Width_Delete = {
  __typename?: "MediasDocAccessFields_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Width_Read = {
  __typename?: "MediasDocAccessFields_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasDocAccessFields_Width_Update = {
  __typename?: "MediasDocAccessFields_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields = {
  __typename?: "MediasFields";
  createdAt: Maybe<MediasFields_CreatedAt>;
  filename: Maybe<MediasFields_Filename>;
  filesize: Maybe<MediasFields_Filesize>;
  height: Maybe<MediasFields_Height>;
  mimeType: Maybe<MediasFields_MimeType>;
  sizes: Maybe<MediasFields_Sizes>;
  updatedAt: Maybe<MediasFields_UpdatedAt>;
  url: Maybe<MediasFields_Url>;
  width: Maybe<MediasFields_Width>;
};

export type MediasFields_CreatedAt = {
  __typename?: "MediasFields_createdAt";
  create: Maybe<MediasFields_CreatedAt_Create>;
  delete: Maybe<MediasFields_CreatedAt_Delete>;
  read: Maybe<MediasFields_CreatedAt_Read>;
  update: Maybe<MediasFields_CreatedAt_Update>;
};

export type MediasFields_CreatedAt_Create = {
  __typename?: "MediasFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_CreatedAt_Delete = {
  __typename?: "MediasFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_CreatedAt_Read = {
  __typename?: "MediasFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_CreatedAt_Update = {
  __typename?: "MediasFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Filename = {
  __typename?: "MediasFields_filename";
  create: Maybe<MediasFields_Filename_Create>;
  delete: Maybe<MediasFields_Filename_Delete>;
  read: Maybe<MediasFields_Filename_Read>;
  update: Maybe<MediasFields_Filename_Update>;
};

export type MediasFields_Filename_Create = {
  __typename?: "MediasFields_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Filename_Delete = {
  __typename?: "MediasFields_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Filename_Read = {
  __typename?: "MediasFields_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Filename_Update = {
  __typename?: "MediasFields_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Filesize = {
  __typename?: "MediasFields_filesize";
  create: Maybe<MediasFields_Filesize_Create>;
  delete: Maybe<MediasFields_Filesize_Delete>;
  read: Maybe<MediasFields_Filesize_Read>;
  update: Maybe<MediasFields_Filesize_Update>;
};

export type MediasFields_Filesize_Create = {
  __typename?: "MediasFields_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Filesize_Delete = {
  __typename?: "MediasFields_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Filesize_Read = {
  __typename?: "MediasFields_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Filesize_Update = {
  __typename?: "MediasFields_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Height = {
  __typename?: "MediasFields_height";
  create: Maybe<MediasFields_Height_Create>;
  delete: Maybe<MediasFields_Height_Delete>;
  read: Maybe<MediasFields_Height_Read>;
  update: Maybe<MediasFields_Height_Update>;
};

export type MediasFields_Height_Create = {
  __typename?: "MediasFields_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Height_Delete = {
  __typename?: "MediasFields_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Height_Read = {
  __typename?: "MediasFields_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Height_Update = {
  __typename?: "MediasFields_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_MimeType = {
  __typename?: "MediasFields_mimeType";
  create: Maybe<MediasFields_MimeType_Create>;
  delete: Maybe<MediasFields_MimeType_Delete>;
  read: Maybe<MediasFields_MimeType_Read>;
  update: Maybe<MediasFields_MimeType_Update>;
};

export type MediasFields_MimeType_Create = {
  __typename?: "MediasFields_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_MimeType_Delete = {
  __typename?: "MediasFields_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_MimeType_Read = {
  __typename?: "MediasFields_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_MimeType_Update = {
  __typename?: "MediasFields_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes = {
  __typename?: "MediasFields_sizes";
  create: Maybe<MediasFields_Sizes_Create>;
  delete: Maybe<MediasFields_Sizes_Delete>;
  fields: Maybe<MediasFields_Sizes_Fields>;
  read: Maybe<MediasFields_Sizes_Read>;
  update: Maybe<MediasFields_Sizes_Update>;
};

export type MediasFields_Sizes_Create = {
  __typename?: "MediasFields_sizes_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Delete = {
  __typename?: "MediasFields_sizes_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Fields = {
  __typename?: "MediasFields_sizes_Fields";
  lg: Maybe<MediasFields_Sizes_Lg>;
  md: Maybe<MediasFields_Sizes_Md>;
  res_2k: Maybe<MediasFields_Sizes_Res_2k>;
  res_4k: Maybe<MediasFields_Sizes_Res_4k>;
  res_fullhd: Maybe<MediasFields_Sizes_Res_Fullhd>;
  res_hd: Maybe<MediasFields_Sizes_Res_Hd>;
  xs: Maybe<MediasFields_Sizes_Xs>;
};

export type MediasFields_Sizes_Read = {
  __typename?: "MediasFields_sizes_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Update = {
  __typename?: "MediasFields_sizes_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg = {
  __typename?: "MediasFields_sizes_lg";
  create: Maybe<MediasFields_Sizes_Lg_Create>;
  delete: Maybe<MediasFields_Sizes_Lg_Delete>;
  fields: Maybe<MediasFields_Sizes_Lg_Fields>;
  read: Maybe<MediasFields_Sizes_Lg_Read>;
  update: Maybe<MediasFields_Sizes_Lg_Update>;
};

export type MediasFields_Sizes_Lg_Create = {
  __typename?: "MediasFields_sizes_lg_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Delete = {
  __typename?: "MediasFields_sizes_lg_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Fields = {
  __typename?: "MediasFields_sizes_lg_Fields";
  filename: Maybe<MediasFields_Sizes_Lg_Filename>;
  filesize: Maybe<MediasFields_Sizes_Lg_Filesize>;
  height: Maybe<MediasFields_Sizes_Lg_Height>;
  mimeType: Maybe<MediasFields_Sizes_Lg_MimeType>;
  url: Maybe<MediasFields_Sizes_Lg_Url>;
  width: Maybe<MediasFields_Sizes_Lg_Width>;
};

export type MediasFields_Sizes_Lg_Read = {
  __typename?: "MediasFields_sizes_lg_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Update = {
  __typename?: "MediasFields_sizes_lg_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Filename = {
  __typename?: "MediasFields_sizes_lg_filename";
  create: Maybe<MediasFields_Sizes_Lg_Filename_Create>;
  delete: Maybe<MediasFields_Sizes_Lg_Filename_Delete>;
  read: Maybe<MediasFields_Sizes_Lg_Filename_Read>;
  update: Maybe<MediasFields_Sizes_Lg_Filename_Update>;
};

export type MediasFields_Sizes_Lg_Filename_Create = {
  __typename?: "MediasFields_sizes_lg_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Filename_Delete = {
  __typename?: "MediasFields_sizes_lg_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Filename_Read = {
  __typename?: "MediasFields_sizes_lg_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Filename_Update = {
  __typename?: "MediasFields_sizes_lg_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Filesize = {
  __typename?: "MediasFields_sizes_lg_filesize";
  create: Maybe<MediasFields_Sizes_Lg_Filesize_Create>;
  delete: Maybe<MediasFields_Sizes_Lg_Filesize_Delete>;
  read: Maybe<MediasFields_Sizes_Lg_Filesize_Read>;
  update: Maybe<MediasFields_Sizes_Lg_Filesize_Update>;
};

export type MediasFields_Sizes_Lg_Filesize_Create = {
  __typename?: "MediasFields_sizes_lg_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Filesize_Delete = {
  __typename?: "MediasFields_sizes_lg_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Filesize_Read = {
  __typename?: "MediasFields_sizes_lg_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Filesize_Update = {
  __typename?: "MediasFields_sizes_lg_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Height = {
  __typename?: "MediasFields_sizes_lg_height";
  create: Maybe<MediasFields_Sizes_Lg_Height_Create>;
  delete: Maybe<MediasFields_Sizes_Lg_Height_Delete>;
  read: Maybe<MediasFields_Sizes_Lg_Height_Read>;
  update: Maybe<MediasFields_Sizes_Lg_Height_Update>;
};

export type MediasFields_Sizes_Lg_Height_Create = {
  __typename?: "MediasFields_sizes_lg_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Height_Delete = {
  __typename?: "MediasFields_sizes_lg_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Height_Read = {
  __typename?: "MediasFields_sizes_lg_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Height_Update = {
  __typename?: "MediasFields_sizes_lg_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_MimeType = {
  __typename?: "MediasFields_sizes_lg_mimeType";
  create: Maybe<MediasFields_Sizes_Lg_MimeType_Create>;
  delete: Maybe<MediasFields_Sizes_Lg_MimeType_Delete>;
  read: Maybe<MediasFields_Sizes_Lg_MimeType_Read>;
  update: Maybe<MediasFields_Sizes_Lg_MimeType_Update>;
};

export type MediasFields_Sizes_Lg_MimeType_Create = {
  __typename?: "MediasFields_sizes_lg_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_MimeType_Delete = {
  __typename?: "MediasFields_sizes_lg_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_MimeType_Read = {
  __typename?: "MediasFields_sizes_lg_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_MimeType_Update = {
  __typename?: "MediasFields_sizes_lg_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Url = {
  __typename?: "MediasFields_sizes_lg_url";
  create: Maybe<MediasFields_Sizes_Lg_Url_Create>;
  delete: Maybe<MediasFields_Sizes_Lg_Url_Delete>;
  read: Maybe<MediasFields_Sizes_Lg_Url_Read>;
  update: Maybe<MediasFields_Sizes_Lg_Url_Update>;
};

export type MediasFields_Sizes_Lg_Url_Create = {
  __typename?: "MediasFields_sizes_lg_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Url_Delete = {
  __typename?: "MediasFields_sizes_lg_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Url_Read = {
  __typename?: "MediasFields_sizes_lg_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Url_Update = {
  __typename?: "MediasFields_sizes_lg_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Width = {
  __typename?: "MediasFields_sizes_lg_width";
  create: Maybe<MediasFields_Sizes_Lg_Width_Create>;
  delete: Maybe<MediasFields_Sizes_Lg_Width_Delete>;
  read: Maybe<MediasFields_Sizes_Lg_Width_Read>;
  update: Maybe<MediasFields_Sizes_Lg_Width_Update>;
};

export type MediasFields_Sizes_Lg_Width_Create = {
  __typename?: "MediasFields_sizes_lg_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Width_Delete = {
  __typename?: "MediasFields_sizes_lg_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Width_Read = {
  __typename?: "MediasFields_sizes_lg_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Lg_Width_Update = {
  __typename?: "MediasFields_sizes_lg_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md = {
  __typename?: "MediasFields_sizes_md";
  create: Maybe<MediasFields_Sizes_Md_Create>;
  delete: Maybe<MediasFields_Sizes_Md_Delete>;
  fields: Maybe<MediasFields_Sizes_Md_Fields>;
  read: Maybe<MediasFields_Sizes_Md_Read>;
  update: Maybe<MediasFields_Sizes_Md_Update>;
};

export type MediasFields_Sizes_Md_Create = {
  __typename?: "MediasFields_sizes_md_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Delete = {
  __typename?: "MediasFields_sizes_md_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Fields = {
  __typename?: "MediasFields_sizes_md_Fields";
  filename: Maybe<MediasFields_Sizes_Md_Filename>;
  filesize: Maybe<MediasFields_Sizes_Md_Filesize>;
  height: Maybe<MediasFields_Sizes_Md_Height>;
  mimeType: Maybe<MediasFields_Sizes_Md_MimeType>;
  url: Maybe<MediasFields_Sizes_Md_Url>;
  width: Maybe<MediasFields_Sizes_Md_Width>;
};

export type MediasFields_Sizes_Md_Read = {
  __typename?: "MediasFields_sizes_md_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Update = {
  __typename?: "MediasFields_sizes_md_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Filename = {
  __typename?: "MediasFields_sizes_md_filename";
  create: Maybe<MediasFields_Sizes_Md_Filename_Create>;
  delete: Maybe<MediasFields_Sizes_Md_Filename_Delete>;
  read: Maybe<MediasFields_Sizes_Md_Filename_Read>;
  update: Maybe<MediasFields_Sizes_Md_Filename_Update>;
};

export type MediasFields_Sizes_Md_Filename_Create = {
  __typename?: "MediasFields_sizes_md_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Filename_Delete = {
  __typename?: "MediasFields_sizes_md_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Filename_Read = {
  __typename?: "MediasFields_sizes_md_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Filename_Update = {
  __typename?: "MediasFields_sizes_md_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Filesize = {
  __typename?: "MediasFields_sizes_md_filesize";
  create: Maybe<MediasFields_Sizes_Md_Filesize_Create>;
  delete: Maybe<MediasFields_Sizes_Md_Filesize_Delete>;
  read: Maybe<MediasFields_Sizes_Md_Filesize_Read>;
  update: Maybe<MediasFields_Sizes_Md_Filesize_Update>;
};

export type MediasFields_Sizes_Md_Filesize_Create = {
  __typename?: "MediasFields_sizes_md_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Filesize_Delete = {
  __typename?: "MediasFields_sizes_md_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Filesize_Read = {
  __typename?: "MediasFields_sizes_md_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Filesize_Update = {
  __typename?: "MediasFields_sizes_md_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Height = {
  __typename?: "MediasFields_sizes_md_height";
  create: Maybe<MediasFields_Sizes_Md_Height_Create>;
  delete: Maybe<MediasFields_Sizes_Md_Height_Delete>;
  read: Maybe<MediasFields_Sizes_Md_Height_Read>;
  update: Maybe<MediasFields_Sizes_Md_Height_Update>;
};

export type MediasFields_Sizes_Md_Height_Create = {
  __typename?: "MediasFields_sizes_md_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Height_Delete = {
  __typename?: "MediasFields_sizes_md_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Height_Read = {
  __typename?: "MediasFields_sizes_md_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Height_Update = {
  __typename?: "MediasFields_sizes_md_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_MimeType = {
  __typename?: "MediasFields_sizes_md_mimeType";
  create: Maybe<MediasFields_Sizes_Md_MimeType_Create>;
  delete: Maybe<MediasFields_Sizes_Md_MimeType_Delete>;
  read: Maybe<MediasFields_Sizes_Md_MimeType_Read>;
  update: Maybe<MediasFields_Sizes_Md_MimeType_Update>;
};

export type MediasFields_Sizes_Md_MimeType_Create = {
  __typename?: "MediasFields_sizes_md_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_MimeType_Delete = {
  __typename?: "MediasFields_sizes_md_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_MimeType_Read = {
  __typename?: "MediasFields_sizes_md_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_MimeType_Update = {
  __typename?: "MediasFields_sizes_md_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Url = {
  __typename?: "MediasFields_sizes_md_url";
  create: Maybe<MediasFields_Sizes_Md_Url_Create>;
  delete: Maybe<MediasFields_Sizes_Md_Url_Delete>;
  read: Maybe<MediasFields_Sizes_Md_Url_Read>;
  update: Maybe<MediasFields_Sizes_Md_Url_Update>;
};

export type MediasFields_Sizes_Md_Url_Create = {
  __typename?: "MediasFields_sizes_md_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Url_Delete = {
  __typename?: "MediasFields_sizes_md_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Url_Read = {
  __typename?: "MediasFields_sizes_md_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Url_Update = {
  __typename?: "MediasFields_sizes_md_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Width = {
  __typename?: "MediasFields_sizes_md_width";
  create: Maybe<MediasFields_Sizes_Md_Width_Create>;
  delete: Maybe<MediasFields_Sizes_Md_Width_Delete>;
  read: Maybe<MediasFields_Sizes_Md_Width_Read>;
  update: Maybe<MediasFields_Sizes_Md_Width_Update>;
};

export type MediasFields_Sizes_Md_Width_Create = {
  __typename?: "MediasFields_sizes_md_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Width_Delete = {
  __typename?: "MediasFields_sizes_md_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Width_Read = {
  __typename?: "MediasFields_sizes_md_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Md_Width_Update = {
  __typename?: "MediasFields_sizes_md_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k = {
  __typename?: "MediasFields_sizes_res_2k";
  create: Maybe<MediasFields_Sizes_Res_2k_Create>;
  delete: Maybe<MediasFields_Sizes_Res_2k_Delete>;
  fields: Maybe<MediasFields_Sizes_Res_2k_Fields>;
  read: Maybe<MediasFields_Sizes_Res_2k_Read>;
  update: Maybe<MediasFields_Sizes_Res_2k_Update>;
};

export type MediasFields_Sizes_Res_2k_Create = {
  __typename?: "MediasFields_sizes_res_2k_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Delete = {
  __typename?: "MediasFields_sizes_res_2k_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Fields = {
  __typename?: "MediasFields_sizes_res_2k_Fields";
  filename: Maybe<MediasFields_Sizes_Res_2k_Filename>;
  filesize: Maybe<MediasFields_Sizes_Res_2k_Filesize>;
  height: Maybe<MediasFields_Sizes_Res_2k_Height>;
  mimeType: Maybe<MediasFields_Sizes_Res_2k_MimeType>;
  url: Maybe<MediasFields_Sizes_Res_2k_Url>;
  width: Maybe<MediasFields_Sizes_Res_2k_Width>;
};

export type MediasFields_Sizes_Res_2k_Read = {
  __typename?: "MediasFields_sizes_res_2k_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Update = {
  __typename?: "MediasFields_sizes_res_2k_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Filename = {
  __typename?: "MediasFields_sizes_res_2k_filename";
  create: Maybe<MediasFields_Sizes_Res_2k_Filename_Create>;
  delete: Maybe<MediasFields_Sizes_Res_2k_Filename_Delete>;
  read: Maybe<MediasFields_Sizes_Res_2k_Filename_Read>;
  update: Maybe<MediasFields_Sizes_Res_2k_Filename_Update>;
};

export type MediasFields_Sizes_Res_2k_Filename_Create = {
  __typename?: "MediasFields_sizes_res_2k_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Filename_Delete = {
  __typename?: "MediasFields_sizes_res_2k_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Filename_Read = {
  __typename?: "MediasFields_sizes_res_2k_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Filename_Update = {
  __typename?: "MediasFields_sizes_res_2k_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Filesize = {
  __typename?: "MediasFields_sizes_res_2k_filesize";
  create: Maybe<MediasFields_Sizes_Res_2k_Filesize_Create>;
  delete: Maybe<MediasFields_Sizes_Res_2k_Filesize_Delete>;
  read: Maybe<MediasFields_Sizes_Res_2k_Filesize_Read>;
  update: Maybe<MediasFields_Sizes_Res_2k_Filesize_Update>;
};

export type MediasFields_Sizes_Res_2k_Filesize_Create = {
  __typename?: "MediasFields_sizes_res_2k_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Filesize_Delete = {
  __typename?: "MediasFields_sizes_res_2k_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Filesize_Read = {
  __typename?: "MediasFields_sizes_res_2k_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Filesize_Update = {
  __typename?: "MediasFields_sizes_res_2k_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Height = {
  __typename?: "MediasFields_sizes_res_2k_height";
  create: Maybe<MediasFields_Sizes_Res_2k_Height_Create>;
  delete: Maybe<MediasFields_Sizes_Res_2k_Height_Delete>;
  read: Maybe<MediasFields_Sizes_Res_2k_Height_Read>;
  update: Maybe<MediasFields_Sizes_Res_2k_Height_Update>;
};

export type MediasFields_Sizes_Res_2k_Height_Create = {
  __typename?: "MediasFields_sizes_res_2k_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Height_Delete = {
  __typename?: "MediasFields_sizes_res_2k_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Height_Read = {
  __typename?: "MediasFields_sizes_res_2k_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Height_Update = {
  __typename?: "MediasFields_sizes_res_2k_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_MimeType = {
  __typename?: "MediasFields_sizes_res_2k_mimeType";
  create: Maybe<MediasFields_Sizes_Res_2k_MimeType_Create>;
  delete: Maybe<MediasFields_Sizes_Res_2k_MimeType_Delete>;
  read: Maybe<MediasFields_Sizes_Res_2k_MimeType_Read>;
  update: Maybe<MediasFields_Sizes_Res_2k_MimeType_Update>;
};

export type MediasFields_Sizes_Res_2k_MimeType_Create = {
  __typename?: "MediasFields_sizes_res_2k_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_MimeType_Delete = {
  __typename?: "MediasFields_sizes_res_2k_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_MimeType_Read = {
  __typename?: "MediasFields_sizes_res_2k_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_MimeType_Update = {
  __typename?: "MediasFields_sizes_res_2k_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Url = {
  __typename?: "MediasFields_sizes_res_2k_url";
  create: Maybe<MediasFields_Sizes_Res_2k_Url_Create>;
  delete: Maybe<MediasFields_Sizes_Res_2k_Url_Delete>;
  read: Maybe<MediasFields_Sizes_Res_2k_Url_Read>;
  update: Maybe<MediasFields_Sizes_Res_2k_Url_Update>;
};

export type MediasFields_Sizes_Res_2k_Url_Create = {
  __typename?: "MediasFields_sizes_res_2k_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Url_Delete = {
  __typename?: "MediasFields_sizes_res_2k_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Url_Read = {
  __typename?: "MediasFields_sizes_res_2k_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Url_Update = {
  __typename?: "MediasFields_sizes_res_2k_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Width = {
  __typename?: "MediasFields_sizes_res_2k_width";
  create: Maybe<MediasFields_Sizes_Res_2k_Width_Create>;
  delete: Maybe<MediasFields_Sizes_Res_2k_Width_Delete>;
  read: Maybe<MediasFields_Sizes_Res_2k_Width_Read>;
  update: Maybe<MediasFields_Sizes_Res_2k_Width_Update>;
};

export type MediasFields_Sizes_Res_2k_Width_Create = {
  __typename?: "MediasFields_sizes_res_2k_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Width_Delete = {
  __typename?: "MediasFields_sizes_res_2k_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Width_Read = {
  __typename?: "MediasFields_sizes_res_2k_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_2k_Width_Update = {
  __typename?: "MediasFields_sizes_res_2k_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k = {
  __typename?: "MediasFields_sizes_res_4k";
  create: Maybe<MediasFields_Sizes_Res_4k_Create>;
  delete: Maybe<MediasFields_Sizes_Res_4k_Delete>;
  fields: Maybe<MediasFields_Sizes_Res_4k_Fields>;
  read: Maybe<MediasFields_Sizes_Res_4k_Read>;
  update: Maybe<MediasFields_Sizes_Res_4k_Update>;
};

export type MediasFields_Sizes_Res_4k_Create = {
  __typename?: "MediasFields_sizes_res_4k_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Delete = {
  __typename?: "MediasFields_sizes_res_4k_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Fields = {
  __typename?: "MediasFields_sizes_res_4k_Fields";
  filename: Maybe<MediasFields_Sizes_Res_4k_Filename>;
  filesize: Maybe<MediasFields_Sizes_Res_4k_Filesize>;
  height: Maybe<MediasFields_Sizes_Res_4k_Height>;
  mimeType: Maybe<MediasFields_Sizes_Res_4k_MimeType>;
  url: Maybe<MediasFields_Sizes_Res_4k_Url>;
  width: Maybe<MediasFields_Sizes_Res_4k_Width>;
};

export type MediasFields_Sizes_Res_4k_Read = {
  __typename?: "MediasFields_sizes_res_4k_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Update = {
  __typename?: "MediasFields_sizes_res_4k_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Filename = {
  __typename?: "MediasFields_sizes_res_4k_filename";
  create: Maybe<MediasFields_Sizes_Res_4k_Filename_Create>;
  delete: Maybe<MediasFields_Sizes_Res_4k_Filename_Delete>;
  read: Maybe<MediasFields_Sizes_Res_4k_Filename_Read>;
  update: Maybe<MediasFields_Sizes_Res_4k_Filename_Update>;
};

export type MediasFields_Sizes_Res_4k_Filename_Create = {
  __typename?: "MediasFields_sizes_res_4k_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Filename_Delete = {
  __typename?: "MediasFields_sizes_res_4k_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Filename_Read = {
  __typename?: "MediasFields_sizes_res_4k_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Filename_Update = {
  __typename?: "MediasFields_sizes_res_4k_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Filesize = {
  __typename?: "MediasFields_sizes_res_4k_filesize";
  create: Maybe<MediasFields_Sizes_Res_4k_Filesize_Create>;
  delete: Maybe<MediasFields_Sizes_Res_4k_Filesize_Delete>;
  read: Maybe<MediasFields_Sizes_Res_4k_Filesize_Read>;
  update: Maybe<MediasFields_Sizes_Res_4k_Filesize_Update>;
};

export type MediasFields_Sizes_Res_4k_Filesize_Create = {
  __typename?: "MediasFields_sizes_res_4k_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Filesize_Delete = {
  __typename?: "MediasFields_sizes_res_4k_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Filesize_Read = {
  __typename?: "MediasFields_sizes_res_4k_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Filesize_Update = {
  __typename?: "MediasFields_sizes_res_4k_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Height = {
  __typename?: "MediasFields_sizes_res_4k_height";
  create: Maybe<MediasFields_Sizes_Res_4k_Height_Create>;
  delete: Maybe<MediasFields_Sizes_Res_4k_Height_Delete>;
  read: Maybe<MediasFields_Sizes_Res_4k_Height_Read>;
  update: Maybe<MediasFields_Sizes_Res_4k_Height_Update>;
};

export type MediasFields_Sizes_Res_4k_Height_Create = {
  __typename?: "MediasFields_sizes_res_4k_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Height_Delete = {
  __typename?: "MediasFields_sizes_res_4k_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Height_Read = {
  __typename?: "MediasFields_sizes_res_4k_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Height_Update = {
  __typename?: "MediasFields_sizes_res_4k_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_MimeType = {
  __typename?: "MediasFields_sizes_res_4k_mimeType";
  create: Maybe<MediasFields_Sizes_Res_4k_MimeType_Create>;
  delete: Maybe<MediasFields_Sizes_Res_4k_MimeType_Delete>;
  read: Maybe<MediasFields_Sizes_Res_4k_MimeType_Read>;
  update: Maybe<MediasFields_Sizes_Res_4k_MimeType_Update>;
};

export type MediasFields_Sizes_Res_4k_MimeType_Create = {
  __typename?: "MediasFields_sizes_res_4k_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_MimeType_Delete = {
  __typename?: "MediasFields_sizes_res_4k_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_MimeType_Read = {
  __typename?: "MediasFields_sizes_res_4k_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_MimeType_Update = {
  __typename?: "MediasFields_sizes_res_4k_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Url = {
  __typename?: "MediasFields_sizes_res_4k_url";
  create: Maybe<MediasFields_Sizes_Res_4k_Url_Create>;
  delete: Maybe<MediasFields_Sizes_Res_4k_Url_Delete>;
  read: Maybe<MediasFields_Sizes_Res_4k_Url_Read>;
  update: Maybe<MediasFields_Sizes_Res_4k_Url_Update>;
};

export type MediasFields_Sizes_Res_4k_Url_Create = {
  __typename?: "MediasFields_sizes_res_4k_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Url_Delete = {
  __typename?: "MediasFields_sizes_res_4k_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Url_Read = {
  __typename?: "MediasFields_sizes_res_4k_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Url_Update = {
  __typename?: "MediasFields_sizes_res_4k_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Width = {
  __typename?: "MediasFields_sizes_res_4k_width";
  create: Maybe<MediasFields_Sizes_Res_4k_Width_Create>;
  delete: Maybe<MediasFields_Sizes_Res_4k_Width_Delete>;
  read: Maybe<MediasFields_Sizes_Res_4k_Width_Read>;
  update: Maybe<MediasFields_Sizes_Res_4k_Width_Update>;
};

export type MediasFields_Sizes_Res_4k_Width_Create = {
  __typename?: "MediasFields_sizes_res_4k_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Width_Delete = {
  __typename?: "MediasFields_sizes_res_4k_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Width_Read = {
  __typename?: "MediasFields_sizes_res_4k_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_4k_Width_Update = {
  __typename?: "MediasFields_sizes_res_4k_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd = {
  __typename?: "MediasFields_sizes_res_fullhd";
  create: Maybe<MediasFields_Sizes_Res_Fullhd_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Fullhd_Delete>;
  fields: Maybe<MediasFields_Sizes_Res_Fullhd_Fields>;
  read: Maybe<MediasFields_Sizes_Res_Fullhd_Read>;
  update: Maybe<MediasFields_Sizes_Res_Fullhd_Update>;
};

export type MediasFields_Sizes_Res_Fullhd_Create = {
  __typename?: "MediasFields_sizes_res_fullhd_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Delete = {
  __typename?: "MediasFields_sizes_res_fullhd_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Fields = {
  __typename?: "MediasFields_sizes_res_fullhd_Fields";
  filename: Maybe<MediasFields_Sizes_Res_Fullhd_Filename>;
  filesize: Maybe<MediasFields_Sizes_Res_Fullhd_Filesize>;
  height: Maybe<MediasFields_Sizes_Res_Fullhd_Height>;
  mimeType: Maybe<MediasFields_Sizes_Res_Fullhd_MimeType>;
  url: Maybe<MediasFields_Sizes_Res_Fullhd_Url>;
  width: Maybe<MediasFields_Sizes_Res_Fullhd_Width>;
};

export type MediasFields_Sizes_Res_Fullhd_Read = {
  __typename?: "MediasFields_sizes_res_fullhd_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Update = {
  __typename?: "MediasFields_sizes_res_fullhd_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Filename = {
  __typename?: "MediasFields_sizes_res_fullhd_filename";
  create: Maybe<MediasFields_Sizes_Res_Fullhd_Filename_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Fullhd_Filename_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Fullhd_Filename_Read>;
  update: Maybe<MediasFields_Sizes_Res_Fullhd_Filename_Update>;
};

export type MediasFields_Sizes_Res_Fullhd_Filename_Create = {
  __typename?: "MediasFields_sizes_res_fullhd_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Filename_Delete = {
  __typename?: "MediasFields_sizes_res_fullhd_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Filename_Read = {
  __typename?: "MediasFields_sizes_res_fullhd_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Filename_Update = {
  __typename?: "MediasFields_sizes_res_fullhd_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Filesize = {
  __typename?: "MediasFields_sizes_res_fullhd_filesize";
  create: Maybe<MediasFields_Sizes_Res_Fullhd_Filesize_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Fullhd_Filesize_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Fullhd_Filesize_Read>;
  update: Maybe<MediasFields_Sizes_Res_Fullhd_Filesize_Update>;
};

export type MediasFields_Sizes_Res_Fullhd_Filesize_Create = {
  __typename?: "MediasFields_sizes_res_fullhd_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Filesize_Delete = {
  __typename?: "MediasFields_sizes_res_fullhd_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Filesize_Read = {
  __typename?: "MediasFields_sizes_res_fullhd_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Filesize_Update = {
  __typename?: "MediasFields_sizes_res_fullhd_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Height = {
  __typename?: "MediasFields_sizes_res_fullhd_height";
  create: Maybe<MediasFields_Sizes_Res_Fullhd_Height_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Fullhd_Height_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Fullhd_Height_Read>;
  update: Maybe<MediasFields_Sizes_Res_Fullhd_Height_Update>;
};

export type MediasFields_Sizes_Res_Fullhd_Height_Create = {
  __typename?: "MediasFields_sizes_res_fullhd_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Height_Delete = {
  __typename?: "MediasFields_sizes_res_fullhd_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Height_Read = {
  __typename?: "MediasFields_sizes_res_fullhd_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Height_Update = {
  __typename?: "MediasFields_sizes_res_fullhd_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_MimeType = {
  __typename?: "MediasFields_sizes_res_fullhd_mimeType";
  create: Maybe<MediasFields_Sizes_Res_Fullhd_MimeType_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Fullhd_MimeType_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Fullhd_MimeType_Read>;
  update: Maybe<MediasFields_Sizes_Res_Fullhd_MimeType_Update>;
};

export type MediasFields_Sizes_Res_Fullhd_MimeType_Create = {
  __typename?: "MediasFields_sizes_res_fullhd_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_MimeType_Delete = {
  __typename?: "MediasFields_sizes_res_fullhd_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_MimeType_Read = {
  __typename?: "MediasFields_sizes_res_fullhd_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_MimeType_Update = {
  __typename?: "MediasFields_sizes_res_fullhd_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Url = {
  __typename?: "MediasFields_sizes_res_fullhd_url";
  create: Maybe<MediasFields_Sizes_Res_Fullhd_Url_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Fullhd_Url_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Fullhd_Url_Read>;
  update: Maybe<MediasFields_Sizes_Res_Fullhd_Url_Update>;
};

export type MediasFields_Sizes_Res_Fullhd_Url_Create = {
  __typename?: "MediasFields_sizes_res_fullhd_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Url_Delete = {
  __typename?: "MediasFields_sizes_res_fullhd_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Url_Read = {
  __typename?: "MediasFields_sizes_res_fullhd_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Url_Update = {
  __typename?: "MediasFields_sizes_res_fullhd_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Width = {
  __typename?: "MediasFields_sizes_res_fullhd_width";
  create: Maybe<MediasFields_Sizes_Res_Fullhd_Width_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Fullhd_Width_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Fullhd_Width_Read>;
  update: Maybe<MediasFields_Sizes_Res_Fullhd_Width_Update>;
};

export type MediasFields_Sizes_Res_Fullhd_Width_Create = {
  __typename?: "MediasFields_sizes_res_fullhd_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Width_Delete = {
  __typename?: "MediasFields_sizes_res_fullhd_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Width_Read = {
  __typename?: "MediasFields_sizes_res_fullhd_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Fullhd_Width_Update = {
  __typename?: "MediasFields_sizes_res_fullhd_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd = {
  __typename?: "MediasFields_sizes_res_hd";
  create: Maybe<MediasFields_Sizes_Res_Hd_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Hd_Delete>;
  fields: Maybe<MediasFields_Sizes_Res_Hd_Fields>;
  read: Maybe<MediasFields_Sizes_Res_Hd_Read>;
  update: Maybe<MediasFields_Sizes_Res_Hd_Update>;
};

export type MediasFields_Sizes_Res_Hd_Create = {
  __typename?: "MediasFields_sizes_res_hd_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Delete = {
  __typename?: "MediasFields_sizes_res_hd_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Fields = {
  __typename?: "MediasFields_sizes_res_hd_Fields";
  filename: Maybe<MediasFields_Sizes_Res_Hd_Filename>;
  filesize: Maybe<MediasFields_Sizes_Res_Hd_Filesize>;
  height: Maybe<MediasFields_Sizes_Res_Hd_Height>;
  mimeType: Maybe<MediasFields_Sizes_Res_Hd_MimeType>;
  url: Maybe<MediasFields_Sizes_Res_Hd_Url>;
  width: Maybe<MediasFields_Sizes_Res_Hd_Width>;
};

export type MediasFields_Sizes_Res_Hd_Read = {
  __typename?: "MediasFields_sizes_res_hd_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Update = {
  __typename?: "MediasFields_sizes_res_hd_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Filename = {
  __typename?: "MediasFields_sizes_res_hd_filename";
  create: Maybe<MediasFields_Sizes_Res_Hd_Filename_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Hd_Filename_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Hd_Filename_Read>;
  update: Maybe<MediasFields_Sizes_Res_Hd_Filename_Update>;
};

export type MediasFields_Sizes_Res_Hd_Filename_Create = {
  __typename?: "MediasFields_sizes_res_hd_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Filename_Delete = {
  __typename?: "MediasFields_sizes_res_hd_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Filename_Read = {
  __typename?: "MediasFields_sizes_res_hd_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Filename_Update = {
  __typename?: "MediasFields_sizes_res_hd_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Filesize = {
  __typename?: "MediasFields_sizes_res_hd_filesize";
  create: Maybe<MediasFields_Sizes_Res_Hd_Filesize_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Hd_Filesize_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Hd_Filesize_Read>;
  update: Maybe<MediasFields_Sizes_Res_Hd_Filesize_Update>;
};

export type MediasFields_Sizes_Res_Hd_Filesize_Create = {
  __typename?: "MediasFields_sizes_res_hd_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Filesize_Delete = {
  __typename?: "MediasFields_sizes_res_hd_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Filesize_Read = {
  __typename?: "MediasFields_sizes_res_hd_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Filesize_Update = {
  __typename?: "MediasFields_sizes_res_hd_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Height = {
  __typename?: "MediasFields_sizes_res_hd_height";
  create: Maybe<MediasFields_Sizes_Res_Hd_Height_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Hd_Height_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Hd_Height_Read>;
  update: Maybe<MediasFields_Sizes_Res_Hd_Height_Update>;
};

export type MediasFields_Sizes_Res_Hd_Height_Create = {
  __typename?: "MediasFields_sizes_res_hd_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Height_Delete = {
  __typename?: "MediasFields_sizes_res_hd_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Height_Read = {
  __typename?: "MediasFields_sizes_res_hd_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Height_Update = {
  __typename?: "MediasFields_sizes_res_hd_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_MimeType = {
  __typename?: "MediasFields_sizes_res_hd_mimeType";
  create: Maybe<MediasFields_Sizes_Res_Hd_MimeType_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Hd_MimeType_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Hd_MimeType_Read>;
  update: Maybe<MediasFields_Sizes_Res_Hd_MimeType_Update>;
};

export type MediasFields_Sizes_Res_Hd_MimeType_Create = {
  __typename?: "MediasFields_sizes_res_hd_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_MimeType_Delete = {
  __typename?: "MediasFields_sizes_res_hd_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_MimeType_Read = {
  __typename?: "MediasFields_sizes_res_hd_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_MimeType_Update = {
  __typename?: "MediasFields_sizes_res_hd_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Url = {
  __typename?: "MediasFields_sizes_res_hd_url";
  create: Maybe<MediasFields_Sizes_Res_Hd_Url_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Hd_Url_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Hd_Url_Read>;
  update: Maybe<MediasFields_Sizes_Res_Hd_Url_Update>;
};

export type MediasFields_Sizes_Res_Hd_Url_Create = {
  __typename?: "MediasFields_sizes_res_hd_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Url_Delete = {
  __typename?: "MediasFields_sizes_res_hd_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Url_Read = {
  __typename?: "MediasFields_sizes_res_hd_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Url_Update = {
  __typename?: "MediasFields_sizes_res_hd_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Width = {
  __typename?: "MediasFields_sizes_res_hd_width";
  create: Maybe<MediasFields_Sizes_Res_Hd_Width_Create>;
  delete: Maybe<MediasFields_Sizes_Res_Hd_Width_Delete>;
  read: Maybe<MediasFields_Sizes_Res_Hd_Width_Read>;
  update: Maybe<MediasFields_Sizes_Res_Hd_Width_Update>;
};

export type MediasFields_Sizes_Res_Hd_Width_Create = {
  __typename?: "MediasFields_sizes_res_hd_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Width_Delete = {
  __typename?: "MediasFields_sizes_res_hd_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Width_Read = {
  __typename?: "MediasFields_sizes_res_hd_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Res_Hd_Width_Update = {
  __typename?: "MediasFields_sizes_res_hd_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs = {
  __typename?: "MediasFields_sizes_xs";
  create: Maybe<MediasFields_Sizes_Xs_Create>;
  delete: Maybe<MediasFields_Sizes_Xs_Delete>;
  fields: Maybe<MediasFields_Sizes_Xs_Fields>;
  read: Maybe<MediasFields_Sizes_Xs_Read>;
  update: Maybe<MediasFields_Sizes_Xs_Update>;
};

export type MediasFields_Sizes_Xs_Create = {
  __typename?: "MediasFields_sizes_xs_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Delete = {
  __typename?: "MediasFields_sizes_xs_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Fields = {
  __typename?: "MediasFields_sizes_xs_Fields";
  filename: Maybe<MediasFields_Sizes_Xs_Filename>;
  filesize: Maybe<MediasFields_Sizes_Xs_Filesize>;
  height: Maybe<MediasFields_Sizes_Xs_Height>;
  mimeType: Maybe<MediasFields_Sizes_Xs_MimeType>;
  url: Maybe<MediasFields_Sizes_Xs_Url>;
  width: Maybe<MediasFields_Sizes_Xs_Width>;
};

export type MediasFields_Sizes_Xs_Read = {
  __typename?: "MediasFields_sizes_xs_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Update = {
  __typename?: "MediasFields_sizes_xs_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Filename = {
  __typename?: "MediasFields_sizes_xs_filename";
  create: Maybe<MediasFields_Sizes_Xs_Filename_Create>;
  delete: Maybe<MediasFields_Sizes_Xs_Filename_Delete>;
  read: Maybe<MediasFields_Sizes_Xs_Filename_Read>;
  update: Maybe<MediasFields_Sizes_Xs_Filename_Update>;
};

export type MediasFields_Sizes_Xs_Filename_Create = {
  __typename?: "MediasFields_sizes_xs_filename_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Filename_Delete = {
  __typename?: "MediasFields_sizes_xs_filename_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Filename_Read = {
  __typename?: "MediasFields_sizes_xs_filename_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Filename_Update = {
  __typename?: "MediasFields_sizes_xs_filename_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Filesize = {
  __typename?: "MediasFields_sizes_xs_filesize";
  create: Maybe<MediasFields_Sizes_Xs_Filesize_Create>;
  delete: Maybe<MediasFields_Sizes_Xs_Filesize_Delete>;
  read: Maybe<MediasFields_Sizes_Xs_Filesize_Read>;
  update: Maybe<MediasFields_Sizes_Xs_Filesize_Update>;
};

export type MediasFields_Sizes_Xs_Filesize_Create = {
  __typename?: "MediasFields_sizes_xs_filesize_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Filesize_Delete = {
  __typename?: "MediasFields_sizes_xs_filesize_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Filesize_Read = {
  __typename?: "MediasFields_sizes_xs_filesize_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Filesize_Update = {
  __typename?: "MediasFields_sizes_xs_filesize_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Height = {
  __typename?: "MediasFields_sizes_xs_height";
  create: Maybe<MediasFields_Sizes_Xs_Height_Create>;
  delete: Maybe<MediasFields_Sizes_Xs_Height_Delete>;
  read: Maybe<MediasFields_Sizes_Xs_Height_Read>;
  update: Maybe<MediasFields_Sizes_Xs_Height_Update>;
};

export type MediasFields_Sizes_Xs_Height_Create = {
  __typename?: "MediasFields_sizes_xs_height_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Height_Delete = {
  __typename?: "MediasFields_sizes_xs_height_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Height_Read = {
  __typename?: "MediasFields_sizes_xs_height_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Height_Update = {
  __typename?: "MediasFields_sizes_xs_height_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_MimeType = {
  __typename?: "MediasFields_sizes_xs_mimeType";
  create: Maybe<MediasFields_Sizes_Xs_MimeType_Create>;
  delete: Maybe<MediasFields_Sizes_Xs_MimeType_Delete>;
  read: Maybe<MediasFields_Sizes_Xs_MimeType_Read>;
  update: Maybe<MediasFields_Sizes_Xs_MimeType_Update>;
};

export type MediasFields_Sizes_Xs_MimeType_Create = {
  __typename?: "MediasFields_sizes_xs_mimeType_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_MimeType_Delete = {
  __typename?: "MediasFields_sizes_xs_mimeType_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_MimeType_Read = {
  __typename?: "MediasFields_sizes_xs_mimeType_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_MimeType_Update = {
  __typename?: "MediasFields_sizes_xs_mimeType_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Url = {
  __typename?: "MediasFields_sizes_xs_url";
  create: Maybe<MediasFields_Sizes_Xs_Url_Create>;
  delete: Maybe<MediasFields_Sizes_Xs_Url_Delete>;
  read: Maybe<MediasFields_Sizes_Xs_Url_Read>;
  update: Maybe<MediasFields_Sizes_Xs_Url_Update>;
};

export type MediasFields_Sizes_Xs_Url_Create = {
  __typename?: "MediasFields_sizes_xs_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Url_Delete = {
  __typename?: "MediasFields_sizes_xs_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Url_Read = {
  __typename?: "MediasFields_sizes_xs_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Url_Update = {
  __typename?: "MediasFields_sizes_xs_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Width = {
  __typename?: "MediasFields_sizes_xs_width";
  create: Maybe<MediasFields_Sizes_Xs_Width_Create>;
  delete: Maybe<MediasFields_Sizes_Xs_Width_Delete>;
  read: Maybe<MediasFields_Sizes_Xs_Width_Read>;
  update: Maybe<MediasFields_Sizes_Xs_Width_Update>;
};

export type MediasFields_Sizes_Xs_Width_Create = {
  __typename?: "MediasFields_sizes_xs_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Width_Delete = {
  __typename?: "MediasFields_sizes_xs_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Width_Read = {
  __typename?: "MediasFields_sizes_xs_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Sizes_Xs_Width_Update = {
  __typename?: "MediasFields_sizes_xs_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_UpdatedAt = {
  __typename?: "MediasFields_updatedAt";
  create: Maybe<MediasFields_UpdatedAt_Create>;
  delete: Maybe<MediasFields_UpdatedAt_Delete>;
  read: Maybe<MediasFields_UpdatedAt_Read>;
  update: Maybe<MediasFields_UpdatedAt_Update>;
};

export type MediasFields_UpdatedAt_Create = {
  __typename?: "MediasFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_UpdatedAt_Delete = {
  __typename?: "MediasFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_UpdatedAt_Read = {
  __typename?: "MediasFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_UpdatedAt_Update = {
  __typename?: "MediasFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Url = {
  __typename?: "MediasFields_url";
  create: Maybe<MediasFields_Url_Create>;
  delete: Maybe<MediasFields_Url_Delete>;
  read: Maybe<MediasFields_Url_Read>;
  update: Maybe<MediasFields_Url_Update>;
};

export type MediasFields_Url_Create = {
  __typename?: "MediasFields_url_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Url_Delete = {
  __typename?: "MediasFields_url_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Url_Read = {
  __typename?: "MediasFields_url_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Url_Update = {
  __typename?: "MediasFields_url_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Width = {
  __typename?: "MediasFields_width";
  create: Maybe<MediasFields_Width_Create>;
  delete: Maybe<MediasFields_Width_Delete>;
  read: Maybe<MediasFields_Width_Read>;
  update: Maybe<MediasFields_Width_Update>;
};

export type MediasFields_Width_Create = {
  __typename?: "MediasFields_width_Create";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Width_Delete = {
  __typename?: "MediasFields_width_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Width_Read = {
  __typename?: "MediasFields_width_Read";
  permission: Scalars["Boolean"]["output"];
};

export type MediasFields_Width_Update = {
  __typename?: "MediasFields_width_Update";
  permission: Scalars["Boolean"]["output"];
};

export type MediasReadAccess = {
  __typename?: "MediasReadAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type MediasReadDocAccess = {
  __typename?: "MediasReadDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type MediasUpdateAccess = {
  __typename?: "MediasUpdateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type MediasUpdateDocAccess = {
  __typename?: "MediasUpdateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  createMedia: Maybe<Media>;
  createPage: Maybe<Page>;
  createPayloadPreference: Maybe<PayloadPreference>;
  createUser: Maybe<User>;
  deleteMedia: Maybe<Media>;
  deletePage: Maybe<Page>;
  deletePayloadPreference: Maybe<PayloadPreference>;
  deleteUser: Maybe<User>;
  forgotPasswordUser: Scalars["Boolean"]["output"];
  loginUser: Maybe<UsersLoginResult>;
  logoutUser: Maybe<Scalars["String"]["output"]>;
  refreshTokenUser: Maybe<UsersRefreshedUser>;
  resetPasswordUser: Maybe<UsersResetPassword>;
  restoreVersionPage: Maybe<Page>;
  unlockUser: Scalars["Boolean"]["output"];
  updateFooter: Maybe<Footer>;
  updateMedia: Maybe<Media>;
  updateNavigation: Maybe<Navigation>;
  updatePage: Maybe<Page>;
  updatePayloadPreference: Maybe<PayloadPreference>;
  updateUser: Maybe<User>;
  verifyEmailUser: Maybe<Scalars["Boolean"]["output"]>;
};

export type MutationCreateMediaArgs = {
  data: MutationMediaInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreatePageArgs = {
  data: MutationPageInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreatePayloadPreferenceArgs = {
  data: MutationPayloadPreferenceInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCreateUserArgs = {
  data: MutationUserInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationDeleteMediaArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeletePageArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeletePayloadPreferenceArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["Int"]["input"];
};

export type MutationForgotPasswordUserArgs = {
  disableEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
  email: Scalars["String"]["input"];
  expiration?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationLoginUserArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRefreshTokenUserArgs = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationResetPasswordUserArgs = {
  password?: InputMaybe<Scalars["String"]["input"]>;
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRestoreVersionPageArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationUnlockUserArgs = {
  email: Scalars["String"]["input"];
};

export type MutationUpdateFooterArgs = {
  data: MutationFooterInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateMediaArgs = {
  autosave?: InputMaybe<Scalars["Boolean"]["input"]>;
  data: MutationMediaUpdateInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type MutationUpdateNavigationArgs = {
  data: MutationNavigationInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdatePageArgs = {
  autosave?: InputMaybe<Scalars["Boolean"]["input"]>;
  data: MutationPageUpdateInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type MutationUpdatePayloadPreferenceArgs = {
  autosave?: InputMaybe<Scalars["Boolean"]["input"]>;
  data: MutationPayloadPreferenceUpdateInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type MutationUpdateUserArgs = {
  autosave?: InputMaybe<Scalars["Boolean"]["input"]>;
  data: MutationUserUpdateInput;
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type MutationVerifyEmailUserArgs = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type Navigation = {
  __typename?: "Navigation";
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  pages: Array<Navigation_Pages>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type NavigationDocAccessFields = {
  __typename?: "NavigationDocAccessFields";
  createdAt: Maybe<NavigationDocAccessFields_CreatedAt>;
  pages: Maybe<NavigationDocAccessFields_Pages>;
  updatedAt: Maybe<NavigationDocAccessFields_UpdatedAt>;
};

export type NavigationDocAccessFields_CreatedAt = {
  __typename?: "NavigationDocAccessFields_createdAt";
  create: Maybe<NavigationDocAccessFields_CreatedAt_Create>;
  delete: Maybe<NavigationDocAccessFields_CreatedAt_Delete>;
  read: Maybe<NavigationDocAccessFields_CreatedAt_Read>;
  update: Maybe<NavigationDocAccessFields_CreatedAt_Update>;
};

export type NavigationDocAccessFields_CreatedAt_Create = {
  __typename?: "NavigationDocAccessFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_CreatedAt_Delete = {
  __typename?: "NavigationDocAccessFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_CreatedAt_Read = {
  __typename?: "NavigationDocAccessFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_CreatedAt_Update = {
  __typename?: "NavigationDocAccessFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages = {
  __typename?: "NavigationDocAccessFields_pages";
  create: Maybe<NavigationDocAccessFields_Pages_Create>;
  delete: Maybe<NavigationDocAccessFields_Pages_Delete>;
  fields: Maybe<NavigationDocAccessFields_Pages_Fields>;
  read: Maybe<NavigationDocAccessFields_Pages_Read>;
  update: Maybe<NavigationDocAccessFields_Pages_Update>;
};

export type NavigationDocAccessFields_Pages_Create = {
  __typename?: "NavigationDocAccessFields_pages_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Delete = {
  __typename?: "NavigationDocAccessFields_pages_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Fields = {
  __typename?: "NavigationDocAccessFields_pages_Fields";
  id: Maybe<NavigationDocAccessFields_Pages_Id>;
  page: Maybe<NavigationDocAccessFields_Pages_Page>;
};

export type NavigationDocAccessFields_Pages_Read = {
  __typename?: "NavigationDocAccessFields_pages_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Update = {
  __typename?: "NavigationDocAccessFields_pages_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Id = {
  __typename?: "NavigationDocAccessFields_pages_id";
  create: Maybe<NavigationDocAccessFields_Pages_Id_Create>;
  delete: Maybe<NavigationDocAccessFields_Pages_Id_Delete>;
  read: Maybe<NavigationDocAccessFields_Pages_Id_Read>;
  update: Maybe<NavigationDocAccessFields_Pages_Id_Update>;
};

export type NavigationDocAccessFields_Pages_Id_Create = {
  __typename?: "NavigationDocAccessFields_pages_id_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Id_Delete = {
  __typename?: "NavigationDocAccessFields_pages_id_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Id_Read = {
  __typename?: "NavigationDocAccessFields_pages_id_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Id_Update = {
  __typename?: "NavigationDocAccessFields_pages_id_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Page = {
  __typename?: "NavigationDocAccessFields_pages_page";
  create: Maybe<NavigationDocAccessFields_Pages_Page_Create>;
  delete: Maybe<NavigationDocAccessFields_Pages_Page_Delete>;
  read: Maybe<NavigationDocAccessFields_Pages_Page_Read>;
  update: Maybe<NavigationDocAccessFields_Pages_Page_Update>;
};

export type NavigationDocAccessFields_Pages_Page_Create = {
  __typename?: "NavigationDocAccessFields_pages_page_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Page_Delete = {
  __typename?: "NavigationDocAccessFields_pages_page_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Page_Read = {
  __typename?: "NavigationDocAccessFields_pages_page_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_Pages_Page_Update = {
  __typename?: "NavigationDocAccessFields_pages_page_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_UpdatedAt = {
  __typename?: "NavigationDocAccessFields_updatedAt";
  create: Maybe<NavigationDocAccessFields_UpdatedAt_Create>;
  delete: Maybe<NavigationDocAccessFields_UpdatedAt_Delete>;
  read: Maybe<NavigationDocAccessFields_UpdatedAt_Read>;
  update: Maybe<NavigationDocAccessFields_UpdatedAt_Update>;
};

export type NavigationDocAccessFields_UpdatedAt_Create = {
  __typename?: "NavigationDocAccessFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_UpdatedAt_Delete = {
  __typename?: "NavigationDocAccessFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_UpdatedAt_Read = {
  __typename?: "NavigationDocAccessFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationDocAccessFields_UpdatedAt_Update = {
  __typename?: "NavigationDocAccessFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields = {
  __typename?: "NavigationFields";
  createdAt: Maybe<NavigationFields_CreatedAt>;
  pages: Maybe<NavigationFields_Pages>;
  updatedAt: Maybe<NavigationFields_UpdatedAt>;
};

export type NavigationFields_CreatedAt = {
  __typename?: "NavigationFields_createdAt";
  create: Maybe<NavigationFields_CreatedAt_Create>;
  delete: Maybe<NavigationFields_CreatedAt_Delete>;
  read: Maybe<NavigationFields_CreatedAt_Read>;
  update: Maybe<NavigationFields_CreatedAt_Update>;
};

export type NavigationFields_CreatedAt_Create = {
  __typename?: "NavigationFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_CreatedAt_Delete = {
  __typename?: "NavigationFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_CreatedAt_Read = {
  __typename?: "NavigationFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_CreatedAt_Update = {
  __typename?: "NavigationFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages = {
  __typename?: "NavigationFields_pages";
  create: Maybe<NavigationFields_Pages_Create>;
  delete: Maybe<NavigationFields_Pages_Delete>;
  fields: Maybe<NavigationFields_Pages_Fields>;
  read: Maybe<NavigationFields_Pages_Read>;
  update: Maybe<NavigationFields_Pages_Update>;
};

export type NavigationFields_Pages_Create = {
  __typename?: "NavigationFields_pages_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Delete = {
  __typename?: "NavigationFields_pages_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Fields = {
  __typename?: "NavigationFields_pages_Fields";
  id: Maybe<NavigationFields_Pages_Id>;
  page: Maybe<NavigationFields_Pages_Page>;
};

export type NavigationFields_Pages_Read = {
  __typename?: "NavigationFields_pages_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Update = {
  __typename?: "NavigationFields_pages_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Id = {
  __typename?: "NavigationFields_pages_id";
  create: Maybe<NavigationFields_Pages_Id_Create>;
  delete: Maybe<NavigationFields_Pages_Id_Delete>;
  read: Maybe<NavigationFields_Pages_Id_Read>;
  update: Maybe<NavigationFields_Pages_Id_Update>;
};

export type NavigationFields_Pages_Id_Create = {
  __typename?: "NavigationFields_pages_id_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Id_Delete = {
  __typename?: "NavigationFields_pages_id_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Id_Read = {
  __typename?: "NavigationFields_pages_id_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Id_Update = {
  __typename?: "NavigationFields_pages_id_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Page = {
  __typename?: "NavigationFields_pages_page";
  create: Maybe<NavigationFields_Pages_Page_Create>;
  delete: Maybe<NavigationFields_Pages_Page_Delete>;
  read: Maybe<NavigationFields_Pages_Page_Read>;
  update: Maybe<NavigationFields_Pages_Page_Update>;
};

export type NavigationFields_Pages_Page_Create = {
  __typename?: "NavigationFields_pages_page_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Page_Delete = {
  __typename?: "NavigationFields_pages_page_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Page_Read = {
  __typename?: "NavigationFields_pages_page_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_Pages_Page_Update = {
  __typename?: "NavigationFields_pages_page_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_UpdatedAt = {
  __typename?: "NavigationFields_updatedAt";
  create: Maybe<NavigationFields_UpdatedAt_Create>;
  delete: Maybe<NavigationFields_UpdatedAt_Delete>;
  read: Maybe<NavigationFields_UpdatedAt_Read>;
  update: Maybe<NavigationFields_UpdatedAt_Update>;
};

export type NavigationFields_UpdatedAt_Create = {
  __typename?: "NavigationFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_UpdatedAt_Delete = {
  __typename?: "NavigationFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_UpdatedAt_Read = {
  __typename?: "NavigationFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationFields_UpdatedAt_Update = {
  __typename?: "NavigationFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type NavigationReadAccess = {
  __typename?: "NavigationReadAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type NavigationReadDocAccess = {
  __typename?: "NavigationReadDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type NavigationUpdateAccess = {
  __typename?: "NavigationUpdateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type NavigationUpdateDocAccess = {
  __typename?: "NavigationUpdateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type Navigation_Pages = {
  __typename?: "Navigation_Pages";
  id: Maybe<Scalars["String"]["output"]>;
  page: Maybe<Page>;
};

export type NumberedItemsModal = {
  __typename?: "NumberedItemsModal";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["JSON"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  items: Maybe<Array<NumberedItemsModal_Items>>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type NumberedItemsModalDescriptionArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NumberedItemsModal_Items = {
  __typename?: "NumberedItemsModal_Items";
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
};

export type NumberedItemsModal_ItemsTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Page = {
  __typename?: "Page";
  _status: Maybe<Page__Status | "%future added value">;
  content: Array<Page_Content>;
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  id: Maybe<Scalars["Int"]["output"]>;
  meta: Maybe<Page_Meta>;
  slug: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export enum PageUpdate__Status_MutationInput {
  Draft = "draft",
  Published = "published",
}

export type PageVersion = {
  __typename?: "PageVersion";
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  id: Maybe<Scalars["Int"]["output"]>;
  latest: Maybe<Scalars["Boolean"]["output"]>;
  parent: Maybe<Page>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
  version: Maybe<PageVersion_Version>;
};

export type PageVersion_Version = {
  __typename?: "PageVersion_Version";
  _status: Maybe<PageVersion_Version__Status | "%future added value">;
  content: Array<PageVersion_Version_Content>;
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  meta: Maybe<PageVersion_Version_Meta>;
  slug: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type PageVersion_Version_Content =
  | AnchorBlock
  | AngledTextBoxBlock
  | BubbleTextBlock
  | CaseLeadBoxBlock
  | CasesBoxBlock
  | DoubleSlotBoxBlock
  | IconCards
  | ImageHeaderBlock
  | LeadBoxBlock
  | LeifLinkBlock
  | MediaBlock
  | PurposeBoxBlock
  | PurposeGridBlock
  | QuotationBoxBlock
  | ReviewQuotationBoxBlock
  | TitleTextBoxBlock
  | VideoCarouselBlock
  | VideoJourneyBlock;

export type PageVersion_Version_Meta = {
  __typename?: "PageVersion_Version_Meta";
  description: Maybe<Scalars["String"]["output"]>;
  image: Maybe<Media>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type PageVersion_Version_MetaImageArgs = {
  where?: InputMaybe<PageVersion_Version_Meta_Image_Where>;
};

export type PageVersion_Version_Meta_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Lg__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Lg__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Lg__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Lg__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Lg__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Lg__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Md__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Md__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Md__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Md__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Md__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Md__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_2k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_2k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_2k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_2k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_2k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_2k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_4k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_4k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_4k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_4k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_4k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_4k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Hd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Hd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Hd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Hd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Hd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Res_Hd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Xs__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Xs__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Xs__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Sizes__Xs__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Xs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Sizes__Xs__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type PageVersion_Version_Meta_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PageVersion_Version_Meta_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PageVersion_Version_Meta_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<PageVersion_Version_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PageVersion_Version_Meta_Image_Where_Or>>>;
  createdAt?: InputMaybe<PageVersion_Version_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<PageVersion_Version_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<PageVersion_Version_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<PageVersion_Version_Meta_Image_Height_Operator>;
  id?: InputMaybe<PageVersion_Version_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<PageVersion_Version_Meta_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<PageVersion_Version_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<PageVersion_Version_Meta_Image_Url_Operator>;
  width?: InputMaybe<PageVersion_Version_Meta_Image_Width_Operator>;
};

export type PageVersion_Version_Meta_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<PageVersion_Version_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PageVersion_Version_Meta_Image_Where_Or>>>;
  createdAt?: InputMaybe<PageVersion_Version_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<PageVersion_Version_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<PageVersion_Version_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<PageVersion_Version_Meta_Image_Height_Operator>;
  id?: InputMaybe<PageVersion_Version_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<PageVersion_Version_Meta_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<PageVersion_Version_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<PageVersion_Version_Meta_Image_Url_Operator>;
  width?: InputMaybe<PageVersion_Version_Meta_Image_Width_Operator>;
};

export type PageVersion_Version_Meta_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<PageVersion_Version_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PageVersion_Version_Meta_Image_Where_Or>>>;
  createdAt?: InputMaybe<PageVersion_Version_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<PageVersion_Version_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<PageVersion_Version_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<PageVersion_Version_Meta_Image_Height_Operator>;
  id?: InputMaybe<PageVersion_Version_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<PageVersion_Version_Meta_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<PageVersion_Version_Meta_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<PageVersion_Version_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<PageVersion_Version_Meta_Image_Url_Operator>;
  width?: InputMaybe<PageVersion_Version_Meta_Image_Width_Operator>;
};

export type PageVersion_Version_Meta_Image_Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum PageVersion_Version__Status {
  Draft = "draft",
  Published = "published",
}

export type Page_Content =
  | AnchorBlock
  | AngledTextBoxBlock
  | BubbleTextBlock
  | CaseLeadBoxBlock
  | CasesBoxBlock
  | DoubleSlotBoxBlock
  | IconCards
  | ImageHeaderBlock
  | LeadBoxBlock
  | LeifLinkBlock
  | MediaBlock
  | PurposeBoxBlock
  | PurposeGridBlock
  | QuotationBoxBlock
  | ReviewQuotationBoxBlock
  | TitleTextBoxBlock
  | VideoCarouselBlock
  | VideoJourneyBlock;

export type Page_Meta = {
  __typename?: "Page_Meta";
  description: Maybe<Scalars["String"]["output"]>;
  image: Maybe<Media>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type Page_MetaImageArgs = {
  where?: InputMaybe<Page_Meta_Image_Where>;
};

export type Page_Meta_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Page_Meta_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Lg__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Lg__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Lg__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Lg__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Lg__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Lg__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Md__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Md__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Md__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Md__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Md__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Md__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_2k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_2k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_2k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_2k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_2k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_2k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_4k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_4k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_4k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_4k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_4k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_4k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_Fullhd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_Fullhd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_Fullhd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_Fullhd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_Fullhd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_Fullhd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_Hd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_Hd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_Hd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Res_Hd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_Hd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Res_Hd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Xs__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Xs__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Xs__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_Sizes__Xs__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Xs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Sizes__Xs__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type Page_Meta_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Page_Meta_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Meta_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<Page_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Page_Meta_Image_Where_Or>>>;
  createdAt?: InputMaybe<Page_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Page_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<Page_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<Page_Meta_Image_Height_Operator>;
  id?: InputMaybe<Page_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<Page_Meta_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<Page_Meta_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<Page_Meta_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<Page_Meta_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<Page_Meta_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<Page_Meta_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<Page_Meta_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<Page_Meta_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<Page_Meta_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<Page_Meta_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<Page_Meta_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<Page_Meta_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<Page_Meta_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<Page_Meta_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<Page_Meta_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<Page_Meta_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<Page_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Page_Meta_Image_Url_Operator>;
  width?: InputMaybe<Page_Meta_Image_Width_Operator>;
};

export type Page_Meta_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Page_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Page_Meta_Image_Where_Or>>>;
  createdAt?: InputMaybe<Page_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Page_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<Page_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<Page_Meta_Image_Height_Operator>;
  id?: InputMaybe<Page_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<Page_Meta_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<Page_Meta_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<Page_Meta_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<Page_Meta_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<Page_Meta_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<Page_Meta_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<Page_Meta_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<Page_Meta_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<Page_Meta_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<Page_Meta_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<Page_Meta_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<Page_Meta_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<Page_Meta_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<Page_Meta_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<Page_Meta_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<Page_Meta_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<Page_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Page_Meta_Image_Url_Operator>;
  width?: InputMaybe<Page_Meta_Image_Width_Operator>;
};

export type Page_Meta_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Page_Meta_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Page_Meta_Image_Where_Or>>>;
  createdAt?: InputMaybe<Page_Meta_Image_CreatedAt_Operator>;
  filename?: InputMaybe<Page_Meta_Image_Filename_Operator>;
  filesize?: InputMaybe<Page_Meta_Image_Filesize_Operator>;
  height?: InputMaybe<Page_Meta_Image_Height_Operator>;
  id?: InputMaybe<Page_Meta_Image_Id_Operator>;
  mimeType?: InputMaybe<Page_Meta_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<Page_Meta_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<Page_Meta_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<Page_Meta_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<Page_Meta_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<Page_Meta_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<Page_Meta_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<Page_Meta_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<Page_Meta_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<Page_Meta_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<Page_Meta_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<Page_Meta_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<Page_Meta_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<Page_Meta_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<Page_Meta_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<Page_Meta_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<Page_Meta_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<Page_Meta_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<Page_Meta_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<Page_Meta_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<Page_Meta_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<Page_Meta_Image_UpdatedAt_Operator>;
  url?: InputMaybe<Page_Meta_Image_Url_Operator>;
  width?: InputMaybe<Page_Meta_Image_Width_Operator>;
};

export type Page_Meta_Image_Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum Page__Status {
  Draft = "draft",
  Published = "published",
}

export enum Page__Status_Input {
  Draft = "draft",
  Published = "published",
}

export enum Page__Status_MutationInput {
  Draft = "draft",
  Published = "published",
}

export type Page__Status_Operator = {
  all?: InputMaybe<Array<InputMaybe<Page__Status_Input | "%future added value">>>;
  equals?: InputMaybe<Page__Status_Input | "%future added value">;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Page__Status_Input | "%future added value">>>;
  not_equals?: InputMaybe<Page__Status_Input | "%future added value">;
  not_in?: InputMaybe<Array<InputMaybe<Page__Status_Input | "%future added value">>>;
};

export type Page_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Page_Id_Operator = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Int"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  less_than?: InputMaybe<Scalars["Int"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  not_equals?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Page_Meta__Description_Operator = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
};

export type Page_Meta__Image_Operator = {
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
};

export type Page_Meta__Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Slug_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type Page_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type Page_Where = {
  AND?: InputMaybe<Array<InputMaybe<Page_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Page_Where_Or>>>;
  _status?: InputMaybe<Page__Status_Operator>;
  createdAt?: InputMaybe<Page_CreatedAt_Operator>;
  id?: InputMaybe<Page_Id_Operator>;
  meta__description?: InputMaybe<Page_Meta__Description_Operator>;
  meta__image?: InputMaybe<Page_Meta__Image_Operator>;
  meta__title?: InputMaybe<Page_Meta__Title_Operator>;
  slug?: InputMaybe<Page_Slug_Operator>;
  title?: InputMaybe<Page_Title_Operator>;
  updatedAt?: InputMaybe<Page_UpdatedAt_Operator>;
};

export type Page_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<Page_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Page_Where_Or>>>;
  _status?: InputMaybe<Page__Status_Operator>;
  createdAt?: InputMaybe<Page_CreatedAt_Operator>;
  id?: InputMaybe<Page_Id_Operator>;
  meta__description?: InputMaybe<Page_Meta__Description_Operator>;
  meta__image?: InputMaybe<Page_Meta__Image_Operator>;
  meta__title?: InputMaybe<Page_Meta__Title_Operator>;
  slug?: InputMaybe<Page_Slug_Operator>;
  title?: InputMaybe<Page_Title_Operator>;
  updatedAt?: InputMaybe<Page_UpdatedAt_Operator>;
};

export type Page_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<Page_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<Page_Where_Or>>>;
  _status?: InputMaybe<Page__Status_Operator>;
  createdAt?: InputMaybe<Page_CreatedAt_Operator>;
  id?: InputMaybe<Page_Id_Operator>;
  meta__description?: InputMaybe<Page_Meta__Description_Operator>;
  meta__image?: InputMaybe<Page_Meta__Image_Operator>;
  meta__title?: InputMaybe<Page_Meta__Title_Operator>;
  slug?: InputMaybe<Page_Slug_Operator>;
  title?: InputMaybe<Page_Title_Operator>;
  updatedAt?: InputMaybe<Page_UpdatedAt_Operator>;
};

export type Pages = {
  __typename?: "Pages";
  docs: Maybe<Array<Maybe<Page>>>;
  hasNextPage: Maybe<Scalars["Boolean"]["output"]>;
  hasPrevPage: Maybe<Scalars["Boolean"]["output"]>;
  limit: Maybe<Scalars["Int"]["output"]>;
  nextPage: Maybe<Scalars["Int"]["output"]>;
  offset: Maybe<Scalars["Int"]["output"]>;
  page: Maybe<Scalars["Int"]["output"]>;
  pagingCounter: Maybe<Scalars["Int"]["output"]>;
  prevPage: Maybe<Scalars["Int"]["output"]>;
  totalDocs: Maybe<Scalars["Int"]["output"]>;
  totalPages: Maybe<Scalars["Int"]["output"]>;
};

export type PagesCreateAccess = {
  __typename?: "PagesCreateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesCreateDocAccess = {
  __typename?: "PagesCreateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesDeleteAccess = {
  __typename?: "PagesDeleteAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesDeleteDocAccess = {
  __typename?: "PagesDeleteDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesDocAccessFields = {
  __typename?: "PagesDocAccessFields";
  _status: Maybe<PagesDocAccessFields__Status>;
  content: Maybe<PagesDocAccessFields_Content>;
  createdAt: Maybe<PagesDocAccessFields_CreatedAt>;
  meta: Maybe<PagesDocAccessFields_Meta>;
  slug: Maybe<PagesDocAccessFields_Slug>;
  title: Maybe<PagesDocAccessFields_Title>;
  updatedAt: Maybe<PagesDocAccessFields_UpdatedAt>;
};

export type PagesDocAccessFields__Status = {
  __typename?: "PagesDocAccessFields__status";
  create: Maybe<PagesDocAccessFields__Status_Create>;
  delete: Maybe<PagesDocAccessFields__Status_Delete>;
  read: Maybe<PagesDocAccessFields__Status_Read>;
  update: Maybe<PagesDocAccessFields__Status_Update>;
};

export type PagesDocAccessFields__Status_Create = {
  __typename?: "PagesDocAccessFields__status_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields__Status_Delete = {
  __typename?: "PagesDocAccessFields__status_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields__Status_Read = {
  __typename?: "PagesDocAccessFields__status_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields__Status_Update = {
  __typename?: "PagesDocAccessFields__status_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Content = {
  __typename?: "PagesDocAccessFields_content";
  create: Maybe<PagesDocAccessFields_Content_Create>;
  delete: Maybe<PagesDocAccessFields_Content_Delete>;
  read: Maybe<PagesDocAccessFields_Content_Read>;
  update: Maybe<PagesDocAccessFields_Content_Update>;
};

export type PagesDocAccessFields_Content_Create = {
  __typename?: "PagesDocAccessFields_content_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Content_Delete = {
  __typename?: "PagesDocAccessFields_content_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Content_Read = {
  __typename?: "PagesDocAccessFields_content_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Content_Update = {
  __typename?: "PagesDocAccessFields_content_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_CreatedAt = {
  __typename?: "PagesDocAccessFields_createdAt";
  create: Maybe<PagesDocAccessFields_CreatedAt_Create>;
  delete: Maybe<PagesDocAccessFields_CreatedAt_Delete>;
  read: Maybe<PagesDocAccessFields_CreatedAt_Read>;
  update: Maybe<PagesDocAccessFields_CreatedAt_Update>;
};

export type PagesDocAccessFields_CreatedAt_Create = {
  __typename?: "PagesDocAccessFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_CreatedAt_Delete = {
  __typename?: "PagesDocAccessFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_CreatedAt_Read = {
  __typename?: "PagesDocAccessFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_CreatedAt_Update = {
  __typename?: "PagesDocAccessFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta = {
  __typename?: "PagesDocAccessFields_meta";
  create: Maybe<PagesDocAccessFields_Meta_Create>;
  delete: Maybe<PagesDocAccessFields_Meta_Delete>;
  fields: Maybe<PagesDocAccessFields_Meta_Fields>;
  read: Maybe<PagesDocAccessFields_Meta_Read>;
  update: Maybe<PagesDocAccessFields_Meta_Update>;
};

export type PagesDocAccessFields_Meta_Create = {
  __typename?: "PagesDocAccessFields_meta_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Delete = {
  __typename?: "PagesDocAccessFields_meta_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Fields = {
  __typename?: "PagesDocAccessFields_meta_Fields";
  description: Maybe<PagesDocAccessFields_Meta_Description>;
  image: Maybe<PagesDocAccessFields_Meta_Image>;
  title: Maybe<PagesDocAccessFields_Meta_Title>;
};

export type PagesDocAccessFields_Meta_Read = {
  __typename?: "PagesDocAccessFields_meta_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Update = {
  __typename?: "PagesDocAccessFields_meta_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Description = {
  __typename?: "PagesDocAccessFields_meta_description";
  create: Maybe<PagesDocAccessFields_Meta_Description_Create>;
  delete: Maybe<PagesDocAccessFields_Meta_Description_Delete>;
  read: Maybe<PagesDocAccessFields_Meta_Description_Read>;
  update: Maybe<PagesDocAccessFields_Meta_Description_Update>;
};

export type PagesDocAccessFields_Meta_Description_Create = {
  __typename?: "PagesDocAccessFields_meta_description_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Description_Delete = {
  __typename?: "PagesDocAccessFields_meta_description_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Description_Read = {
  __typename?: "PagesDocAccessFields_meta_description_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Description_Update = {
  __typename?: "PagesDocAccessFields_meta_description_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Image = {
  __typename?: "PagesDocAccessFields_meta_image";
  create: Maybe<PagesDocAccessFields_Meta_Image_Create>;
  delete: Maybe<PagesDocAccessFields_Meta_Image_Delete>;
  read: Maybe<PagesDocAccessFields_Meta_Image_Read>;
  update: Maybe<PagesDocAccessFields_Meta_Image_Update>;
};

export type PagesDocAccessFields_Meta_Image_Create = {
  __typename?: "PagesDocAccessFields_meta_image_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Image_Delete = {
  __typename?: "PagesDocAccessFields_meta_image_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Image_Read = {
  __typename?: "PagesDocAccessFields_meta_image_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Image_Update = {
  __typename?: "PagesDocAccessFields_meta_image_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Title = {
  __typename?: "PagesDocAccessFields_meta_title";
  create: Maybe<PagesDocAccessFields_Meta_Title_Create>;
  delete: Maybe<PagesDocAccessFields_Meta_Title_Delete>;
  read: Maybe<PagesDocAccessFields_Meta_Title_Read>;
  update: Maybe<PagesDocAccessFields_Meta_Title_Update>;
};

export type PagesDocAccessFields_Meta_Title_Create = {
  __typename?: "PagesDocAccessFields_meta_title_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Title_Delete = {
  __typename?: "PagesDocAccessFields_meta_title_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Title_Read = {
  __typename?: "PagesDocAccessFields_meta_title_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Meta_Title_Update = {
  __typename?: "PagesDocAccessFields_meta_title_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Slug = {
  __typename?: "PagesDocAccessFields_slug";
  create: Maybe<PagesDocAccessFields_Slug_Create>;
  delete: Maybe<PagesDocAccessFields_Slug_Delete>;
  read: Maybe<PagesDocAccessFields_Slug_Read>;
  update: Maybe<PagesDocAccessFields_Slug_Update>;
};

export type PagesDocAccessFields_Slug_Create = {
  __typename?: "PagesDocAccessFields_slug_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Slug_Delete = {
  __typename?: "PagesDocAccessFields_slug_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Slug_Read = {
  __typename?: "PagesDocAccessFields_slug_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Slug_Update = {
  __typename?: "PagesDocAccessFields_slug_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Title = {
  __typename?: "PagesDocAccessFields_title";
  create: Maybe<PagesDocAccessFields_Title_Create>;
  delete: Maybe<PagesDocAccessFields_Title_Delete>;
  read: Maybe<PagesDocAccessFields_Title_Read>;
  update: Maybe<PagesDocAccessFields_Title_Update>;
};

export type PagesDocAccessFields_Title_Create = {
  __typename?: "PagesDocAccessFields_title_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Title_Delete = {
  __typename?: "PagesDocAccessFields_title_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Title_Read = {
  __typename?: "PagesDocAccessFields_title_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_Title_Update = {
  __typename?: "PagesDocAccessFields_title_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_UpdatedAt = {
  __typename?: "PagesDocAccessFields_updatedAt";
  create: Maybe<PagesDocAccessFields_UpdatedAt_Create>;
  delete: Maybe<PagesDocAccessFields_UpdatedAt_Delete>;
  read: Maybe<PagesDocAccessFields_UpdatedAt_Read>;
  update: Maybe<PagesDocAccessFields_UpdatedAt_Update>;
};

export type PagesDocAccessFields_UpdatedAt_Create = {
  __typename?: "PagesDocAccessFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_UpdatedAt_Delete = {
  __typename?: "PagesDocAccessFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_UpdatedAt_Read = {
  __typename?: "PagesDocAccessFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesDocAccessFields_UpdatedAt_Update = {
  __typename?: "PagesDocAccessFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields = {
  __typename?: "PagesFields";
  _status: Maybe<PagesFields__Status>;
  content: Maybe<PagesFields_Content>;
  createdAt: Maybe<PagesFields_CreatedAt>;
  meta: Maybe<PagesFields_Meta>;
  slug: Maybe<PagesFields_Slug>;
  title: Maybe<PagesFields_Title>;
  updatedAt: Maybe<PagesFields_UpdatedAt>;
};

export type PagesFields__Status = {
  __typename?: "PagesFields__status";
  create: Maybe<PagesFields__Status_Create>;
  delete: Maybe<PagesFields__Status_Delete>;
  read: Maybe<PagesFields__Status_Read>;
  update: Maybe<PagesFields__Status_Update>;
};

export type PagesFields__Status_Create = {
  __typename?: "PagesFields__status_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields__Status_Delete = {
  __typename?: "PagesFields__status_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields__Status_Read = {
  __typename?: "PagesFields__status_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields__Status_Update = {
  __typename?: "PagesFields__status_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Content = {
  __typename?: "PagesFields_content";
  create: Maybe<PagesFields_Content_Create>;
  delete: Maybe<PagesFields_Content_Delete>;
  read: Maybe<PagesFields_Content_Read>;
  update: Maybe<PagesFields_Content_Update>;
};

export type PagesFields_Content_Create = {
  __typename?: "PagesFields_content_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Content_Delete = {
  __typename?: "PagesFields_content_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Content_Read = {
  __typename?: "PagesFields_content_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Content_Update = {
  __typename?: "PagesFields_content_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_CreatedAt = {
  __typename?: "PagesFields_createdAt";
  create: Maybe<PagesFields_CreatedAt_Create>;
  delete: Maybe<PagesFields_CreatedAt_Delete>;
  read: Maybe<PagesFields_CreatedAt_Read>;
  update: Maybe<PagesFields_CreatedAt_Update>;
};

export type PagesFields_CreatedAt_Create = {
  __typename?: "PagesFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_CreatedAt_Delete = {
  __typename?: "PagesFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_CreatedAt_Read = {
  __typename?: "PagesFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_CreatedAt_Update = {
  __typename?: "PagesFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta = {
  __typename?: "PagesFields_meta";
  create: Maybe<PagesFields_Meta_Create>;
  delete: Maybe<PagesFields_Meta_Delete>;
  fields: Maybe<PagesFields_Meta_Fields>;
  read: Maybe<PagesFields_Meta_Read>;
  update: Maybe<PagesFields_Meta_Update>;
};

export type PagesFields_Meta_Create = {
  __typename?: "PagesFields_meta_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Delete = {
  __typename?: "PagesFields_meta_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Fields = {
  __typename?: "PagesFields_meta_Fields";
  description: Maybe<PagesFields_Meta_Description>;
  image: Maybe<PagesFields_Meta_Image>;
  title: Maybe<PagesFields_Meta_Title>;
};

export type PagesFields_Meta_Read = {
  __typename?: "PagesFields_meta_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Update = {
  __typename?: "PagesFields_meta_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Description = {
  __typename?: "PagesFields_meta_description";
  create: Maybe<PagesFields_Meta_Description_Create>;
  delete: Maybe<PagesFields_Meta_Description_Delete>;
  read: Maybe<PagesFields_Meta_Description_Read>;
  update: Maybe<PagesFields_Meta_Description_Update>;
};

export type PagesFields_Meta_Description_Create = {
  __typename?: "PagesFields_meta_description_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Description_Delete = {
  __typename?: "PagesFields_meta_description_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Description_Read = {
  __typename?: "PagesFields_meta_description_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Description_Update = {
  __typename?: "PagesFields_meta_description_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Image = {
  __typename?: "PagesFields_meta_image";
  create: Maybe<PagesFields_Meta_Image_Create>;
  delete: Maybe<PagesFields_Meta_Image_Delete>;
  read: Maybe<PagesFields_Meta_Image_Read>;
  update: Maybe<PagesFields_Meta_Image_Update>;
};

export type PagesFields_Meta_Image_Create = {
  __typename?: "PagesFields_meta_image_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Image_Delete = {
  __typename?: "PagesFields_meta_image_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Image_Read = {
  __typename?: "PagesFields_meta_image_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Image_Update = {
  __typename?: "PagesFields_meta_image_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Title = {
  __typename?: "PagesFields_meta_title";
  create: Maybe<PagesFields_Meta_Title_Create>;
  delete: Maybe<PagesFields_Meta_Title_Delete>;
  read: Maybe<PagesFields_Meta_Title_Read>;
  update: Maybe<PagesFields_Meta_Title_Update>;
};

export type PagesFields_Meta_Title_Create = {
  __typename?: "PagesFields_meta_title_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Title_Delete = {
  __typename?: "PagesFields_meta_title_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Title_Read = {
  __typename?: "PagesFields_meta_title_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Meta_Title_Update = {
  __typename?: "PagesFields_meta_title_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Slug = {
  __typename?: "PagesFields_slug";
  create: Maybe<PagesFields_Slug_Create>;
  delete: Maybe<PagesFields_Slug_Delete>;
  read: Maybe<PagesFields_Slug_Read>;
  update: Maybe<PagesFields_Slug_Update>;
};

export type PagesFields_Slug_Create = {
  __typename?: "PagesFields_slug_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Slug_Delete = {
  __typename?: "PagesFields_slug_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Slug_Read = {
  __typename?: "PagesFields_slug_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Slug_Update = {
  __typename?: "PagesFields_slug_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Title = {
  __typename?: "PagesFields_title";
  create: Maybe<PagesFields_Title_Create>;
  delete: Maybe<PagesFields_Title_Delete>;
  read: Maybe<PagesFields_Title_Read>;
  update: Maybe<PagesFields_Title_Update>;
};

export type PagesFields_Title_Create = {
  __typename?: "PagesFields_title_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Title_Delete = {
  __typename?: "PagesFields_title_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Title_Read = {
  __typename?: "PagesFields_title_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_Title_Update = {
  __typename?: "PagesFields_title_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_UpdatedAt = {
  __typename?: "PagesFields_updatedAt";
  create: Maybe<PagesFields_UpdatedAt_Create>;
  delete: Maybe<PagesFields_UpdatedAt_Delete>;
  read: Maybe<PagesFields_UpdatedAt_Read>;
  update: Maybe<PagesFields_UpdatedAt_Update>;
};

export type PagesFields_UpdatedAt_Create = {
  __typename?: "PagesFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_UpdatedAt_Delete = {
  __typename?: "PagesFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_UpdatedAt_Read = {
  __typename?: "PagesFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PagesFields_UpdatedAt_Update = {
  __typename?: "PagesFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PagesReadAccess = {
  __typename?: "PagesReadAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesReadDocAccess = {
  __typename?: "PagesReadDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesReadVersionsAccess = {
  __typename?: "PagesReadVersionsAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesReadVersionsDocAccess = {
  __typename?: "PagesReadVersionsDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesUpdateAccess = {
  __typename?: "PagesUpdateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PagesUpdateDocAccess = {
  __typename?: "PagesUpdateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PayloadPreference = {
  __typename?: "PayloadPreference";
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  id: Maybe<Scalars["Int"]["output"]>;
  key: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
  user: PayloadPreference_User_Relationship;
  value: Maybe<Scalars["JSON"]["output"]>;
};

export type PayloadPreferenceUpdate_UserRelationshipInput = {
  relationTo?: InputMaybe<PayloadPreferenceUpdate_UserRelationshipInputRelationTo | "%future added value">;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export enum PayloadPreferenceUpdate_UserRelationshipInputRelationTo {
  Users = "users",
}

export type PayloadPreference_User = User;

export type PayloadPreference_UserRelationshipInput = {
  relationTo?: InputMaybe<PayloadPreference_UserRelationshipInputRelationTo | "%future added value">;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export enum PayloadPreference_UserRelationshipInputRelationTo {
  Users = "users",
}

export enum PayloadPreference_User_RelationTo {
  Users = "users",
}

export type PayloadPreference_User_Relationship = {
  __typename?: "PayloadPreference_User_Relationship";
  relationTo: Maybe<PayloadPreference_User_RelationTo | "%future added value">;
  value: Maybe<PayloadPreference_User>;
};

export type PayloadPreference_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PayloadPreference_Id_Operator = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Int"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  less_than?: InputMaybe<Scalars["Int"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  not_equals?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PayloadPreference_Key_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type PayloadPreference_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PayloadPreference_User_Relation = {
  relationTo?: InputMaybe<PayloadPreference_User_Relation_RelationTo | "%future added value">;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export enum PayloadPreference_User_Relation_RelationTo {
  Users = "users",
}

export type PayloadPreference_Value_Operator = {
  contains?: InputMaybe<Scalars["JSON"]["input"]>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  intersects?: InputMaybe<Scalars["JSON"]["input"]>;
  like?: InputMaybe<Scalars["JSON"]["input"]>;
  not_equals?: InputMaybe<Scalars["JSON"]["input"]>;
  within?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type PayloadPreference_Where = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreference_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreference_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<PayloadPreference_Where_Or>>>;
  createdAt?: InputMaybe<PayloadPreference_CreatedAt_Operator>;
  id?: InputMaybe<PayloadPreference_Id_Operator>;
  key?: InputMaybe<PayloadPreference_Key_Operator>;
  updatedAt?: InputMaybe<PayloadPreference_UpdatedAt_Operator>;
  user?: InputMaybe<PayloadPreference_User_Relation>;
  value?: InputMaybe<PayloadPreference_Value_Operator>;
};

export type PayloadPreferences = {
  __typename?: "PayloadPreferences";
  docs: Maybe<Array<Maybe<PayloadPreference>>>;
  hasNextPage: Maybe<Scalars["Boolean"]["output"]>;
  hasPrevPage: Maybe<Scalars["Boolean"]["output"]>;
  limit: Maybe<Scalars["Int"]["output"]>;
  nextPage: Maybe<Scalars["Int"]["output"]>;
  offset: Maybe<Scalars["Int"]["output"]>;
  page: Maybe<Scalars["Int"]["output"]>;
  pagingCounter: Maybe<Scalars["Int"]["output"]>;
  prevPage: Maybe<Scalars["Int"]["output"]>;
  totalDocs: Maybe<Scalars["Int"]["output"]>;
  totalPages: Maybe<Scalars["Int"]["output"]>;
};

export type PayloadPreferencesCreateAccess = {
  __typename?: "PayloadPreferencesCreateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PayloadPreferencesCreateDocAccess = {
  __typename?: "PayloadPreferencesCreateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PayloadPreferencesDeleteAccess = {
  __typename?: "PayloadPreferencesDeleteAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PayloadPreferencesDeleteDocAccess = {
  __typename?: "PayloadPreferencesDeleteDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PayloadPreferencesDocAccessFields = {
  __typename?: "PayloadPreferencesDocAccessFields";
  createdAt: Maybe<PayloadPreferencesDocAccessFields_CreatedAt>;
  key: Maybe<PayloadPreferencesDocAccessFields_Key>;
  updatedAt: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt>;
  user: Maybe<PayloadPreferencesDocAccessFields_User>;
  value: Maybe<PayloadPreferencesDocAccessFields_Value>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt = {
  __typename?: "PayloadPreferencesDocAccessFields_createdAt";
  create: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Create>;
  delete: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Delete>;
  read: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Read>;
  update: Maybe<PayloadPreferencesDocAccessFields_CreatedAt_Update>;
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Create = {
  __typename?: "PayloadPreferencesDocAccessFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Delete = {
  __typename?: "PayloadPreferencesDocAccessFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Read = {
  __typename?: "PayloadPreferencesDocAccessFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_CreatedAt_Update = {
  __typename?: "PayloadPreferencesDocAccessFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_Key = {
  __typename?: "PayloadPreferencesDocAccessFields_key";
  create: Maybe<PayloadPreferencesDocAccessFields_Key_Create>;
  delete: Maybe<PayloadPreferencesDocAccessFields_Key_Delete>;
  read: Maybe<PayloadPreferencesDocAccessFields_Key_Read>;
  update: Maybe<PayloadPreferencesDocAccessFields_Key_Update>;
};

export type PayloadPreferencesDocAccessFields_Key_Create = {
  __typename?: "PayloadPreferencesDocAccessFields_key_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_Key_Delete = {
  __typename?: "PayloadPreferencesDocAccessFields_key_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_Key_Read = {
  __typename?: "PayloadPreferencesDocAccessFields_key_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_Key_Update = {
  __typename?: "PayloadPreferencesDocAccessFields_key_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt = {
  __typename?: "PayloadPreferencesDocAccessFields_updatedAt";
  create: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Create>;
  delete: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Delete>;
  read: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Read>;
  update: Maybe<PayloadPreferencesDocAccessFields_UpdatedAt_Update>;
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Create = {
  __typename?: "PayloadPreferencesDocAccessFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Delete = {
  __typename?: "PayloadPreferencesDocAccessFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Read = {
  __typename?: "PayloadPreferencesDocAccessFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_UpdatedAt_Update = {
  __typename?: "PayloadPreferencesDocAccessFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_User = {
  __typename?: "PayloadPreferencesDocAccessFields_user";
  create: Maybe<PayloadPreferencesDocAccessFields_User_Create>;
  delete: Maybe<PayloadPreferencesDocAccessFields_User_Delete>;
  read: Maybe<PayloadPreferencesDocAccessFields_User_Read>;
  update: Maybe<PayloadPreferencesDocAccessFields_User_Update>;
};

export type PayloadPreferencesDocAccessFields_User_Create = {
  __typename?: "PayloadPreferencesDocAccessFields_user_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_User_Delete = {
  __typename?: "PayloadPreferencesDocAccessFields_user_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_User_Read = {
  __typename?: "PayloadPreferencesDocAccessFields_user_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_User_Update = {
  __typename?: "PayloadPreferencesDocAccessFields_user_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_Value = {
  __typename?: "PayloadPreferencesDocAccessFields_value";
  create: Maybe<PayloadPreferencesDocAccessFields_Value_Create>;
  delete: Maybe<PayloadPreferencesDocAccessFields_Value_Delete>;
  read: Maybe<PayloadPreferencesDocAccessFields_Value_Read>;
  update: Maybe<PayloadPreferencesDocAccessFields_Value_Update>;
};

export type PayloadPreferencesDocAccessFields_Value_Create = {
  __typename?: "PayloadPreferencesDocAccessFields_value_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_Value_Delete = {
  __typename?: "PayloadPreferencesDocAccessFields_value_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_Value_Read = {
  __typename?: "PayloadPreferencesDocAccessFields_value_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesDocAccessFields_Value_Update = {
  __typename?: "PayloadPreferencesDocAccessFields_value_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields = {
  __typename?: "PayloadPreferencesFields";
  createdAt: Maybe<PayloadPreferencesFields_CreatedAt>;
  key: Maybe<PayloadPreferencesFields_Key>;
  updatedAt: Maybe<PayloadPreferencesFields_UpdatedAt>;
  user: Maybe<PayloadPreferencesFields_User>;
  value: Maybe<PayloadPreferencesFields_Value>;
};

export type PayloadPreferencesFields_CreatedAt = {
  __typename?: "PayloadPreferencesFields_createdAt";
  create: Maybe<PayloadPreferencesFields_CreatedAt_Create>;
  delete: Maybe<PayloadPreferencesFields_CreatedAt_Delete>;
  read: Maybe<PayloadPreferencesFields_CreatedAt_Read>;
  update: Maybe<PayloadPreferencesFields_CreatedAt_Update>;
};

export type PayloadPreferencesFields_CreatedAt_Create = {
  __typename?: "PayloadPreferencesFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_CreatedAt_Delete = {
  __typename?: "PayloadPreferencesFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_CreatedAt_Read = {
  __typename?: "PayloadPreferencesFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_CreatedAt_Update = {
  __typename?: "PayloadPreferencesFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_Key = {
  __typename?: "PayloadPreferencesFields_key";
  create: Maybe<PayloadPreferencesFields_Key_Create>;
  delete: Maybe<PayloadPreferencesFields_Key_Delete>;
  read: Maybe<PayloadPreferencesFields_Key_Read>;
  update: Maybe<PayloadPreferencesFields_Key_Update>;
};

export type PayloadPreferencesFields_Key_Create = {
  __typename?: "PayloadPreferencesFields_key_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_Key_Delete = {
  __typename?: "PayloadPreferencesFields_key_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_Key_Read = {
  __typename?: "PayloadPreferencesFields_key_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_Key_Update = {
  __typename?: "PayloadPreferencesFields_key_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_UpdatedAt = {
  __typename?: "PayloadPreferencesFields_updatedAt";
  create: Maybe<PayloadPreferencesFields_UpdatedAt_Create>;
  delete: Maybe<PayloadPreferencesFields_UpdatedAt_Delete>;
  read: Maybe<PayloadPreferencesFields_UpdatedAt_Read>;
  update: Maybe<PayloadPreferencesFields_UpdatedAt_Update>;
};

export type PayloadPreferencesFields_UpdatedAt_Create = {
  __typename?: "PayloadPreferencesFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_UpdatedAt_Delete = {
  __typename?: "PayloadPreferencesFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_UpdatedAt_Read = {
  __typename?: "PayloadPreferencesFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_UpdatedAt_Update = {
  __typename?: "PayloadPreferencesFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_User = {
  __typename?: "PayloadPreferencesFields_user";
  create: Maybe<PayloadPreferencesFields_User_Create>;
  delete: Maybe<PayloadPreferencesFields_User_Delete>;
  read: Maybe<PayloadPreferencesFields_User_Read>;
  update: Maybe<PayloadPreferencesFields_User_Update>;
};

export type PayloadPreferencesFields_User_Create = {
  __typename?: "PayloadPreferencesFields_user_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_User_Delete = {
  __typename?: "PayloadPreferencesFields_user_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_User_Read = {
  __typename?: "PayloadPreferencesFields_user_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_User_Update = {
  __typename?: "PayloadPreferencesFields_user_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_Value = {
  __typename?: "PayloadPreferencesFields_value";
  create: Maybe<PayloadPreferencesFields_Value_Create>;
  delete: Maybe<PayloadPreferencesFields_Value_Delete>;
  read: Maybe<PayloadPreferencesFields_Value_Read>;
  update: Maybe<PayloadPreferencesFields_Value_Update>;
};

export type PayloadPreferencesFields_Value_Create = {
  __typename?: "PayloadPreferencesFields_value_Create";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_Value_Delete = {
  __typename?: "PayloadPreferencesFields_value_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_Value_Read = {
  __typename?: "PayloadPreferencesFields_value_Read";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesFields_Value_Update = {
  __typename?: "PayloadPreferencesFields_value_Update";
  permission: Scalars["Boolean"]["output"];
};

export type PayloadPreferencesReadAccess = {
  __typename?: "PayloadPreferencesReadAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PayloadPreferencesReadDocAccess = {
  __typename?: "PayloadPreferencesReadDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PayloadPreferencesUpdateAccess = {
  __typename?: "PayloadPreferencesUpdateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PayloadPreferencesUpdateDocAccess = {
  __typename?: "PayloadPreferencesUpdateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type PurposeBoxBlock = {
  __typename?: "PurposeBoxBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  icon: Maybe<PurposeBoxBlock_Icon | "%future added value">;
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
};

export type PurposeBoxBlockTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum PurposeBoxBlock_Icon {
  Compass = "compass",
  Eye = "eye",
  Filter = "filter",
  Heart = "heart",
  Send = "send",
  Target = "target",
  Volume_1 = "volume_1",
  Volume_2 = "volume_2",
  X = "x",
}

export type PurposeGridBlock = {
  __typename?: "PurposeGridBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  footer: Maybe<PurposeGridBlock_Footer>;
  id: Maybe<Scalars["String"]["output"]>;
  showFooter: Maybe<Scalars["Boolean"]["output"]>;
  steps: Maybe<Array<PurposeGridBlock_Steps>>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type PurposeGridBlock_Footer = {
  __typename?: "PurposeGridBlock_Footer";
  linkLabel: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["String"]["output"]>;
  url: Maybe<Scalars["String"]["output"]>;
};

export type PurposeGridBlock_Steps = {
  __typename?: "PurposeGridBlock_Steps";
  additionalContent: Maybe<Array<PurposeGridBlock_Steps_AdditionalContent>>;
  icon: Maybe<PurposeGridBlock_Steps_Icon | "%future added value">;
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  video: Maybe<Scalars["String"]["output"]>;
};

export type PurposeGridBlock_StepsTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PurposeGridBlock_Steps_AdditionalContent = BubbleTextBlock;

export enum PurposeGridBlock_Steps_Icon {
  Compass = "compass",
  Eye = "eye",
  Filter = "filter",
  Heart = "heart",
  Send = "send",
  Target = "target",
  Volume_1 = "volume_1",
  Volume_2 = "volume_2",
  X = "x",
}

export type Query = {
  __typename?: "Query";
  Access: Maybe<Access>;
  Footer: Maybe<Footer>;
  Media: Maybe<Media>;
  Medias: Maybe<Medias>;
  Navigation: Maybe<Navigation>;
  Page: Maybe<Page>;
  Pages: Maybe<Pages>;
  PayloadPreference: Maybe<PayloadPreference>;
  PayloadPreferences: Maybe<PayloadPreferences>;
  User: Maybe<User>;
  Users: Maybe<Users>;
  docAccessFooter: Maybe<FooterDocAccess>;
  docAccessMedia: Maybe<MediasDocAccess>;
  docAccessNavigation: Maybe<NavigationDocAccess>;
  docAccessPage: Maybe<PagesDocAccess>;
  docAccessPayloadPreference: Maybe<Payload_PreferencesDocAccess>;
  docAccessUser: Maybe<UsersDocAccess>;
  initializedUser: Maybe<Scalars["Boolean"]["output"]>;
  meUser: Maybe<UsersMe>;
  versionPage: Maybe<PageVersion>;
  versionsPages: Maybe<VersionsPages>;
};

export type QueryFooterArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryMediaArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type QueryMediasArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<Media_Where>;
};

export type QueryNavigationArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryPageArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type QueryPagesArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<Page_Where>;
};

export type QueryPayloadPreferenceArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type QueryPayloadPreferencesArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<PayloadPreference_Where>;
};

export type QueryUserArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
};

export type QueryUsersArgs = {
  draft?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<User_Where>;
};

export type QueryDocAccessMediaArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDocAccessPageArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDocAccessPayloadPreferenceArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryDocAccessUserArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryVersionPageArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryVersionsPagesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  where?: InputMaybe<VersionsPage_Where>;
};

export type QuotationBoxBlock = {
  __typename?: "QuotationBoxBlock";
  author: Maybe<Scalars["String"]["output"]>;
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
};

export type QuotationBoxBlockTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReviewQuotationBoxBlock = {
  __typename?: "ReviewQuotationBoxBlock";
  author: Maybe<Scalars["String"]["output"]>;
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  image: Maybe<Media>;
  text: Maybe<Scalars["JSON"]["output"]>;
};

export type ReviewQuotationBoxBlockImageArgs = {
  where?: InputMaybe<ReviewQuotationBoxBlock_Image_Where>;
};

export type ReviewQuotationBoxBlockTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Id_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Lg__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Lg__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Lg__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Lg__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Lg__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Lg__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Md__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Md__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Md__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Md__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Md__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Md__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_2k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_4k__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Xs__Filename_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Xs__Filesize_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Xs__Height_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Xs__MimeType_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Xs__Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Sizes__Xs__Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ReviewQuotationBoxBlock_Image_Url_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type ReviewQuotationBoxBlock_Image_Where = {
  AND?: InputMaybe<Array<InputMaybe<ReviewQuotationBoxBlock_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewQuotationBoxBlock_Image_Where_Or>>>;
  createdAt?: InputMaybe<ReviewQuotationBoxBlock_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Filename_Operator>;
  filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Filesize_Operator>;
  height?: InputMaybe<ReviewQuotationBoxBlock_Image_Height_Operator>;
  id?: InputMaybe<ReviewQuotationBoxBlock_Image_Id_Operator>;
  mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<ReviewQuotationBoxBlock_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ReviewQuotationBoxBlock_Image_Url_Operator>;
  width?: InputMaybe<ReviewQuotationBoxBlock_Image_Width_Operator>;
};

export type ReviewQuotationBoxBlock_Image_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<ReviewQuotationBoxBlock_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewQuotationBoxBlock_Image_Where_Or>>>;
  createdAt?: InputMaybe<ReviewQuotationBoxBlock_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Filename_Operator>;
  filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Filesize_Operator>;
  height?: InputMaybe<ReviewQuotationBoxBlock_Image_Height_Operator>;
  id?: InputMaybe<ReviewQuotationBoxBlock_Image_Id_Operator>;
  mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<ReviewQuotationBoxBlock_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ReviewQuotationBoxBlock_Image_Url_Operator>;
  width?: InputMaybe<ReviewQuotationBoxBlock_Image_Width_Operator>;
};

export type ReviewQuotationBoxBlock_Image_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<ReviewQuotationBoxBlock_Image_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<ReviewQuotationBoxBlock_Image_Where_Or>>>;
  createdAt?: InputMaybe<ReviewQuotationBoxBlock_Image_CreatedAt_Operator>;
  filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Filename_Operator>;
  filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Filesize_Operator>;
  height?: InputMaybe<ReviewQuotationBoxBlock_Image_Height_Operator>;
  id?: InputMaybe<ReviewQuotationBoxBlock_Image_Id_Operator>;
  mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_MimeType_Operator>;
  sizes__lg__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Filename_Operator>;
  sizes__lg__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Filesize_Operator>;
  sizes__lg__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Height_Operator>;
  sizes__lg__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__MimeType_Operator>;
  sizes__lg__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Url_Operator>;
  sizes__lg__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Lg__Width_Operator>;
  sizes__md__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Filename_Operator>;
  sizes__md__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Filesize_Operator>;
  sizes__md__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Height_Operator>;
  sizes__md__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__MimeType_Operator>;
  sizes__md__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Url_Operator>;
  sizes__md__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Md__Width_Operator>;
  sizes__res_2k__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Filename_Operator>;
  sizes__res_2k__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Filesize_Operator>;
  sizes__res_2k__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Height_Operator>;
  sizes__res_2k__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__MimeType_Operator>;
  sizes__res_2k__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Url_Operator>;
  sizes__res_2k__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_2k__Width_Operator>;
  sizes__res_4k__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Filename_Operator>;
  sizes__res_4k__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Filesize_Operator>;
  sizes__res_4k__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Height_Operator>;
  sizes__res_4k__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__MimeType_Operator>;
  sizes__res_4k__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Url_Operator>;
  sizes__res_4k__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_4k__Width_Operator>;
  sizes__res_fullhd__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Filename_Operator>;
  sizes__res_fullhd__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Filesize_Operator>;
  sizes__res_fullhd__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Height_Operator>;
  sizes__res_fullhd__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__MimeType_Operator>;
  sizes__res_fullhd__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Url_Operator>;
  sizes__res_fullhd__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Fullhd__Width_Operator>;
  sizes__res_hd__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Filename_Operator>;
  sizes__res_hd__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Filesize_Operator>;
  sizes__res_hd__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Height_Operator>;
  sizes__res_hd__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__MimeType_Operator>;
  sizes__res_hd__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Url_Operator>;
  sizes__res_hd__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Res_Hd__Width_Operator>;
  sizes__xs__filename?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Filename_Operator>;
  sizes__xs__filesize?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Filesize_Operator>;
  sizes__xs__height?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Height_Operator>;
  sizes__xs__mimeType?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__MimeType_Operator>;
  sizes__xs__url?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Url_Operator>;
  sizes__xs__width?: InputMaybe<ReviewQuotationBoxBlock_Image_Sizes__Xs__Width_Operator>;
  updatedAt?: InputMaybe<ReviewQuotationBoxBlock_Image_UpdatedAt_Operator>;
  url?: InputMaybe<ReviewQuotationBoxBlock_Image_Url_Operator>;
  width?: InputMaybe<ReviewQuotationBoxBlock_Image_Width_Operator>;
};

export type ReviewQuotationBoxBlock_Image_Width_Operator = {
  equals?: InputMaybe<Scalars["Float"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Float"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  less_than?: InputMaybe<Scalars["Float"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Float"]["input"]>;
  not_equals?: InputMaybe<Scalars["Float"]["input"]>;
};

export type TextGridModal = {
  __typename?: "TextGridModal";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  tiles: Maybe<Array<TextGridModal_Tiles>>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type TextGridModal_Tiles = {
  __typename?: "TextGridModal_Tiles";
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type TextGridModal_TilesTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TitleTextBoxBlock = {
  __typename?: "TitleTextBoxBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  emphasizeFirstLetter: Maybe<Scalars["Boolean"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  text: Maybe<Scalars["JSON"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
};

export type TitleTextBoxBlockTextArgs = {
  depth?: InputMaybe<Scalars["Int"]["input"]>;
};

export type User = {
  __typename?: "User";
  createdAt: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["EmailAddress"]["output"];
  hash: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["Int"]["output"]>;
  lockUntil: Maybe<Scalars["DateTime"]["output"]>;
  loginAttempts: Maybe<Scalars["Float"]["output"]>;
  password: Scalars["String"]["output"];
  resetPasswordExpiration: Maybe<Scalars["DateTime"]["output"]>;
  resetPasswordToken: Maybe<Scalars["String"]["output"]>;
  salt: Maybe<Scalars["String"]["output"]>;
  updatedAt: Maybe<Scalars["DateTime"]["output"]>;
};

export type User_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type User_Email_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["EmailAddress"]["input"]>>>;
  contains?: InputMaybe<Scalars["EmailAddress"]["input"]>;
  equals?: InputMaybe<Scalars["EmailAddress"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["EmailAddress"]["input"]>>>;
  like?: InputMaybe<Scalars["EmailAddress"]["input"]>;
  not_equals?: InputMaybe<Scalars["EmailAddress"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["EmailAddress"]["input"]>>>;
};

export type User_Id_Operator = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Int"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  less_than?: InputMaybe<Scalars["Int"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  not_equals?: InputMaybe<Scalars["Int"]["input"]>;
};

export type User_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type User_Where = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type User_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type User_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<User_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<User_Where_Or>>>;
  createdAt?: InputMaybe<User_CreatedAt_Operator>;
  email?: InputMaybe<User_Email_Operator>;
  id?: InputMaybe<User_Id_Operator>;
  updatedAt?: InputMaybe<User_UpdatedAt_Operator>;
};

export type Users = {
  __typename?: "Users";
  docs: Maybe<Array<Maybe<User>>>;
  hasNextPage: Maybe<Scalars["Boolean"]["output"]>;
  hasPrevPage: Maybe<Scalars["Boolean"]["output"]>;
  limit: Maybe<Scalars["Int"]["output"]>;
  nextPage: Maybe<Scalars["Int"]["output"]>;
  offset: Maybe<Scalars["Int"]["output"]>;
  page: Maybe<Scalars["Int"]["output"]>;
  pagingCounter: Maybe<Scalars["Int"]["output"]>;
  prevPage: Maybe<Scalars["Int"]["output"]>;
  totalDocs: Maybe<Scalars["Int"]["output"]>;
  totalPages: Maybe<Scalars["Int"]["output"]>;
};

export type UsersCreateAccess = {
  __typename?: "UsersCreateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersCreateDocAccess = {
  __typename?: "UsersCreateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersDeleteAccess = {
  __typename?: "UsersDeleteAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersDeleteDocAccess = {
  __typename?: "UsersDeleteDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersDocAccessFields = {
  __typename?: "UsersDocAccessFields";
  createdAt: Maybe<UsersDocAccessFields_CreatedAt>;
  email: Maybe<UsersDocAccessFields_Email>;
  password: Maybe<UsersDocAccessFields_Password>;
  updatedAt: Maybe<UsersDocAccessFields_UpdatedAt>;
};

export type UsersDocAccessFields_CreatedAt = {
  __typename?: "UsersDocAccessFields_createdAt";
  create: Maybe<UsersDocAccessFields_CreatedAt_Create>;
  delete: Maybe<UsersDocAccessFields_CreatedAt_Delete>;
  read: Maybe<UsersDocAccessFields_CreatedAt_Read>;
  update: Maybe<UsersDocAccessFields_CreatedAt_Update>;
};

export type UsersDocAccessFields_CreatedAt_Create = {
  __typename?: "UsersDocAccessFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_CreatedAt_Delete = {
  __typename?: "UsersDocAccessFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_CreatedAt_Read = {
  __typename?: "UsersDocAccessFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_CreatedAt_Update = {
  __typename?: "UsersDocAccessFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_Email = {
  __typename?: "UsersDocAccessFields_email";
  create: Maybe<UsersDocAccessFields_Email_Create>;
  delete: Maybe<UsersDocAccessFields_Email_Delete>;
  read: Maybe<UsersDocAccessFields_Email_Read>;
  update: Maybe<UsersDocAccessFields_Email_Update>;
};

export type UsersDocAccessFields_Email_Create = {
  __typename?: "UsersDocAccessFields_email_Create";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_Email_Delete = {
  __typename?: "UsersDocAccessFields_email_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_Email_Read = {
  __typename?: "UsersDocAccessFields_email_Read";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_Email_Update = {
  __typename?: "UsersDocAccessFields_email_Update";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_Password = {
  __typename?: "UsersDocAccessFields_password";
  create: Maybe<UsersDocAccessFields_Password_Create>;
  delete: Maybe<UsersDocAccessFields_Password_Delete>;
  read: Maybe<UsersDocAccessFields_Password_Read>;
  update: Maybe<UsersDocAccessFields_Password_Update>;
};

export type UsersDocAccessFields_Password_Create = {
  __typename?: "UsersDocAccessFields_password_Create";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_Password_Delete = {
  __typename?: "UsersDocAccessFields_password_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_Password_Read = {
  __typename?: "UsersDocAccessFields_password_Read";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_Password_Update = {
  __typename?: "UsersDocAccessFields_password_Update";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_UpdatedAt = {
  __typename?: "UsersDocAccessFields_updatedAt";
  create: Maybe<UsersDocAccessFields_UpdatedAt_Create>;
  delete: Maybe<UsersDocAccessFields_UpdatedAt_Delete>;
  read: Maybe<UsersDocAccessFields_UpdatedAt_Read>;
  update: Maybe<UsersDocAccessFields_UpdatedAt_Update>;
};

export type UsersDocAccessFields_UpdatedAt_Create = {
  __typename?: "UsersDocAccessFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_UpdatedAt_Delete = {
  __typename?: "UsersDocAccessFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_UpdatedAt_Read = {
  __typename?: "UsersDocAccessFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type UsersDocAccessFields_UpdatedAt_Update = {
  __typename?: "UsersDocAccessFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields = {
  __typename?: "UsersFields";
  createdAt: Maybe<UsersFields_CreatedAt>;
  email: Maybe<UsersFields_Email>;
  password: Maybe<UsersFields_Password>;
  updatedAt: Maybe<UsersFields_UpdatedAt>;
};

export type UsersFields_CreatedAt = {
  __typename?: "UsersFields_createdAt";
  create: Maybe<UsersFields_CreatedAt_Create>;
  delete: Maybe<UsersFields_CreatedAt_Delete>;
  read: Maybe<UsersFields_CreatedAt_Read>;
  update: Maybe<UsersFields_CreatedAt_Update>;
};

export type UsersFields_CreatedAt_Create = {
  __typename?: "UsersFields_createdAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_CreatedAt_Delete = {
  __typename?: "UsersFields_createdAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_CreatedAt_Read = {
  __typename?: "UsersFields_createdAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_CreatedAt_Update = {
  __typename?: "UsersFields_createdAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_Email = {
  __typename?: "UsersFields_email";
  create: Maybe<UsersFields_Email_Create>;
  delete: Maybe<UsersFields_Email_Delete>;
  read: Maybe<UsersFields_Email_Read>;
  update: Maybe<UsersFields_Email_Update>;
};

export type UsersFields_Email_Create = {
  __typename?: "UsersFields_email_Create";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_Email_Delete = {
  __typename?: "UsersFields_email_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_Email_Read = {
  __typename?: "UsersFields_email_Read";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_Email_Update = {
  __typename?: "UsersFields_email_Update";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_Password = {
  __typename?: "UsersFields_password";
  create: Maybe<UsersFields_Password_Create>;
  delete: Maybe<UsersFields_Password_Delete>;
  read: Maybe<UsersFields_Password_Read>;
  update: Maybe<UsersFields_Password_Update>;
};

export type UsersFields_Password_Create = {
  __typename?: "UsersFields_password_Create";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_Password_Delete = {
  __typename?: "UsersFields_password_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_Password_Read = {
  __typename?: "UsersFields_password_Read";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_Password_Update = {
  __typename?: "UsersFields_password_Update";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_UpdatedAt = {
  __typename?: "UsersFields_updatedAt";
  create: Maybe<UsersFields_UpdatedAt_Create>;
  delete: Maybe<UsersFields_UpdatedAt_Delete>;
  read: Maybe<UsersFields_UpdatedAt_Read>;
  update: Maybe<UsersFields_UpdatedAt_Update>;
};

export type UsersFields_UpdatedAt_Create = {
  __typename?: "UsersFields_updatedAt_Create";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_UpdatedAt_Delete = {
  __typename?: "UsersFields_updatedAt_Delete";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_UpdatedAt_Read = {
  __typename?: "UsersFields_updatedAt_Read";
  permission: Scalars["Boolean"]["output"];
};

export type UsersFields_UpdatedAt_Update = {
  __typename?: "UsersFields_updatedAt_Update";
  permission: Scalars["Boolean"]["output"];
};

export type UsersReadAccess = {
  __typename?: "UsersReadAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersReadDocAccess = {
  __typename?: "UsersReadDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersUnlockAccess = {
  __typename?: "UsersUnlockAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersUnlockDocAccess = {
  __typename?: "UsersUnlockDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersUpdateAccess = {
  __typename?: "UsersUpdateAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type UsersUpdateDocAccess = {
  __typename?: "UsersUpdateDocAccess";
  permission: Scalars["Boolean"]["output"];
  where: Maybe<Scalars["JSONObject"]["output"]>;
};

export type VideoCarouselBlock = {
  __typename?: "VideoCarouselBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  videos: Maybe<Array<VideoCarouselBlock_Videos>>;
};

export type VideoCarouselBlock_Videos = {
  __typename?: "VideoCarouselBlock_Videos";
  id: Maybe<Scalars["String"]["output"]>;
  video: Maybe<Scalars["String"]["output"]>;
};

export type VideoJourneyBlock = {
  __typename?: "VideoJourneyBlock";
  blockName: Maybe<Scalars["String"]["output"]>;
  blockType: Maybe<Scalars["String"]["output"]>;
  bubbleText: Maybe<Scalars["String"]["output"]>;
  id: Maybe<Scalars["String"]["output"]>;
  items: Maybe<Array<VideoJourneyBlock_Items>>;
};

export type VideoJourneyBlock_Items = {
  __typename?: "VideoJourneyBlock_Items";
  id: Maybe<Scalars["String"]["output"]>;
  label: Maybe<Scalars["String"]["output"]>;
  navigateTo: Maybe<Scalars["String"]["output"]>;
  title: Maybe<Scalars["String"]["output"]>;
  video: Maybe<Scalars["String"]["output"]>;
};

export type FooterAccess = {
  __typename?: "footerAccess";
  fields: Maybe<FooterFields>;
  read: Maybe<FooterReadAccess>;
  update: Maybe<FooterUpdateAccess>;
};

export type FooterDocAccess = {
  __typename?: "footerDocAccess";
  fields: Maybe<FooterDocAccessFields>;
  read: Maybe<FooterReadDocAccess>;
  update: Maybe<FooterUpdateDocAccess>;
};

export type MediasAccess = {
  __typename?: "mediasAccess";
  create: Maybe<MediasCreateAccess>;
  delete: Maybe<MediasDeleteAccess>;
  fields: Maybe<MediasFields>;
  read: Maybe<MediasReadAccess>;
  update: Maybe<MediasUpdateAccess>;
};

export type MediasDocAccess = {
  __typename?: "mediasDocAccess";
  create: Maybe<MediasCreateDocAccess>;
  delete: Maybe<MediasDeleteDocAccess>;
  fields: Maybe<MediasDocAccessFields>;
  read: Maybe<MediasReadDocAccess>;
  update: Maybe<MediasUpdateDocAccess>;
};

export type MutationFooterInput = {
  address: Scalars["JSON"]["input"];
  contact?: InputMaybe<MutationFooter_ContactInput>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  socials?: InputMaybe<MutationFooter_SocialsInput>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationFooter_ContactInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  emailContent?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationFooter_SocialsInput = {
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationMediaInput = {
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  sizes?: InputMaybe<MutationMedia_SizesInput>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMediaUpdateInput = {
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  sizes?: InputMaybe<MutationMediaUpdate_SizesInput>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMediaUpdate_SizesInput = {
  lg?: InputMaybe<MutationMediaUpdate_Sizes_LgInput>;
  md?: InputMaybe<MutationMediaUpdate_Sizes_MdInput>;
  res_2k?: InputMaybe<MutationMediaUpdate_Sizes_Res_2kInput>;
  res_4k?: InputMaybe<MutationMediaUpdate_Sizes_Res_4kInput>;
  res_fullhd?: InputMaybe<MutationMediaUpdate_Sizes_Res_FullhdInput>;
  res_hd?: InputMaybe<MutationMediaUpdate_Sizes_Res_HdInput>;
  xs?: InputMaybe<MutationMediaUpdate_Sizes_XsInput>;
};

export type MutationMediaUpdate_Sizes_LgInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMediaUpdate_Sizes_MdInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMediaUpdate_Sizes_Res_2kInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMediaUpdate_Sizes_Res_4kInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMediaUpdate_Sizes_Res_FullhdInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMediaUpdate_Sizes_Res_HdInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMediaUpdate_Sizes_XsInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMedia_SizesInput = {
  lg?: InputMaybe<MutationMedia_Sizes_LgInput>;
  md?: InputMaybe<MutationMedia_Sizes_MdInput>;
  res_2k?: InputMaybe<MutationMedia_Sizes_Res_2kInput>;
  res_4k?: InputMaybe<MutationMedia_Sizes_Res_4kInput>;
  res_fullhd?: InputMaybe<MutationMedia_Sizes_Res_FullhdInput>;
  res_hd?: InputMaybe<MutationMedia_Sizes_Res_HdInput>;
  xs?: InputMaybe<MutationMedia_Sizes_XsInput>;
};

export type MutationMedia_Sizes_LgInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMedia_Sizes_MdInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMedia_Sizes_Res_2kInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMedia_Sizes_Res_4kInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMedia_Sizes_Res_FullhdInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMedia_Sizes_Res_HdInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationMedia_Sizes_XsInput = {
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filesize?: InputMaybe<Scalars["Float"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationNavigationInput = {
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  pages?: InputMaybe<Array<MutationNavigation_PagesInput>>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationNavigation_PagesInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationPageInput = {
  _status?: InputMaybe<Page__Status_MutationInput | "%future added value">;
  content?: InputMaybe<Scalars["JSON"]["input"]>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<MutationPage_MetaInput>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPageUpdateInput = {
  _status?: InputMaybe<PageUpdate__Status_MutationInput | "%future added value">;
  content?: InputMaybe<Scalars["JSON"]["input"]>;
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  meta?: InputMaybe<MutationPageUpdate_MetaInput>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPageUpdate_MetaInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPage_MetaInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPayloadPreferenceInput = {
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<PayloadPreference_UserRelationshipInput>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type MutationPayloadPreferenceUpdateInput = {
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<PayloadPreferenceUpdate_UserRelationshipInput>;
  value?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type MutationUserInput = {
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  hash?: InputMaybe<Scalars["String"]["input"]>;
  lockUntil?: InputMaybe<Scalars["String"]["input"]>;
  loginAttempts?: InputMaybe<Scalars["Float"]["input"]>;
  password: Scalars["String"]["input"];
  resetPasswordExpiration?: InputMaybe<Scalars["String"]["input"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]["input"]>;
  salt?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUserUpdateInput = {
  createdAt?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  hash?: InputMaybe<Scalars["String"]["input"]>;
  lockUntil?: InputMaybe<Scalars["String"]["input"]>;
  loginAttempts?: InputMaybe<Scalars["Float"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  resetPasswordExpiration?: InputMaybe<Scalars["String"]["input"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]["input"]>;
  salt?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type NavigationAccess = {
  __typename?: "navigationAccess";
  fields: Maybe<NavigationFields>;
  read: Maybe<NavigationReadAccess>;
  update: Maybe<NavigationUpdateAccess>;
};

export type NavigationDocAccess = {
  __typename?: "navigationDocAccess";
  fields: Maybe<NavigationDocAccessFields>;
  read: Maybe<NavigationReadDocAccess>;
  update: Maybe<NavigationUpdateDocAccess>;
};

export type PagesAccess = {
  __typename?: "pagesAccess";
  create: Maybe<PagesCreateAccess>;
  delete: Maybe<PagesDeleteAccess>;
  fields: Maybe<PagesFields>;
  read: Maybe<PagesReadAccess>;
  readVersions: Maybe<PagesReadVersionsAccess>;
  update: Maybe<PagesUpdateAccess>;
};

export type PagesDocAccess = {
  __typename?: "pagesDocAccess";
  create: Maybe<PagesCreateDocAccess>;
  delete: Maybe<PagesDeleteDocAccess>;
  fields: Maybe<PagesDocAccessFields>;
  read: Maybe<PagesReadDocAccess>;
  readVersions: Maybe<PagesReadVersionsDocAccess>;
  update: Maybe<PagesUpdateDocAccess>;
};

export type Payload_PreferencesAccess = {
  __typename?: "payload_preferencesAccess";
  create: Maybe<PayloadPreferencesCreateAccess>;
  delete: Maybe<PayloadPreferencesDeleteAccess>;
  fields: Maybe<PayloadPreferencesFields>;
  read: Maybe<PayloadPreferencesReadAccess>;
  update: Maybe<PayloadPreferencesUpdateAccess>;
};

export type Payload_PreferencesDocAccess = {
  __typename?: "payload_preferencesDocAccess";
  create: Maybe<PayloadPreferencesCreateDocAccess>;
  delete: Maybe<PayloadPreferencesDeleteDocAccess>;
  fields: Maybe<PayloadPreferencesDocAccessFields>;
  read: Maybe<PayloadPreferencesReadDocAccess>;
  update: Maybe<PayloadPreferencesUpdateDocAccess>;
};

export type UsersAccess = {
  __typename?: "usersAccess";
  create: Maybe<UsersCreateAccess>;
  delete: Maybe<UsersDeleteAccess>;
  fields: Maybe<UsersFields>;
  read: Maybe<UsersReadAccess>;
  unlock: Maybe<UsersUnlockAccess>;
  update: Maybe<UsersUpdateAccess>;
};

export type UsersDocAccess = {
  __typename?: "usersDocAccess";
  create: Maybe<UsersCreateDocAccess>;
  delete: Maybe<UsersDeleteDocAccess>;
  fields: Maybe<UsersDocAccessFields>;
  read: Maybe<UsersReadDocAccess>;
  unlock: Maybe<UsersUnlockDocAccess>;
  update: Maybe<UsersUpdateDocAccess>;
};

export type UsersJwt = {
  __typename?: "usersJWT";
  collection: Scalars["String"]["output"];
  email: Scalars["EmailAddress"]["output"];
};

export type UsersLoginResult = {
  __typename?: "usersLoginResult";
  exp: Maybe<Scalars["Int"]["output"]>;
  token: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User>;
};

export type UsersMe = {
  __typename?: "usersMe";
  collection: Maybe<Scalars["String"]["output"]>;
  exp: Maybe<Scalars["Int"]["output"]>;
  token: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User>;
};

export type UsersRefreshedUser = {
  __typename?: "usersRefreshedUser";
  exp: Maybe<Scalars["Int"]["output"]>;
  refreshedToken: Maybe<Scalars["String"]["output"]>;
  user: Maybe<UsersJwt>;
};

export type UsersResetPassword = {
  __typename?: "usersResetPassword";
  token: Maybe<Scalars["String"]["output"]>;
  user: Maybe<User>;
};

export type VersionsPage_CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VersionsPage_Id_Operator = {
  equals?: InputMaybe<Scalars["Int"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["Int"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  less_than?: InputMaybe<Scalars["Int"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["Int"]["input"]>;
  not_equals?: InputMaybe<Scalars["Int"]["input"]>;
};

export type VersionsPage_Latest_Operator = {
  equals?: InputMaybe<Scalars["Boolean"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  not_equals?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type VersionsPage_Parent_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  equals?: InputMaybe<Scalars["JSON"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  not_equals?: InputMaybe<Scalars["JSON"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
};

export type VersionsPage_UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export enum VersionsPage_Version___Status_Input {
  Draft = "draft",
  Published = "published",
}

export type VersionsPage_Version___Status_Operator = {
  all?: InputMaybe<Array<InputMaybe<VersionsPage_Version___Status_Input | "%future added value">>>;
  equals?: InputMaybe<VersionsPage_Version___Status_Input | "%future added value">;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<VersionsPage_Version___Status_Input | "%future added value">>>;
  not_equals?: InputMaybe<VersionsPage_Version___Status_Input | "%future added value">;
  not_in?: InputMaybe<Array<InputMaybe<VersionsPage_Version___Status_Input | "%future added value">>>;
};

export type VersionsPage_Version__CreatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VersionsPage_Version__Meta__Description_Operator = {
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
};

export type VersionsPage_Version__Meta__Image_Operator = {
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
};

export type VersionsPage_Version__Meta__Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type VersionsPage_Version__Slug_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type VersionsPage_Version__Title_Operator = {
  all?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contains?: InputMaybe<Scalars["String"]["input"]>;
  equals?: InputMaybe<Scalars["String"]["input"]>;
  in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  like?: InputMaybe<Scalars["String"]["input"]>;
  not_equals?: InputMaybe<Scalars["String"]["input"]>;
  not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type VersionsPage_Version__UpdatedAt_Operator = {
  equals?: InputMaybe<Scalars["DateTime"]["input"]>;
  exists?: InputMaybe<Scalars["Boolean"]["input"]>;
  greater_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  greater_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than?: InputMaybe<Scalars["DateTime"]["input"]>;
  less_than_equal?: InputMaybe<Scalars["DateTime"]["input"]>;
  like?: InputMaybe<Scalars["DateTime"]["input"]>;
  not_equals?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type VersionsPage_Where = {
  AND?: InputMaybe<Array<InputMaybe<VersionsPage_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsPage_Where_Or>>>;
  createdAt?: InputMaybe<VersionsPage_CreatedAt_Operator>;
  id?: InputMaybe<VersionsPage_Id_Operator>;
  latest?: InputMaybe<VersionsPage_Latest_Operator>;
  parent?: InputMaybe<VersionsPage_Parent_Operator>;
  updatedAt?: InputMaybe<VersionsPage_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsPage_Version___Status_Operator>;
  version__createdAt?: InputMaybe<VersionsPage_Version__CreatedAt_Operator>;
  version__meta__description?: InputMaybe<VersionsPage_Version__Meta__Description_Operator>;
  version__meta__image?: InputMaybe<VersionsPage_Version__Meta__Image_Operator>;
  version__meta__title?: InputMaybe<VersionsPage_Version__Meta__Title_Operator>;
  version__slug?: InputMaybe<VersionsPage_Version__Slug_Operator>;
  version__title?: InputMaybe<VersionsPage_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsPage_Version__UpdatedAt_Operator>;
};

export type VersionsPage_Where_And = {
  AND?: InputMaybe<Array<InputMaybe<VersionsPage_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsPage_Where_Or>>>;
  createdAt?: InputMaybe<VersionsPage_CreatedAt_Operator>;
  id?: InputMaybe<VersionsPage_Id_Operator>;
  latest?: InputMaybe<VersionsPage_Latest_Operator>;
  parent?: InputMaybe<VersionsPage_Parent_Operator>;
  updatedAt?: InputMaybe<VersionsPage_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsPage_Version___Status_Operator>;
  version__createdAt?: InputMaybe<VersionsPage_Version__CreatedAt_Operator>;
  version__meta__description?: InputMaybe<VersionsPage_Version__Meta__Description_Operator>;
  version__meta__image?: InputMaybe<VersionsPage_Version__Meta__Image_Operator>;
  version__meta__title?: InputMaybe<VersionsPage_Version__Meta__Title_Operator>;
  version__slug?: InputMaybe<VersionsPage_Version__Slug_Operator>;
  version__title?: InputMaybe<VersionsPage_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsPage_Version__UpdatedAt_Operator>;
};

export type VersionsPage_Where_Or = {
  AND?: InputMaybe<Array<InputMaybe<VersionsPage_Where_And>>>;
  OR?: InputMaybe<Array<InputMaybe<VersionsPage_Where_Or>>>;
  createdAt?: InputMaybe<VersionsPage_CreatedAt_Operator>;
  id?: InputMaybe<VersionsPage_Id_Operator>;
  latest?: InputMaybe<VersionsPage_Latest_Operator>;
  parent?: InputMaybe<VersionsPage_Parent_Operator>;
  updatedAt?: InputMaybe<VersionsPage_UpdatedAt_Operator>;
  version___status?: InputMaybe<VersionsPage_Version___Status_Operator>;
  version__createdAt?: InputMaybe<VersionsPage_Version__CreatedAt_Operator>;
  version__meta__description?: InputMaybe<VersionsPage_Version__Meta__Description_Operator>;
  version__meta__image?: InputMaybe<VersionsPage_Version__Meta__Image_Operator>;
  version__meta__title?: InputMaybe<VersionsPage_Version__Meta__Title_Operator>;
  version__slug?: InputMaybe<VersionsPage_Version__Slug_Operator>;
  version__title?: InputMaybe<VersionsPage_Version__Title_Operator>;
  version__updatedAt?: InputMaybe<VersionsPage_Version__UpdatedAt_Operator>;
};

export type VersionsPages = {
  __typename?: "versionsPages";
  docs: Maybe<Array<Maybe<PageVersion>>>;
  hasNextPage: Maybe<Scalars["Boolean"]["output"]>;
  hasPrevPage: Maybe<Scalars["Boolean"]["output"]>;
  limit: Maybe<Scalars["Int"]["output"]>;
  nextPage: Maybe<Scalars["Int"]["output"]>;
  offset: Maybe<Scalars["Int"]["output"]>;
  page: Maybe<Scalars["Int"]["output"]>;
  pagingCounter: Maybe<Scalars["Int"]["output"]>;
  prevPage: Maybe<Scalars["Int"]["output"]>;
  totalDocs: Maybe<Scalars["Int"]["output"]>;
  totalPages: Maybe<Scalars["Int"]["output"]>;
};

export type FooterQueryVariables = Exact<{ [key: string]: never }>;

export type FooterQuery = {
  __typename?: "Query";
  Footer: {
    __typename?: "Footer";
    address: any;
    contact: {
      __typename?: "Footer_Contact";
      title: string | null;
      phoneNumber: string | null;
      email: string | null;
      emailContent: string | null;
    } | null;
    socials: { __typename?: "Footer_Socials"; linkedinUrl: string | null } | null;
  } | null;
};

export type NavQueryVariables = Exact<{ [key: string]: never }>;

export type NavQuery = {
  __typename?: "Query";
  Navigation: {
    __typename?: "Navigation";
    pages: Array<{
      __typename?: "Navigation_Pages";
      page: { __typename?: "Page"; id: number | null; title: string | null; slug: string | null } | null;
    }>;
  } | null;
};

export type BubbleTextFragment = {
  __typename?: "BubbleTextBlock";
  id: string | null;
  url: string | null;
  openInNewTab: boolean | null;
  bubbleText: string | null;
  page: { __typename?: "Page"; slug: string | null } | null;
  modal: Array<
    | {
        __typename?: "MailchimpSignupModal";
        id: string | null;
        title: string | null;
        text: any | null;
        Mailchimp: {
          __typename?: "MailchimpSignupModal_Mailchimp";
          actionUrl: string | null;
          tags: Array<{
            __typename?: "MailchimpSignupModal_Mailchimp_Tags";
            id: string | null;
            tag: string | null;
          }> | null;
        } | null;
      }
    | {
        __typename?: "NumberedItemsModal";
        id: string | null;
        title: string | null;
        description: any | null;
        items: Array<{ __typename?: "NumberedItemsModal_Items"; id: string | null; text: any | null }> | null;
      }
    | {
        __typename?: "TextGridModal";
        id: string | null;
        title: string | null;
        tiles: Array<{
          __typename?: "TextGridModal_Tiles";
          id: string | null;
          title: string | null;
          text: any | null;
        }> | null;
      }
  > | null;
} & { " $fragmentName"?: "BubbleTextFragment" };

export type SizedImageFragment = {
  __typename?: "Media";
  id: number | null;
  filename: string | null;
  sizes: {
    __typename?: "Media_Sizes";
    res_4k: { __typename?: "Media_Sizes_Res_4k"; url: string | null } | null;
    res_2k: { __typename?: "Media_Sizes_Res_2k"; url: string | null } | null;
    res_fullhd: { __typename?: "Media_Sizes_Res_fullhd"; url: string | null } | null;
    res_hd: { __typename?: "Media_Sizes_Res_hd"; url: string | null } | null;
    lg: { __typename?: "Media_Sizes_Lg"; url: string | null } | null;
    md: { __typename?: "Media_Sizes_Md"; url: string | null } | null;
    xs: { __typename?: "Media_Sizes_Xs"; url: string | null } | null;
  } | null;
} & { " $fragmentName"?: "SizedImageFragment" };

type PageBlock_AnchorBlock_Fragment = { __typename: "AnchorBlock"; id: string | null; name: string | null } & {
  " $fragmentName"?: "PageBlock_AnchorBlock_Fragment";
};

type PageBlock_AngledTextBoxBlock_Fragment = {
  __typename: "AngledTextBoxBlock";
  id: string | null;
  title: string | null;
  text: any | null;
} & { " $fragmentName"?: "PageBlock_AngledTextBoxBlock_Fragment" };

type PageBlock_BubbleTextBlock_Fragment = {
  __typename: "BubbleTextBlock";
  id: string | null;
  url: string | null;
  bubbleText: string | null;
  page: { __typename?: "Page"; slug: string | null } | null;
  modal: Array<
    | {
        __typename?: "MailchimpSignupModal";
        id: string | null;
        title: string | null;
        text: any | null;
        Mailchimp: {
          __typename?: "MailchimpSignupModal_Mailchimp";
          actionUrl: string | null;
          tags: Array<{
            __typename?: "MailchimpSignupModal_Mailchimp_Tags";
            id: string | null;
            tag: string | null;
          }> | null;
        } | null;
      }
    | {
        __typename?: "NumberedItemsModal";
        id: string | null;
        title: string | null;
        description: any | null;
        items: Array<{ __typename?: "NumberedItemsModal_Items"; id: string | null; text: any | null }> | null;
      }
    | {
        __typename?: "TextGridModal";
        id: string | null;
        title: string | null;
        tiles: Array<{
          __typename?: "TextGridModal_Tiles";
          id: string | null;
          title: string | null;
          text: any | null;
        }> | null;
      }
  > | null;
} & { " $fragmentName"?: "PageBlock_BubbleTextBlock_Fragment" };

type PageBlock_CaseLeadBoxBlock_Fragment = {
  __typename: "CaseLeadBoxBlock";
  id: string | null;
  headline: string | null;
  text: any | null;
  video: string | null;
  poster: ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } }) | null;
} & { " $fragmentName"?: "PageBlock_CaseLeadBoxBlock_Fragment" };

type PageBlock_CasesBoxBlock_Fragment = {
  __typename: "CasesBoxBlock";
  id: string | null;
  cases: Array<{
    __typename?: "CasesBoxBlock_Cases";
    id: string | null;
    title: string | null;
    description: string | null;
    text: any | null;
    image: ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } }) | null;
    page: { __typename?: "Page"; id: number | null; title: string | null; slug: string | null } | null;
  }> | null;
} & { " $fragmentName"?: "PageBlock_CasesBoxBlock_Fragment" };

type PageBlock_DoubleSlotBoxBlock_Fragment = {
  __typename: "DoubleSlotBoxBlock";
  id: string | null;
  contentLeft: {
    __typename?: "DoubleSlotBoxBlock_ContentLeft";
    blocks: Array<
      | {
          __typename: "BubbleTextBlock";
          id: string | null;
          url: string | null;
          bubbleText: string | null;
          page: { __typename?: "Page"; slug: string | null } | null;
          modal: Array<
            | {
                __typename?: "MailchimpSignupModal";
                id: string | null;
                title: string | null;
                text: any | null;
                Mailchimp: {
                  __typename?: "MailchimpSignupModal_Mailchimp";
                  actionUrl: string | null;
                  tags: Array<{
                    __typename?: "MailchimpSignupModal_Mailchimp_Tags";
                    id: string | null;
                    tag: string | null;
                  }> | null;
                } | null;
              }
            | {
                __typename?: "NumberedItemsModal";
                id: string | null;
                title: string | null;
                description: any | null;
                items: Array<{ __typename?: "NumberedItemsModal_Items"; id: string | null; text: any | null }> | null;
              }
            | {
                __typename?: "TextGridModal";
                id: string | null;
                title: string | null;
                tiles: Array<{
                  __typename?: "TextGridModal_Tiles";
                  id: string | null;
                  title: string | null;
                  text: any | null;
                }> | null;
              }
          > | null;
        }
      | {
          __typename: "VideoCarouselBlock";
          id: string | null;
          videos: Array<{ __typename?: "VideoCarouselBlock_Videos"; id: string | null; video: string | null }> | null;
        }
    > | null;
  } | null;
  contentRight: {
    __typename?: "DoubleSlotBoxBlock_ContentRight";
    icon: DoubleSlotBoxBlock_ContentRight_Icon | null;
    text: any | null;
  } | null;
} & { " $fragmentName"?: "PageBlock_DoubleSlotBoxBlock_Fragment" };

type PageBlock_IconCards_Fragment = {
  __typename: "IconCards";
  id: string | null;
  title: string | null;
  cards: Array<{
    __typename?: "IconCards_Cards";
    id: string | null;
    title: string | null;
    icon: IconCards_Cards_Icon | null;
    text: any | null;
    additionalContent: Array<{
      __typename: "BubbleTextBlock";
      id: string | null;
      url: string | null;
      bubbleText: string | null;
      page: { __typename?: "Page"; slug: string | null } | null;
      modal: Array<
        | {
            __typename?: "MailchimpSignupModal";
            id: string | null;
            title: string | null;
            text: any | null;
            Mailchimp: {
              __typename?: "MailchimpSignupModal_Mailchimp";
              actionUrl: string | null;
              tags: Array<{
                __typename?: "MailchimpSignupModal_Mailchimp_Tags";
                id: string | null;
                tag: string | null;
              }> | null;
            } | null;
          }
        | {
            __typename?: "NumberedItemsModal";
            id: string | null;
            title: string | null;
            description: any | null;
            items: Array<{ __typename?: "NumberedItemsModal_Items"; id: string | null; text: any | null }> | null;
          }
        | {
            __typename?: "TextGridModal";
            id: string | null;
            title: string | null;
            tiles: Array<{
              __typename?: "TextGridModal_Tiles";
              id: string | null;
              title: string | null;
              text: any | null;
            }> | null;
          }
      > | null;
    }> | null;
  }> | null;
} & { " $fragmentName"?: "PageBlock_IconCards_Fragment" };

type PageBlock_ImageHeaderBlock_Fragment = {
  __typename: "ImageHeaderBlock";
  id: string | null;
  typewriterFx: boolean | null;
  video: string | null;
  headerBubbleText: string | null;
  headline: string | null;
  headerImage: ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } }) | null;
} & { " $fragmentName"?: "PageBlock_ImageHeaderBlock_Fragment" };

type PageBlock_LeadBoxBlock_Fragment = {
  __typename: "LeadBoxBlock";
  id: string | null;
  text: any | null;
  links: Array<{
    __typename: "BubbleTextBlock";
    id: string | null;
    url: string | null;
    bubbleText: string | null;
    page: { __typename?: "Page"; slug: string | null } | null;
    modal: Array<
      | {
          __typename?: "MailchimpSignupModal";
          id: string | null;
          title: string | null;
          text: any | null;
          Mailchimp: {
            __typename?: "MailchimpSignupModal_Mailchimp";
            actionUrl: string | null;
            tags: Array<{
              __typename?: "MailchimpSignupModal_Mailchimp_Tags";
              id: string | null;
              tag: string | null;
            }> | null;
          } | null;
        }
      | {
          __typename?: "NumberedItemsModal";
          id: string | null;
          title: string | null;
          description: any | null;
          items: Array<{ __typename?: "NumberedItemsModal_Items"; id: string | null; text: any | null }> | null;
        }
      | {
          __typename?: "TextGridModal";
          id: string | null;
          title: string | null;
          tiles: Array<{
            __typename?: "TextGridModal_Tiles";
            id: string | null;
            title: string | null;
            text: any | null;
          }> | null;
        }
    > | null;
  }> | null;
} & { " $fragmentName"?: "PageBlock_LeadBoxBlock_Fragment" };

type PageBlock_LeifLinkBlock_Fragment = {
  __typename: "LeifLinkBlock";
  id: string | null;
  text: any | null;
  url: string | null;
} & { " $fragmentName"?: "PageBlock_LeifLinkBlock_Fragment" };

type PageBlock_MediaBlock_Fragment = {
  __typename: "MediaBlock";
  id: string | null;
  media: ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } }) | null;
} & { " $fragmentName"?: "PageBlock_MediaBlock_Fragment" };

type PageBlock_PurposeBoxBlock_Fragment = {
  __typename: "PurposeBoxBlock";
  id: string | null;
  text: any | null;
  description: string | null;
  icon: PurposeBoxBlock_Icon | null;
} & { " $fragmentName"?: "PageBlock_PurposeBoxBlock_Fragment" };

type PageBlock_PurposeGridBlock_Fragment = {
  __typename: "PurposeGridBlock";
  id: string | null;
  title: string | null;
  showFooter: boolean | null;
  steps: Array<{
    __typename?: "PurposeGridBlock_Steps";
    id: string | null;
    title: string | null;
    icon: PurposeGridBlock_Steps_Icon | null;
    video: string | null;
    text: any | null;
    additionalContent: Array<
      { __typename: "BubbleTextBlock" } & { " $fragmentRefs"?: { BubbleTextFragment: BubbleTextFragment } }
    > | null;
  }> | null;
  footer: {
    __typename?: "PurposeGridBlock_Footer";
    linkLabel: string | null;
    url: string | null;
    footerText: string | null;
  } | null;
} & { " $fragmentName"?: "PageBlock_PurposeGridBlock_Fragment" };

type PageBlock_QuotationBoxBlock_Fragment = {
  __typename: "QuotationBoxBlock";
  id: string | null;
  author: string | null;
  text: any | null;
} & { " $fragmentName"?: "PageBlock_QuotationBoxBlock_Fragment" };

type PageBlock_ReviewQuotationBoxBlock_Fragment = {
  __typename: "ReviewQuotationBoxBlock";
  id: string | null;
  text: any | null;
  author: string | null;
  image: ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } }) | null;
} & { " $fragmentName"?: "PageBlock_ReviewQuotationBoxBlock_Fragment" };

type PageBlock_TitleTextBoxBlock_Fragment = {
  __typename: "TitleTextBoxBlock";
  id: string | null;
  title: string | null;
  text: any | null;
  emphasizeFirstLetter: boolean | null;
} & { " $fragmentName"?: "PageBlock_TitleTextBoxBlock_Fragment" };

type PageBlock_VideoCarouselBlock_Fragment = {
  __typename: "VideoCarouselBlock";
  id: string | null;
  videos: Array<{ __typename?: "VideoCarouselBlock_Videos"; id: string | null; video: string | null }> | null;
} & { " $fragmentName"?: "PageBlock_VideoCarouselBlock_Fragment" };

type PageBlock_VideoJourneyBlock_Fragment = {
  __typename: "VideoJourneyBlock";
  id: string | null;
  bubbleText: string | null;
  items: Array<{
    __typename?: "VideoJourneyBlock_Items";
    id: string | null;
    title: string | null;
    label: string | null;
    video: string | null;
    navigateTo: string | null;
  }> | null;
} & { " $fragmentName"?: "PageBlock_VideoJourneyBlock_Fragment" };

export type PageBlockFragment =
  | PageBlock_AnchorBlock_Fragment
  | PageBlock_AngledTextBoxBlock_Fragment
  | PageBlock_BubbleTextBlock_Fragment
  | PageBlock_CaseLeadBoxBlock_Fragment
  | PageBlock_CasesBoxBlock_Fragment
  | PageBlock_DoubleSlotBoxBlock_Fragment
  | PageBlock_IconCards_Fragment
  | PageBlock_ImageHeaderBlock_Fragment
  | PageBlock_LeadBoxBlock_Fragment
  | PageBlock_LeifLinkBlock_Fragment
  | PageBlock_MediaBlock_Fragment
  | PageBlock_PurposeBoxBlock_Fragment
  | PageBlock_PurposeGridBlock_Fragment
  | PageBlock_QuotationBoxBlock_Fragment
  | PageBlock_ReviewQuotationBoxBlock_Fragment
  | PageBlock_TitleTextBoxBlock_Fragment
  | PageBlock_VideoCarouselBlock_Fragment
  | PageBlock_VideoJourneyBlock_Fragment;

export type CurrentPageQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;

export type CurrentPageQuery = {
  __typename?: "Query";
  Pages: {
    __typename?: "Pages";
    docs: Array<{
      __typename?: "Page";
      id: number | null;
      title: string | null;
      slug: string | null;
      meta: { __typename?: "Page_Meta"; title: string | null; description: string | null } | null;
      content: Array<
        | { __typename: "AnchorBlock"; id: string | null; name: string | null }
        | { __typename: "AngledTextBoxBlock"; id: string | null; title: string | null; text: any | null }
        | ({ __typename: "BubbleTextBlock" } & { " $fragmentRefs"?: { BubbleTextFragment: BubbleTextFragment } })
        | {
            __typename: "CaseLeadBoxBlock";
            id: string | null;
            headline: string | null;
            text: any | null;
            video: string | null;
            poster:
              | ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } })
              | null;
          }
        | {
            __typename: "CasesBoxBlock";
            id: string | null;
            cases: Array<{
              __typename?: "CasesBoxBlock_Cases";
              id: string | null;
              title: string | null;
              description: string | null;
              text: any | null;
              image:
                | ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } })
                | null;
              page: { __typename?: "Page"; id: number | null; title: string | null; slug: string | null } | null;
            }> | null;
          }
        | {
            __typename: "DoubleSlotBoxBlock";
            id: string | null;
            contentLeft: {
              __typename?: "DoubleSlotBoxBlock_ContentLeft";
              blocks: Array<
                | ({ __typename: "BubbleTextBlock" } & {
                    " $fragmentRefs"?: { BubbleTextFragment: BubbleTextFragment };
                  })
                | {
                    __typename: "VideoCarouselBlock";
                    id: string | null;
                    videos: Array<{
                      __typename?: "VideoCarouselBlock_Videos";
                      id: string | null;
                      video: string | null;
                    }> | null;
                  }
              > | null;
            } | null;
            contentRight: {
              __typename?: "DoubleSlotBoxBlock_ContentRight";
              icon: DoubleSlotBoxBlock_ContentRight_Icon | null;
              text: any | null;
            } | null;
          }
        | {
            __typename: "IconCards";
            id: string | null;
            title: string | null;
            cards: Array<{
              __typename?: "IconCards_Cards";
              id: string | null;
              title: string | null;
              icon: IconCards_Cards_Icon | null;
              text: any | null;
              additionalContent: Array<
                { __typename: "BubbleTextBlock" } & { " $fragmentRefs"?: { BubbleTextFragment: BubbleTextFragment } }
              > | null;
            }> | null;
          }
        | {
            __typename: "ImageHeaderBlock";
            id: string | null;
            typewriterFx: boolean | null;
            video: string | null;
            headerBubbleText: string | null;
            headline: string | null;
            headerImage:
              | ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } })
              | null;
          }
        | {
            __typename: "LeadBoxBlock";
            id: string | null;
            text: any | null;
            links: Array<
              { __typename: "BubbleTextBlock" } & { " $fragmentRefs"?: { BubbleTextFragment: BubbleTextFragment } }
            > | null;
          }
        | { __typename: "LeifLinkBlock"; id: string | null; text: any | null; url: string | null }
        | {
            __typename: "MediaBlock";
            id: string | null;
            media:
              | ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } })
              | null;
          }
        | {
            __typename: "PurposeBoxBlock";
            id: string | null;
            text: any | null;
            description: string | null;
            icon: PurposeBoxBlock_Icon | null;
          }
        | {
            __typename: "PurposeGridBlock";
            id: string | null;
            title: string | null;
            showFooter: boolean | null;
            steps: Array<{
              __typename?: "PurposeGridBlock_Steps";
              id: string | null;
              title: string | null;
              icon: PurposeGridBlock_Steps_Icon | null;
              video: string | null;
              text: any | null;
              additionalContent: Array<
                { __typename: "BubbleTextBlock" } & { " $fragmentRefs"?: { BubbleTextFragment: BubbleTextFragment } }
              > | null;
            }> | null;
            footer: {
              __typename?: "PurposeGridBlock_Footer";
              linkLabel: string | null;
              url: string | null;
              footerText: string | null;
            } | null;
          }
        | { __typename: "QuotationBoxBlock"; id: string | null; author: string | null; text: any | null }
        | {
            __typename: "ReviewQuotationBoxBlock";
            id: string | null;
            text: any | null;
            author: string | null;
            image:
              | ({ __typename?: "Media" } & { " $fragmentRefs"?: { SizedImageFragment: SizedImageFragment } })
              | null;
          }
        | {
            __typename: "TitleTextBoxBlock";
            id: string | null;
            title: string | null;
            text: any | null;
            emphasizeFirstLetter: boolean | null;
          }
        | {
            __typename: "VideoCarouselBlock";
            id: string | null;
            videos: Array<{ __typename?: "VideoCarouselBlock_Videos"; id: string | null; video: string | null }> | null;
          }
        | {
            __typename: "VideoJourneyBlock";
            id: string | null;
            bubbleText: string | null;
            items: Array<{
              __typename?: "VideoJourneyBlock_Items";
              id: string | null;
              title: string | null;
              label: string | null;
              video: string | null;
              navigateTo: string | null;
            }> | null;
          }
      >;
    } | null> | null;
  } | null;
};

export type PagesQueryVariables = Exact<{ [key: string]: never }>;

export type PagesQuery = {
  __typename?: "Query";
  Pages: {
    __typename: "Pages";
    docs: Array<{
      __typename?: "Page";
      id: number | null;
      title: string | null;
      meta: { __typename?: "Page_Meta"; title: string | null; description: string | null } | null;
    } | null> | null;
  } | null;
};

export const SizedImageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SizedImage" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Media" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "filename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sizes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_4k" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_2k" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_fullhd" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_hd" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lg" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "md" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "xs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SizedImageFragment, unknown>;
export const BubbleTextFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BubbleText" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", alias: { kind: "Name", value: "bubbleText" }, name: { kind: "Name", value: "text" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "page" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "openInNewTab" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "modal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MailchimpSignupModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Mailchimp" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "tag" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TextGridModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tiles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NumberedItemsModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BubbleTextFragment, unknown>;
export const PageBlockFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "PageBlock" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Page_Content" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AnchorBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VideoJourneyBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "bubbleText" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "label" } },
                      { kind: "Field", name: { kind: "Name", value: "video" } },
                      { kind: "Field", name: { kind: "Name", value: "navigateTo" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LeadBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "links" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "__typename" } },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "bubbleText" },
                              name: { kind: "Name", value: "text" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "page" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modal" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "MailchimpSignupModal" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "Mailchimp" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "tags" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    { kind: "Field", name: { kind: "Name", value: "tag" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "TextGridModal" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "tiles" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "title" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NumberedItemsModal" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "items" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CaseLeadBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "headline" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "video" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "poster" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } }],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", alias: { kind: "Name", value: "bubbleText" }, name: { kind: "Name", value: "text" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "page" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "modal" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MailchimpSignupModal" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Mailchimp" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "tags" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "tag" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TextGridModal" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tiles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NumberedItemsModal" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TitleTextBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "emphasizeFirstLetter" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VideoCarouselBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "video" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DoubleSlotBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contentLeft" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "blocks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VideoCarouselBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "videos" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "video" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "bubbleText" },
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "page" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "modal" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: { kind: "Name", value: "MailchimpSignupModal" },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "title" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "Mailchimp" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "tags" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "id" } },
                                                          { kind: "Field", name: { kind: "Name", value: "tag" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: { kind: "Name", value: "TextGridModal" },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "title" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "tiles" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    { kind: "Field", name: { kind: "Name", value: "title" } },
                                                    { kind: "Field", name: { kind: "Name", value: "text" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: { kind: "Name", value: "NumberedItemsModal" },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "title" } },
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "items" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    { kind: "Field", name: { kind: "Name", value: "text" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contentRight" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "QuotationBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "author" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "IconCards" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cards" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalContent" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "bubbleText" },
                                    name: { kind: "Name", value: "text" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "page" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "modal" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: { kind: "Name", value: "MailchimpSignupModal" },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "title" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "Mailchimp" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "tags" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "id" } },
                                                          { kind: "Field", name: { kind: "Name", value: "tag" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: { kind: "Name", value: "TextGridModal" },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "title" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "tiles" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    { kind: "Field", name: { kind: "Name", value: "title" } },
                                                    { kind: "Field", name: { kind: "Name", value: "text" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: { kind: "Name", value: "NumberedItemsModal" },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "title" } },
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "items" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    { kind: "Field", name: { kind: "Name", value: "text" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PurposeGridBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "steps" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "icon" } },
                      { kind: "Field", name: { kind: "Name", value: "video" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "additionalContent" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "BubbleText" } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "showFooter" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "footer" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "footerText" },
                        name: { kind: "Name", value: "text" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "linkLabel" } },
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CasesBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cases" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "page" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AngledTextBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PurposeBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "icon" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ReviewQuotationBoxBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "author" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LeifLinkBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ImageHeaderBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "headerBubbleText" },
                  name: { kind: "Name", value: "bubbleText" },
                },
                { kind: "Field", alias: { kind: "Name", value: "headline" }, name: { kind: "Name", value: "text" } },
                { kind: "Field", name: { kind: "Name", value: "typewriterFx" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "headerImage" },
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "video" } },
              ],
            },
          },
          {
            kind: "InlineFragment",
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MediaBlock" } },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "media" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SizedImage" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Media" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "filename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sizes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_4k" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_2k" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_fullhd" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_hd" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lg" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "md" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "xs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BubbleText" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", alias: { kind: "Name", value: "bubbleText" }, name: { kind: "Name", value: "text" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "page" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "openInNewTab" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "modal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MailchimpSignupModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Mailchimp" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "tag" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TextGridModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tiles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NumberedItemsModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PageBlockFragment, unknown>;
export const FooterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "footer" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Footer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "contact" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "phoneNumber" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "emailContent" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "socials" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "linkedinUrl" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FooterQuery, FooterQueryVariables>;
export const NavDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "nav" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Navigation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pages" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "page" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<NavQuery, NavQueryVariables>;
export const CurrentPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "currentPage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Pages" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equals" },
                            value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              { kind: "Argument", name: { kind: "Name", value: "limit" }, value: { kind: "IntValue", value: "1" } },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "docs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "content" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AnchorBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VideoJourneyBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "bubbleText" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "items" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "label" } },
                                        { kind: "Field", name: { kind: "Name", value: "video" } },
                                        { kind: "Field", name: { kind: "Name", value: "navigateTo" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LeadBoxBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "links" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: { kind: "Name", value: "BubbleTextBlock" },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "FragmentSpread", name: { kind: "Name", value: "BubbleText" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CaseLeadBoxBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "headline" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "video" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "poster" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "BubbleText" } }],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TitleTextBoxBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "emphasizeFirstLetter" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "VideoCarouselBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "videos" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "video" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DoubleSlotBoxBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "contentLeft" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "blocks" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "VideoCarouselBlock" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "videos" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "id" } },
                                                          { kind: "Field", name: { kind: "Name", value: "video" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "BubbleTextBlock" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "FragmentSpread",
                                                      name: { kind: "Name", value: "BubbleText" },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "contentRight" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "icon" } },
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "QuotationBoxBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "author" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "IconCards" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cards" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "icon" } },
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "additionalContent" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "BubbleTextBlock" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "FragmentSpread",
                                                      name: { kind: "Name", value: "BubbleText" },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PurposeGridBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "steps" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "icon" } },
                                        { kind: "Field", name: { kind: "Name", value: "video" } },
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "additionalContent" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "BubbleTextBlock" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "FragmentSpread",
                                                      name: { kind: "Name", value: "BubbleText" },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "showFooter" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "footer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          alias: { kind: "Name", value: "footerText" },
                                          name: { kind: "Name", value: "text" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "linkLabel" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CasesBoxBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cases" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "image" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "page" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "title" } },
                                              { kind: "Field", name: { kind: "Name", value: "slug" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "AngledTextBoxBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PurposeBoxBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "icon" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "ReviewQuotationBoxBlock" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "author" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LeifLinkBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ImageHeaderBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "headerBubbleText" },
                                    name: { kind: "Name", value: "bubbleText" },
                                  },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "headline" },
                                    name: { kind: "Name", value: "text" },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "typewriterFx" } },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "headerImage" },
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "video" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MediaBlock" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "media" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "FragmentSpread", name: { kind: "Name", value: "SizedImage" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BubbleText" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "BubbleTextBlock" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", alias: { kind: "Name", value: "bubbleText" }, name: { kind: "Name", value: "text" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "page" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "url" } },
          { kind: "Field", name: { kind: "Name", value: "openInNewTab" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "modal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MailchimpSignupModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "text" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "Mailchimp" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "actionUrl" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tags" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "tag" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TextGridModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tiles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NumberedItemsModal" } },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      { kind: "Field", name: { kind: "Name", value: "description" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SizedImage" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Media" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "filename" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "sizes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_4k" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_2k" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_fullhd" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "res_hd" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lg" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "md" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "xs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentPageQuery, CurrentPageQueryVariables>;
export const PagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Pages" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "Pages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "docs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PagesQuery, PagesQueryVariables>;
